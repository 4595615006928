import { useSearchParams } from "react-router-dom";
import { Urls } from "../utils/AppConfig";
import { useEffect } from "react";
import { fetchGet, fetchPost } from "../utils/helpers";

const DownloadManualReport = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    let manualReportId = searchParams.get("id");
    useEffect(()=>{
        if(manualReportId){
            fetchGet(Urls.DownloadMR + "?manualReportId="+manualReportId, window.localStorage.getItem('token'))
            .then(data=>{
                console.log(data);
                if(data.error){
                    return window.alert("Please try again later.")
                }
                window.location.href = data.data;
            })
        }
    }, [])
    if(!manualReportId){
        return (
            <p>Error!</p>
        )
    }
    
    
    return (
        <>
            <p>Please wait...</p>
        </>
    )
}

export default DownloadManualReport;