import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchPost } from "../utils/helpers";
import SuccessImg from '../images/ic_success.png';
import FailureImg from '../images/ic_failure.png';
import LoadingImg from '../images/loading.gif';
import { toggleLoader } from "../actions";
import { connect, useDispatch } from "react-redux";
import { Urls } from "../utils/AppConfig";
import PaymentDetails from "../components/PaymentDetails/PaymentDetails";

const mapStateToProps = (state) => {
  return {
    data: state.DataReducer
  }
}

const PaymentDetailsPage = (props) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  let paymentId = parseInt(searchParams.get("id"));
  console.log(props.data.payments, paymentId)
  let paymentData = props.data.payments.filter(payment => {
    return payment['payment_id'] === paymentId;
  })
  if (paymentData.length > 0) {
    paymentData = paymentData[0]
  } else {
    return <></>
  }

  return <>
    <PaymentDetails props={paymentId} paymentData={paymentData} />
  </>
}

export default connect(mapStateToProps)(PaymentDetailsPage);