import { useDispatch } from "react-redux";
import AppHeader from "../components/AppHeader";
import HeaderSection from "../components/Landing/HeaderSection";
import FooterSection from "../components/Landing/FooterSection";
import SubscriptionSection from "../components/SubscriptionSection";
import Cursor from '../components/Cursor/Cursor';
import ScrollToAnchor from '../utils/ScrollToAnchor';

const SubscriptionPage = () => {
    const dispatch = useDispatch();



    return <>
        <Cursor/>
        <ScrollToAnchor />
        <HeaderSection/>
        <SubscriptionSection/>
<FooterSection />
    </>
}

export default SubscriptionPage;