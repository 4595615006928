import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RepScShImg from '../../images/i3.jpg';
import LogoImg from '../../images/logo.js';
import React, { useState,useEffect } from 'react';

const AboutSection = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    

    return <>
        <section id='about' >
            <div style={{ textAlign: 'center', padding: 0, background: '#111111', paddingTop: 65, paddingBottom: 65 }}>
                <div elevation={0} style={{ textAnchor: 'middle', background: 'transparent', minHeight: '100vh' }}>
                    <p className="head_font2 text-5xl font-bold text-[#00ff99] pt-5 ">
                        ABOUT US
                    </p>
                    <Container>
                        <Row style={{ padding: 16 }}>
                            <Col xs={12} md={6} style={{ textAlign: 'center', display: 'flex' }}>
                                <LogoImg style={{ width: '100%', maxWidth: 600, margin: 'auto' }} />
                            </Col>
                            <Col xs={12} md={6} style={{ marginTop: 16 }}>
                                <p variant="caption2" className="content-text-about text-xl">
                                Welcome to <b><i>Plot Potential</i></b>. We are a pioneering GIS-based land assessment company, committed to providing comprehensive reports and insights to enable individuals and organisations to make informed decisions about their land and property. Our innovative approach combines the power of GIS technology, artificial intelligance and machine learning, along with deep industry expertise to deliver accurate and reliable information.
                                <br />
                                    <br />

                                At <b><i>Plot Potential</i></b>, we understand that owning land or property comes with a myriad of questions and uncertainties. Our mission is to empower our clients by answering the fundamental question: "What can I do with my plot?" We believe that every plot of land has untapped potential, and our goal is to unlock its possibilities. We help our customers navigate the complexities of zoning requirements, development engineering constraints and environmental considerations.
                                <br />
                                <br />
                                    Our team is led by Rishabh Arora, a civil engineer with extensive experience in the construction and building control industries. We are a team of experts from various backgrounds, including architecture, engineering, planning and GIS. By harnessing the collective knowledge of these disciplines, we provide a holistic perspective on land assessments, enabling our clients to maximize the value and potential of their property.
                                </p>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <br />
                        <Row style={{ padding: 16 }}>
                            <Col sm={12} md={6}>
                                <p variant="caption2" className="content-text-about text-xl" >
                                Our reports are meticulously crafted, incorporating data from the Auckland Unitary Plan, developmental restrictions, and cutting-edge GIS technology. We go beyond the traditional scope of land assessments by presenting comprehensive, user-friendly reports that makes complex information easy to deal with. 
                                    <br />
                                    <br />

                                    Every plot of land is an opportunity waiting to be realised. No matter what your goal is, we’re excited to be on this journey with you. 

                                    {/* At <b><i>Plot Potential</i></b>, we are driven by a vision to help the community unlock the potential of their land. We believe that by offering comprehensive and user-friendly land assessment reports, we can empower individuals, developers, and organizations to realize their dreams, whether it's building their dream home, launching a commercial venture, or contributing to sustainable urban development. */}
                                    <br />
                                    <br />
                                    {/* We are excited to be on this journey with you and look forward to partnering with you in uncovering the hidden potential of your land. Join us in shaping a future where every plot of land is seen as an opportunity waiting to be realized.
                                    <br />
                                    <br /> */}
                                </p>
                                <p className="content-text-2" style={{ textAlign: 'center' }}>
                                    Unlock the Potential with <b><i>Plot Potential</i></b>.
                                </p>
                            </Col>
                            <Col sm={12} md={6}>
                                <img src={RepScShImg} style={{ maxWidth: '100%' }} />
                            </Col>
                        </Row>

                    </Container>
                </div>
                <br />
                {/* <IconButton style={{ color: 'white', border: '1px solid white' }}>
                    <KeyboardDoubleArrowDownIcon />
                </IconButton> */}
            </div>

            <div style={{}}>
                <div style={{ textAlign: 'center', padding: 0, background: '#171717', paddingTop: 65, paddingBottom: 65 }}>
                    <div elevation={0} style={{ textAnchor: 'middle', background: 'transparent' }}>

                        <Container>
                            <Row style={{ padding: 16 }}>
                                <Col sm={12} md={4}>
                                </Col>
                                <Col sm={12} md={8} style={{ padding: 32 }}>
                                    <p className="content-text-about text-xl">

                                        At PlotPotential.co.nz, we believe in giving 110%. By using training AI data sets to build confident land potential reports, we would want to provide a user-friendly platform where businesses grow and foster a consumer first mindset.
                                        <br />
                                        We thrive because of our market knowledge and a great team behind our product.
                                    </p>
                                    <br />
                                    <br />
                                    <p className="content-text-2-about text-xl text-justify" >
                                        As our Founder says, “Future efficiencies will come from proactively transforming how we do business in today’s time." - Rishabh Arora (CEO and Founder)
                                    </p>
                                </Col>
                            </Row>
                        </Container>


                    </div>
                    <br />
                </div>
            </div>


        </section>
    </>
}

export default AboutSection;