import HomeHeader from "../components/HomeHeader";
import SubscriptionTab from "../components/Profile/SubscriptionTab";


const MySubscriptionPage = (props) => {
    return <>
        <HomeHeader />
        <div className="md:mx-20 md:my-10 my-5 mx-10">
            <div>
            <SubscriptionTab />
            </div>
        </div>
    </>
}

export default MySubscriptionPage;