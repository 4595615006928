import { useDispatch } from "react-redux";
import { setUser, toggleLoader } from "../actions";
import HomeHeader from "../components/HomeHeader";
import {ResetPassword,NewPassword} from "../components/ResetPassword";
import '../styles/signin.css';
import { validateToken } from "../utils/helpers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import Cursor from '../components/Cursor/Cursor';
import BgVideo from '../images/BgVideo.mp4';


const SignInPage = () => {

    return <>

<Cursor/>

        <HomeHeader />
        <header className="relative  flex items-center justify-center h-[calc(100vh-72px)] overflow-hidden" style={{fontFamily: 'Verdana, sans-serif'}}>
                <div className='z-10 lg:py-0 md:py-3 py-5 sm:py-2 flex items-center justify-center absolute h-[calc(100vh-72px)] w-screen content-card-video'>


                <ResetPassword />
                <NewPassword/>

                            
                </div>
            <video
                autoPlay
                loop
                muted
                className="absolute -z-0 w-auto min-w-full min-h-full max-w-none bg-transparent object-cover hidden md:block"
            >
                <source
                    src={BgVideo}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </header>
    </>
}

export default SignInPage;