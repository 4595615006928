import React, { useRef } from 'react';
import EmpLogoPng from '../../images/emp_logo.png'
import {Navbar, NavbarBrand, NavbarContent, NavbarItem,  Button} from "@nextui-org/react";
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { setUser } from '../../actions';

import { useState } from "react";

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer
    }
}




export const HeaderSection = (props) => {   
    
    const dispatch = useDispatch();
    const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false

    return (
        <header class="bg-black fixed top-0 w-full shadow-md z-20 h-[70px]" style={{ fontFamily: 'Verdana, sans-serif' }}>
        <nav class="container mx-auto px-6 py-3 h-[70px]">

            <div className="flex items-center flex-1 md:absolute  md:left-0 px-5">
                <div className="items-center justify-between  md:w-auto">
                    <Link to='/' className='flex flex-row '>
                        <img  src={EmpLogoPng} loading="lazy" width="30" height="30"/>
                    <span className='px-2 text-[#00ff99] text-2xl font-sans'>PlotPotential</span>
                    </Link>
                </div>
            </div>

            <div className="flex absolute lg:hidden items-center justify-end inset-y-0 right-0 px-5">
            <button className="  p-2 text-gray-400 bg-black border-2 border-zinc-800 rounded-md group hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-neutral-700"  type="button" aria-expanded="false" onClick={() => setIsNavOpen((prev) => !prev)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
                            <path className={isNavOpen ? "hidden" : "flex"} stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16"></path>
                            <path className={isNavOpen ? "flex" : "hidden"} stroke-linecap="round" stroke-linejoin="round" d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z" >
                            </path> 
                            </svg>
                            </button>

                          
                            <div className={isNavOpen ? "flex" : "hidden"} >
                            <ul className='bg-black absolute w-screen pb-6 px-0 top-[70px] right-0  flex flex-col  space-y-3 justify-end'>
                            <Link to='/home' className='hover:bg-[#00ff99]  w-full'><li className='flex justify-center hover:text-black py-3 text-[#00ff99]' >
                            Home
                            </li></Link>
                            {/* <Link to='/map' className='hover:bg-[#00ff99]  w-full'><li className='flex justify-center hover:text-black py-3 text-[#00ff99] '>
                            Map
                            </li>
                            </Link> */}
                            <Link to='/#services' className='hover:bg-[#00ff99]  w-full '><li className='flex justify-center hover:text-black py-3 text-[#00ff99] '>
                            Services
                            </li></Link>
                            <Link to='/#about' className='hover:bg-[#00ff99]  w-full'><li className='flex justify-center hover:text-black  py-3 text-[#00ff99] '>
                            About
                            </li></Link>
                            <Link to='/#contact' className='hover:bg-[#00ff99]  w-full'><li className='flex justify-center hover:text-black py-3 text-[#00ff99]' >
                            Contact
                            </li></Link>
                            <Link to='/info' className='hover:bg-[#00ff99]  w-full'><li className='flex justify-center hover:text-black py-3 text-[#00ff99]' >
                            More...
                            </li></Link>
                            {
                                props.data.user?<>
                                    <button className='hover:bg-[#00ff99] '>
                                    {
                                        props.data.user.email.indexOf("plotpotential.co.nz")>-1?<Link className='hover:bg-[#00ff99]  w-full' to={'/home'}><li className='flex justify-center hover:text-black py-3 text-[#00ff99]' >
                                        Home
                                        </li></Link>:''
                                    }
                                    </button>
                                    <button className='hover:bg-[#00ff99] ' onClick={() => {
                                                dispatch(setUser(null));
                                                window.localStorage.removeItem('token');
                                            }}>
                                    <Link className='hover:bg-[#00ff99]  w-full'><li className='flex justify-center hover:text-black py-3 text-[#00ff99]' >
                                    LogOut
                                    </li></Link>
                                    </button>
                                </>:<>
                                <Link to='/signIn' className='hover:bg-[#00ff99]  w-full'><li className='flex justify-center hover:text-black py-3 text-[#00ff99]' >
                                    Sign In
                                </li></Link>
                                <Link to='/signUp' className='hover:bg-[#00ff99]  w-full'><li className='flex justify-center hover:text-black py-3 text-[#00ff99]' >
                                    Sign Up
                                </li></Link>
                                </>
                            }
                            </ul>
                            </div>
                    </div>

            <div class="lg:flex justify-center items-center hidden">
            <div className="hidden md:flex md:space-x-12 list-none">
            {/* <li>
            <Link to='/home' className="text-xl  list-none text-[#00ff99] hover:text-white"
                        target="">Home</Link>
                </li> */}
                <li>
                <Link to='/#services' className="text-xl  list-none text-[#00ff99] hover:text-white"
                        target="">Services</Link>
                </li>
                <li>
                <Link to="/#about" className="text-xl  list-none text-[#00ff99] hover:text-white"
                        target="">About
                    </Link>
                </li>
                <li>
                <Link to="/#contact" className="text-xl  list-none text-[#00ff99] hover:text-white"
                        target="">Contact
                    </Link>
                    
                </li>
                <li>
                <Link to='/info' className='text-xl  list-none text-[#00ff99] hover:text-white'>
                            More...
                            </Link>
                </li>
            </div>
            </div>


            
            




                <div className="hidden absolute lg:flex md:items-center md:justify-end md:inset-y-0 md:right-0 px-5">
                <div className="inline-flex rounded-full shadow space-x-3">
                
                       {
                        !props.data.user?<>
                        <Link to='/signIn'><div href="#"
                        className="inline-flex items-center px-4 py-2 text-base text-black  border-2 bg-[#00ff99] border-[#00ff99] cursor-pointer font-base hover:text-black hover:bg-[#00ff99]">
                        Sign In
                    </div></Link>
                    <Link to='/signUp'><div href="#"
                        className="text-xl  list-none text-[#00ff99] hover:text-white">
                        Sign Up
                    </div></Link>
                        </>:<>
                        {props.data.user.email.indexOf("plotpotential.co.nz")>-1?(<Link to='/home'><div href="#"
                        className="inline-flex items-center px-4 py-2 text-base text-black  border-2 bg-[#00ff99] border-[#00ff99] cursor-pointer font-base hover:text-black hover:bg-[#00ff99]">
                        Home
                    </div></Link>):''}
                        <button onClick={() => {
                                        dispatch(setUser(null));
                                        window.localStorage.removeItem('token');
                                    }} >
              <div className="inline-flex items-center px-4 py-2 text-base text-[#00ff99]  border-2 border-[#00ff99]  cursor-pointer font-base hover:text-black hover:bg-[#00ff99]">
             Log Out</div>
             </button>
             </>
                       } 
                </div>
            </div>
        </nav>
    </header>
    )};

    export default connect(mapStateToProps)(HeaderSection);


    {/* <div className="px-4 mx-auto max-w-7xl max-h-[70px] sm:px-6 " >
    <div className="relative pt-6 pb-16 sm:pb-24 ">
        <nav className=" relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
            <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-between  md:w-auto">
                    <Link href='/'>
                    <a  className=''>
                        <img  src={EmpLogoPng} loading="lazy" width="30" height="30"/>
                    </a>
                    <span className='px-2 text-[#00ff99] text-2xl font-sans'>PlotPotential</span>
                    </Link>
                </div>
            </div>
            <div className="flex items-center -mr-2 md:hidden">
                        <button className="inline-flex items-center justify-center p-2 text-gray-400 bg-gray-50 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-50"  type="button" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16"></path>
                            </svg>
                        </button>
                    </div>




            <div className="hidden md:flex md:space-x-10 list-none">
            <li>
            <a href="/" className="text-xl  list-none text-[#00ff99] hover:text-white"
                        target="">Home</a>
                </li>
                <li>
                <a href="#services" className="text-xl  list-none text-[#00ff99] hover:text-white"
                        target="">Services</a>
                </li>
                <li>
                <a href="#about" className="text-xl  list-none text-[#00ff99] hover:text-white"
                        target="">About
                    </a>
                </li>
                <li>
                <a href="#contact" className="text-xl  list-none text-[#00ff99] hover:text-white"
                        target="_blank">Contact
                    </a>
                </li>
            </div>
            <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                <div className="inline-flex rounded-full shadow">
                    <div href="#"
                        className="inline-flex items-center px-4 py-2 text-base text-[#00ff99]  border-2 border-[#00ff99]  cursor-pointer font-base hover:text-black hover:bg-[#00ff99]">
                        Log Out
                    </div>
                </div>
            </div>
        </nav>            
    </div>
</div> */}