

import Button from 'react-bootstrap/Button';
import IcUserGreen from '../../images/ic_user_green.png';
import IcSubsGreen from '../../images/ic_subscription_green.png';
import React, { useEffect, useState } from "react";
import AddressSearch from '../AddressSearch';
import UserImg from '../../images/ic_user_green.png';


// import { Button } from "../../shadcn/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/components/ui/dialog"
import { Input } from "../../shadcn/components/ui/input"
import { Label } from "../../shadcn/components/ui/label"
import { Pencil1Icon, Pencil2Icon } from '@radix-ui/react-icons'
import { fetchPost } from '../../utils/helpers';
import { Col, Container, Row } from 'react-bootstrap';
import { FaSquarePhone, FaLocationDot } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { FaEdit, FaSave } from "react-icons/fa";
import { connect, useDispatch } from 'react-redux';
import { setUser, toggleLoader } from '../../actions';
import { Urls } from '../../utils/AppConfig';


const mapStateToProps = (state) => {
  return {
    data: state.DataReducer
  }
}

const HomeTab = (props) => {

  const dispatch = useDispatch();

  const [searchdata, setSearchdata] = useState([])
  const [userData, setUserData] = useState(null);

  const [phone, setPhone] = useState(props.data.user['client_phone']);
  const [street, setStreet] = useState(props.data.user['address_street']);
  const [city, setCity] = useState(props.data.user['address_city']);
  const [region, setRegion] = useState(props.data.user['address_region']);
  const [editing, setEditing] = useState(false);
  const token = window.localStorage.getItem('token');

  useEffect(() => {
    fetchPost(Urls.GetSearchHistory, {}, token)
      .then(response => {
        if (response.error) {
          return;
        }
        setSearchdata(response.data);
      });
  }, []);

  const updateUser = () => {
    dispatch(toggleLoader(true));
    fetchPost(Urls.UpdateUser, {
      phone, street, city, region
    }, token)
      .then(response => {
        dispatch(toggleLoader(false));
        if (response.error) {
          return window.alert("Failed to update user details. Please, try again later.");
        }
        dispatch(setUser(response.data));
        setEditing(false);
      });
  }


  if (!props.data || !props.data.user) {
    return <>...</>;
  }

  return (
    <>

      <div className='sign-in-container'>
        <Container>
          <Row>
            <Col lg={6} style={{ padding: 0, textAlign: 'center', marginTop: 15 }}>
              <div style={{ maxWidth: '100%', display: 'inline-block' }}>
                <div style={{ textAlign: 'center' }}>
                  <div className='sign-in-user-img-container' style={{ backgroundColor: '#0D0D0D', boxShadow: '2px 2px 2px 1px black' }}>
                    <img width="75" src={UserImg} />
                  </div>
                </div>
                <div className='sign-in-panel' style={{ backgroundColor: '#111', width: 600, maxWidth: '100%' }}>
                  <div style={{ padding: '75px 8px 0 8px', textAlign: 'center' }}>
                    <h2 className='sign-in-header'>{props.data.user.name}</h2>
                    <h4 className='content-text' style={{ textAlign: 'center' }}>{props.data.user.email}</h4>
                    <div style={{ textAlign: 'left', display: 'inline-block', width: '100%' }}>
                      <Row>
                        <Col xs={2} style={{ marginTop: 10 }}><FaSquarePhone color='#00FF99' /></Col>
                        <Col xs={10}><input className="pp-input-2" style={{ width: '100%' }} disabled={!editing} name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} /></Col>
                      </Row>
                      <Row>
                        <Col xs={2} style={{ marginTop: 10 }}><FaLocationDot color='#00FF99' /></Col>
                        <Col xs={10}>
                          <input placeholder='Building/Street' className="pp-input-2" style={{ width: '100%' }} disabled={!editing} name="street" value={street} onChange={(e) => setStreet(e.target.value)} />
                          <br />
                          <input placeholder='City' className="pp-input-2" style={{ width: '100%' }} disabled={!editing} name="city" value={city} onChange={(e) => setCity(e.target.value)} />
                          <br />
                          <select className="pp-input-2" style={{ width: '100%' }} value={region} disabled={!editing} onChange={(e) => {
                            setRegion(e.target.value)
                          }}>
                            <option value={'select'}>Select</option>
                            <option value={'Northland'}>Northland</option>
                            <option value={'Auckland'}>Auckland</option>
                            <option value={'Waikato'}>Waikato</option>
                            <option value={'Bay of Plenty'}>Bay of Plenty</option>
                            <option value={'Gisborne'}>Gisborne</option>
                            <option value={'Hawkes Bay'}>Hawkes Bay</option>
                            <option value={'Taranaki'}>Taranaki</option>
                            <option value={'Manawatu-Whanganui'}>Manawatu-Whanganui</option>
                            <option value={'Wellington'}>Wellington</option>
                            <option value={'Tasman'}>Tasman</option>
                            <option value={'Nelson'}>Nelson</option>
                            <option value={'Marlborough'}>Marlborough</option>
                            <option value={'West Coast'}>West Coast</option>
                            <option value={'Canterbury'}>Canterbury</option>
                            <option value={'Otago'}>Otago</option>
                            <option value={'Southland'}>Southland</option>
                          </select>
                          {/* <input placeholder='Region' className="pp-input-2" style={{ width: '100%' }} disabled={!editing} name="region" value={region} onChange={(e) => setRegion(e.target.value)} /> */}
                        </Col>
                      </Row>
                      <br />
                      <div style={{ textAlign: 'right' }}>
                        {
                          !editing ? (
                            <button onClick={() => { setEditing(true) }} className='btn' style={{ border: 'none' }}> <div style={{ display: 'inline-block' }}>Edit</div> <span></span></button>
                          ) : (
                            <>
                              <button onClick={() => { updateUser() }} className='btn' style={{ border: 'none', color: 'green' }}> <div style={{ display: 'inline-block' }}>Save</div> <span></span></button>
                              <button onClick={() => { setEditing(false) }} className='btn' style={{ border: 'none', color: 'red' }}> <div style={{ display: 'inline-block' }}>Cancel</div> <span></span></button>
                            </>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <Row style={{ maxWidth: '100%', padding: 2, marginTop: 50 }}>
                <Col md={6} xs={12} style={{ padding: 8 }}>
                  <div style={{ background: '#111', padding: 20, borderRadius: '10px' }}>
                    <p className='content-text' style={{ textAlign: 'center' }}>No. of Reports</p>
                    <h2 className='sign-in-header'>{props.data.user['report_count']}</h2>
                  </div>
                </Col>
                <Col md={6} xs={12} style={{ padding: 8 }}>
                  <div style={{ background: '#111', padding: 20, borderRadius: '10px' }}>
                    <p className='content-text' style={{ textAlign: 'center' }}>No. of Searches</p>
                    <h2 className='sign-in-header'>{props.data.user['search_count']}</h2>
                  </div>
                </Col>
                <Col md={6} xs={12} style={{ padding: 8 }}>
                  <div style={{ background: '#111', padding: 20, borderRadius: '10px' }}>
                    <p className='content-text' style={{ textAlign: 'center' }}>Subscription</p>
                    <h2 className='sign-in-header'>{props.data.user['plan_id'] === null ? '-' : props.data.user['plan_name']}</h2>
                    {props.data.user['plan_id'] !== null ? (<p className='header-4-small' style={{ textAlign: 'center', fontSize: '14px !important' }}>
                      {Math.floor((new Date(props.data.user['expires_on']).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))} days remaining
                    </p>) : ''}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container >
      </div >

    </>
  )
}



export default connect(mapStateToProps)(HomeTab);