import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchGet, fetchPost } from "../utils/helpers";
import { Urls } from "../utils/AppConfig";
import { Button } from "../shadcn/components/ui/button"
import { Input } from "../shadcn/components/ui/input";

const Quote = (props) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [quoteData, setQuoteData] = React.useState(null);
    const [remarks, setRemarks] = React.useState("");
    const [quoteIds, setQuoteIds] = React.useState([]);
    const [customReportId, setCustomReportId] = React.useState(null);
    const [quoteType, setQuoteType] = React.useState("AUTO");
    

    let reportId = searchParams.get("reportId");
    
    React.useEffect(()=>{
        fetchGet(Urls.GetQuoteByReportId + "?reportId=" + reportId, window.localStorage.getItem('token'))
        .then(r=>{
            console.log(r);
            setQuoteData(r.data);
            setQuoteType(r.data['quoteType']);
            setQuoteIds(r.data['quotes'].map(q=>q['quote_id']));
            setCustomReportId(r.data['quotes'].length>0?r.data['quotes'][0]['custom_report_id']:0);
        })
    }, [])

    if(quoteData===null){
        return <></>
    }


    return <>
        <div>
        <div className="text-[#ffffff] font-extrabold" style={{textAlign: 'center', fontSize: 18}}>Quotes for #{reportId}</div>
            <br/>
            <div className="container grid grid-cols-1 lg:grid-cols-1 gap-3 items-center justify-center">
                {
                    quoteData.quotes.map(quote=>{
                        return <>
                            <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full">
                                <div>
                                    <div className="text-[#00ff99] font-extrabold ">{quote.quote_name}</div>
                                    <div className="text-[#888888] font-medium ">{quote.summary}</div>
                                    <br/>
                                    <div className="text-[#ffffff] font-medium ">{quote.template_content}</div>
                                </div>
                            </div>
                        </>
                    })
                }
            </div>
        </div>
        <div>
        <div className="flex justify-center">
            <div className="flex flex-col sm:flex-row items-center justify-center p-2 gap-3">
                <Button  variant="pp" onClick={()=>{
                    fetchPost(Urls.AcceptQuote, {customReportId, quoteIds, quoteType}, window.localStorage.getItem('token'))
                    .then(r=>{
                        if(!r.error){
                            window.alert("Quote accepted");
                        }
                        navigate(-1)
                    })
                }}>
                    <span className="px-3">Accept</span>
                </Button>
                <Button  variant="warning" onClick={()=>{
                    fetchPost(Urls.RejectQuote, {customReportId, quoteIds, quoteType}, window.localStorage.getItem('token'))
                    .then(r=>{
                        if(!r.error){
                            window.alert("Quote rejected");
                        }
                        navigate(-1)
                    })
                }}>
                    <span className="px-3">Reject</span>
                </Button>
            </div>
        </div>
        <br/>
        <div className="flex justify-center">
            <div className="flex flex-col sm:flex-row items-center justify-center gap-3">
                <Input style={{color: 'white'}} value={remarks} onChange={(e)=>{
                    setRemarks(e.target.value);
                }} />
                <Button  variant="warning" onClick={()=>{
                    fetchPost(Urls.RequestQuoteCorrections, {customReportId, quoteIds, quoteType, remarks}, window.localStorage.getItem('token'))
                    .then(r=>{
                        if(!r.error){
                            window.alert("Changes are requested.");
                        }
                        navigate(-1)
                    })
                }}>
                    <span className="px-3">Request Corrections</span>
                </Button>
            </div>
        </div>
        </div>
    </>
}

export default Quote;