// import { Modal } from "@material-ui/core"
// import { Box, Typography } from "@mui/material"
import { connect, useDispatch } from "react-redux"
import { toggleAlertDialog } from "../actions"
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure,Input} from "@nextui-org/react";

const mapStateToProps = (state) => {
    return {
        dialog: state.DialogReducer
    }
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

const AlertMessage = (props) => {
    const dispatch = useDispatch();
    return <>
        <>
      <Modal isOpen={Boolean(props.dialog.alertDialog.flag)} onOpenChange={() => { dispatch(toggleAlertDialog(false, "", "")) }} isDismissable={false}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-2xl text-[#00ff99]">{props.dialog.alertDialog.title}</ModalHeader>
              <ModalBody className="text-white">
                    {props.dialog.alertDialog.message}
              </ModalBody>
              <ModalFooter>
                <Button color="danger"  onPress={onClose}>
                  Close
                </Button>
                {/* <Button color="primary" onPress={onClose}>
                  Action
                </Button> */}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
    </>
}

export default connect(mapStateToProps)(AlertMessage)