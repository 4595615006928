import React from 'react';
import { Page, Text, View, Document, StyleSheet,Image,Line,Svg } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import pdfLogo from '../images/pdf/PPLogo.png';
import pdfLogoIcon from '../images/pdf/PP.png';
import { black } from 'tailwindcss/colors';
import { Divider } from '@nextui-org/react';

// Create styles
const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
      width:'100%',
      height:'100%',
      backgroundColor:black,
    },
    image: {
        marginTop: 15,
        marginHorizontal: 50,
        width:'35%'
      },
      image2: {
        marginTop: 10,
        alignContent:'center',
        alignSelf:'center',
        width:'35%',
      },

    title: {
        marginTop:20,
        marginHorizontal: 50,
        fontSize: 18,
      color:'#00ff99',
    },
    paragraph:{
        marginHorizontal:50,
        color:"white",
        fontSize:12,
        marginTop:15,
        lineHeight:1.5,
        textAlign: 'justify',
    },
    paragraph3:{
        marginHorizontal:50,
        color:"white",
        fontSize:12,
        marginTop:15,
        lineHeight:1,
        textAlign: 'justify',
    },

    
    signature:{
        marginHorizontal:50,
        color:"white",
        fontSize:12,
        marginTop:30,
        lineHeight:1.5,
    },


    paragraph2:{
        marginHorizontal:50,
        color:"white",
        fontSize:12,
        marginTop:1,
        lineHeight:1,
    },
    
    heading:{
        marginHorizontal:50,
        color:"#00ff99",
        fontSize:14,
        marginTop:15,
    },


    subtitle: {
      fontSize: 22,
      marginTop:20,
      marginHorizontal: 50,
      color:'#00ff99',
      textAlign: 'justify',
    },

    appendix:{
        color:'#00ff99',
        marginHorizontal:50,
        marginTop:20,
        fontSize:12
    },

    theader : {
        marginTop : 10,
        fontSize : 12,
        fontWeight:'bold',
        paddingTop: 4 ,
        paddingLeft: 7 ,
        flex:1,
        height:20,
        backgroundColor : '#00ff99',
        borderColor : 'black', 
        borderBottom:1,
        borderLeft:1,
        borderTop:1,
        textAlign:'center',
    },

    tbody:{
         fontSize : 9,
         paddingTop: 4 , 
         paddingLeft: 7 , 
         flex:1, 
         borderColor : 'black', 
         borderBottom:1,
         borderLeft:1,
         backgroundColor : '#00ff99',
        },



  });

  const payment_data = {
    "items": [
      {
        "id": 1,
        "desc": "License Fee (Annual)",
        "fee": "NZD 5000 + GST due within 30 days of signing"
      },
      {
        "id": 2,
        "desc": "Renewal Fee",
        "fee": "NZD 5000 + GST due upon each annual renewal"
      },
      {
        "id": 3,
        "desc": "Late Payment Interest",
        "fee":"Interest at 10% for overdue amounts"
      } 
    ]
  }
  
  const TableHead = () => (
    <View style={{ width:'80%', flexDirection :'row',alignSelf:'center'}}>
        <View style={styles.theader}>
            <Text >Service/Description</Text>   
        </View>
        <View style={styles.theader}>
            <Text>Fee/Payment Terms</Text>   
        </View>
    </View>
);

  const TableBody = () => (
    payment_data.items.map((payment)=>(
     <>
         <View style={{ width:'80%', flexDirection :'row',alignSelf:'center'}}>
             <View style={styles.tbody}>
                 <Text >{payment.desc}</Text>   
             </View>
             <View style={styles.tbody}>
                 <Text>{payment.fee} </Text>   
             </View>
         </View>
     </>
    ))
 );



  Font.registerHyphenationCallback(word => [word]);

  // Create Document Component
  const MyDocument = () => (
    <Document>
    <Page size="A4" style={styles.body}>
   
      <Image
        style={styles.image}
        src={pdfLogo}
      />
       <Text style={styles.title}>Software Licensing Agreement</Text>
      <Text style={styles.subtitle}>
      This Agreement is entered into by and between Plot Potential Ltd ('Licensor') and the Real Estate Agency ('Licensee') for the use of Plot Potential’s software-as-a-service (SAAS) solution.
      </Text>
      <Svg style={{height:"10",width:"100%"}}>
          <Line x1="50" y1="10" x2="475" y2="10" strokeWidth={1} stroke="rgb(255,255,255)" />
      </Svg>

        <Text style={styles.paragraph}>
        This Agreement is effective from [Effective Date]___________ for a period of one (1) year, renewable upon mutual agreement.
        </Text>

      <Text style={styles.heading}>
      1. Grant of License
      </Text>
      <Text style={styles.paragraph}>
      The Licensor hereby grants the Licensee a limited, non-exclusive, non-transferable license to use the Plot Potential platform. The license permits access solely for internal business operations relating to property analysis and reporting. Licensee agrees not to sub-license, share, or transfer the software or any of its rights to third parties.
      </Text>
      <Text style={styles.heading}>
      2. Intellectual Property and Ownership
      </Text>
      <Text style={styles.paragraph}>
      All rights, title, and interest in and to Plot Potential's software, including but not limited to any underlying systems, designs, and proprietary algorithms, remain with the Licensor. Licensee receives no ownership rights and must respect all intellectual property rights in accordance with New Zealand law.
      </Text>
      <Text style={styles.heading}>
      3. Restrictions and Responsibilities
      </Text>
      <Text style={styles.paragraph}>
      Licensee agrees not to reverse-engineer or create derivative works based on the platform. All actions must comply with New Zealand laws and Licensor’s usage policy. Unauthorized export of software or technology is strictly prohibited.
      </Text>







      <Text style={styles.heading} break>
      4. License Fees and Payment Terms
            </Text>
      <Text style={styles.paragraph}>
      Licensee shall pay an annual licensing fee as outlined in Schedule A of this Agreement. Payments are due within 30 days of the Effective Date or, for renewals, within 30 days of the renewal date. Any overdue amounts will accrue interest at a rate specified by the Licensor, and failure to remit payment may result in suspension of access.
      </Text>
      <Text style={styles.heading}>
      5. Data Privacy and Security
            </Text>
      <Text style={styles.paragraph}>
      Licensor will adhere to the New Zealand Privacy Act 2020 for all data handling processes. Personal data will be handled in line with Licensor’s Privacy Policy. Licensee agrees to notify users regarding data handling and to secure necessary consents. Licensor’s full Privacy Policy can be found in Appendix B.
      </Text>
      <Text style={styles.heading}>
      6. Confidentiality
            </Text>
      <Text style={styles.paragraph}>
      Both parties agree to maintain the confidentiality of all proprietary and sensitive information shared under this Agreement. Licensee may not disclose any non-public features, functionality, or performance information of the software without Licensor’s written consent. Licensor will protect any personal data shared by Licensee per privacy laws and guidelines.
      </Text>
      <Text style={styles.heading}>
      7. Service Availability and Uptime
            </Text>
      <Text style={styles.paragraph}>
      Licensor will make reasonable efforts to ensure availability of the Plot Potential platform at least 99% of the time, excluding scheduled maintenance. In the event of any substantial service outage, Licensee may be entitled to a prorated refund of the licensing fee for the downtime period.
      </Text>
      <Text style={styles.heading}>
      8. Termination and Renewal
            </Text>
      <Text style={styles.paragraph}>
      This Agreement will automatically terminate after one (1) year unless renewed by mutual agreement. Either party may terminate the Agreement upon thirty (30) days’ written notice for convenience or upon a material breach by the other party, provided the breach is not remedied within fifteen (15) days of notice.
      </Text>
      <Text style={styles.heading}>
      9. Limitation of Liability
            </Text>
      <Text style={styles.paragraph}>
      To the fullest extent permitted by law, Licensor’s liability under this Agreement shall be limited to the total licensing fees paid by Licensee for the prior year. Licensor is not liable for any indirect, incidental, or consequential damages arising from the use of the software, data losses, or inability to access the platform.
      </Text>


        <Text style={styles.heading}>
        10. Compliance with Local Laws
        </Text>
      <Text style={styles.paragraph}>
      Both parties agree to adhere to applicable New Zealand laws, including the Privacy Act 2020 and Fair Trading Act 1986. Any disputes arising from this Agreement shall be resolved under the jurisdiction of New Zealand courts.
      </Text>
      <Text style={styles.heading}>
      Appendices
      </Text>

      <Text style={styles.paragraph3}>
      Appendix A: Fee Schedule and Payment Terms
      </Text>
      <Text style={styles.paragraph3}>
      Appendix B: Privacy Policy (see attached document)
      </Text>
      <Text style={styles.paragraph3}>
      Appendix C: General Terms and Conditions (see attached document)
      </Text>

      <Text style={styles.heading}>
      Signatures
      </Text>

      <Text style={styles.signature}>
      Signed for Licensor:
      </Text>
      <Text style={styles.paragraph2}>
      [Signature]    Date: _______
      </Text>


      <Text style={styles.signature}>
      Signed for Licensee:
      </Text>
      <Text style={styles.paragraph2}>
      [Signature]   Date: _______
      </Text>



      <Text style={styles.appendix}>
        Appendix A: Fee Schedule and Payment Terms
      </Text>

        <TableHead/>
        <TableBody/>

      <Text style={styles.appendix}>
      Appendix B: Privacy Policy
      </Text>
      <Text style={styles.paragraph}>
      Plot Potential Ltd operates in compliance with the New Zealand Privacy Act 2020 ('NZ Privacy Act') to safeguard personal information. We collect and process three main categories of personal information:
      </Text>
      <Text style={styles.paragraph}>
      - Account and Marketing Data: Includes personal details collected during account creation and through usage patterns (e.g., names, emails, contact information, billing details).
      </Text>
      <Text style={styles.paragraph}>
      - Client Data: Personal information collected by clients, handled per Plot Potential’s Terms of Use.
      </Text>
      <Text style={styles.paragraph} >
      - Third-Party Data: Information sourced from third parties to support services.
      </Text>
  
      <Text style={styles.paragraph} break>
      Data is used solely for providing services, improving functionality, and marketing purposes in line with user consent. We implement security measures to prevent unauthorized data access. Users have the right to access, correct, and delete personal information as provided under the Privacy Act.
      </Text>


      <Text style={styles.paragraph}>
      For complete information on how we handle data, users can refer to our Privacy Policy at www.plotpotential.co.nz/privacy or contact us directly at contact@plotpotential.co.nz.
      </Text>
      <Text style={styles.appendix}>
      Appendix C: General Terms and Conditions
      </Text>

      <Text style={styles.paragraph}>
      These Terms govern use of the Plot Potential platform and services by Licensee and authorized users. Acceptance of these Terms constitutes agreement to abide by all conditions, including:
    </Text>

    <Text style={styles.paragraph}>
    - Scope of Use: Licensee agrees to use the platform solely for internal business purposes. Reselling or distributing the software or its contents without Licensor’s authorization is prohibited.
    </Text>
    <Text style={styles.paragraph}>
    - Fees and Payment: Fees are defined in Schedule A and must be paid as per the specified terms. Late payments may result in interest charges or suspension of access.
    </Text>
    <Text style={styles.paragraph}>
    - Intellectual Property: Plot Potential retains ownership of the platform, its underlying systems, and any proprietary data or methods used within.
    </Text>
    <Text style={styles.paragraph}>
    - Liability Limitation: Licensor disclaims liability for indirect damages or data loss, with liability capped at the total amount paid by Licensee.
    </Text>
    <Text style={styles.paragraph}>
    - Termination: Either party may terminate the Agreement for breach or on expiration. Licensee agrees to cease all access upon termination.
    </Text>
    <Text style={styles.paragraph}>
    These Terms are governed by New Zealand law, and disputes shall be handled by the New Zealand courts. Full Terms and Conditions are available at www.plotpotential.co.nz/privacy or by contacting us at contact@plotpotential.co.nz
    </Text>

   
    <Image
        style={styles.image2}
        src={pdfLogo}
      />

    </Page>
  
  
    </Document>
  );



//   ReactPDF.render(<GenerateLicense />);


   const GenerateLicense = () => (



    <PDFViewer style={{width:'100%',height:'100vh'}}>
      <MyDocument />
    </PDFViewer>
  );

  export default GenerateLicense;