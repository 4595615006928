import HomeHeader from "../components/HomeHeader";
import HomeTab from "../components/Profile/HomeTab";


const PlotWorksPage = (props) => {
    return <>
        <HomeHeader />
        <div className="md:mx-20 md:my-10 my-5 mx-10">
            <div>
                Coming Soon
            </div>
        </div>
    </>
}

export default PlotWorksPage;