import React, { useState,useEffect } from 'react';
import HeaderSection from './HeaderSection';
import FooterSection from './FooterSection';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='pb-10 pt-[100px] bg-zinc-900 text-justify scroll-mt-[72px]'  style={{ fontFamily: 'Verdana, sans-serif' }}>



<div className='text-[#00ff99]  text-center text-4xl'>
Privacy Policy
</div>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
1. Privacy policy 
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
1.1. Project-X Management Limited (a New Zealand company) (<span className='text-white'>“us”</span> or <span className='text-white'>“we”</span> or <span className='text-white'>“our”</span>) will comply with the Privacy Act 2020 (NZ) in relation to any personal information about a natural person you supply to us or gathered as a result of your use of our website, Plot Potential, when you make a payment to us, or when we provide you with our services (<span className='text-white'>“Your Data”</span>).
</div>
<div>
1.2. We may change or update this policy from time to time. Any changes to this policy take effect when we publish the amended policy on our website, plotpotential.co.nz.
</div>
<div>
1.3. This policy was last updated on:
</div>
</div>
</section>

<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
2. What personal information do we collect?
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
2.1. We may collect the following types of Your Data from you:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>2.1.1.	Full Name</div>
<div>2.1.2.	Postal, mailing, or street address</div>
<div>2.1.3.	Location (country and/or region)</div>
<div>2.1.4.	Email Address</div>
<div>2.1.5.	Telephone or mobile number</div>
<div>2.1.6.	Age, age-range, or birth date</div>
<div>2.1.7.	Profession, occupation, or job title</div>
<div>2.1.8.	IP addresses and/or other device identifying data</div>
<div>2.1.9.	Billing information (such as credit card or bank account details)</div>
<div>2.1.10. Any additional personal information that you provide us directly or indirectly through our services.</div>
<div>2.1.11. Images, videos, audio, or photographs of you, as may be provided by you.</div>
<div>2.1.12. Information that is publicly available.</div>
</div>
</section>

<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
3.	Methods of collection
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
3.1.	We collect Your Data in many ways, including:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>3.1.1.	When you set up/create, and in our administration of, any user account for Plot Potential and/or our Services (as defined in our Terms and Conditions).</div>
<div>3.1.2.	when you access and use our website (such as through cookies), and when you access and use Plot Potential.</div>
<div>3.1.3.	when we provide our services to you.</div>
<div>3.1.4.	When you request information about Plot Potential and/or our Services. </div>
<div>3.1.5.	When you sign up for our newsletter.</div>
<div>3.1.6.	when you interact with us through email, over the phone, or in any other form of correspondence.</div>
<div>3.1.7.	when you make a payment to us.</div>
<div>3.1.8.	From third parties in connection with their lawful collection and use of Your Data (for example, information provided by LINZ about home or land ownership).</div>
</div>
</section>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
4.	Purpose
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
4.1.	We will use Your Data only for the purpose for which it is supplied to or gathered by us and will not use it for any other purpose or supply to any third party except as required by law or authorised by you.
</div>
<div>
4.2.	We may collect, hold and use Your Data for the following purposes:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>4.2.1.	To provide you with the Services and access and use of Plot Potential.</div>
<div>4.2.2.	To market Plot Potential and related services to you, including through electronic communications (such as email, SMS messaging).</div>
<div>4.2.3.	To improve Plot Potential and/or the Services provided to you.</div>
<div>4.2.4.	To comply with our legal obligations.</div>
<div>4.2.5.	As required to process any payments made to us.</div>
<div>4.2.6.	For research and statistical purposes, but only where Your Data has been anonymised and cannot identify you. </div>
</div>
</section>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
5.	Who we provide Your Data to / Disclosure of Your Data
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
5.1.	We may provide third parties with Your Data where we have anonymised it so that you are not identifiable by it, as anonymised statistical information.
</div>
<div>
5.2.	We will also disclose Your Data to:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>5.2.1.	Our professional advisers (such as lawyers, accountants, auditors) where necessary for us to comply with our legal obligations or to get legal advice.</div>
<div>5.2.2.	Comply with any valid order of a Court of competent jurisdiction or otherwise comply with the law (for example, the Office of the Privacy Commissioner in order to handle complaints or as otherwise required by the Privacy Act).</div>
</div>
</section>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
6.	Security
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
6.1.	We will take all reasonable steps we can to keep Your Data safe and secure. We will disclose Your Data to only third parties who may provide You with services or carry out administrative or other functions on our behalf . Some of these third parties may be based overseas.
</div>
</div>
</section>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
7.	Your access to data and information
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
7.1.	You may request access to Your Data at any time to confirm or verify the accuracy of Your Data, and to alter, remove, or add to Your Data in order to maintain the accuracy of that information.
</div>
<div>
7.2.	Your Data always remains your property; You may request a copy of Your Data as held by us by emailing us at [address].  
</div>
</div>
</section>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
8.	General
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
8.1.	If You choose not to provide us with Your Data under this Privacy Policy, then we may not be able to provide you with access or use of part of or all of our services, process any payments, or information about our services or payments.
</div>
<div>
8.2.	We may use cookies (which are a small text files containing small pieces of data stored on a device when browsing a website to enable the website host (i.e. us) to record information about that device’s use of the website) and similar technologies to help operate our website efficiently. If you do not wish to use cookies, you may choose to disable cookies in your chosen internet browser’s settings.
</div>
</div>
</section>

    </div>
  )}


  export default PrivacyPolicy;
