import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import * as React from 'react';
// import EmpLogoImg from './images/emp_logo.png';
import LandingPage from './pages/LandingPage';
import MapPage from './pages/MapPage';
import Loader from './components/Loader';
import { connect, useDispatch } from 'react-redux';
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";
import { Navigate } from 'react-router-dom';

import AlertMessage from './components/AlertMessage'
import PaymentWaitingPage from './pages/PaymentWaitingPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsConditionsPage from './pages/TermsConditionsPage'
import SignInPage from './pages/SignInPage';
import HomePage from './pages/HomePage';
import OrdersPage from './pages/OrdersPage';
import ProfilePage from './pages/ProfilePage';
import OrderAutoReport from './components/OrderAutoReport';
import ShowSamplePlans from './components/ShowSamplePlans';
import ReportPage from './pages/ReportPage';
import PaymentDetailsPage from './pages/PaymentDetailsPage';
import SignUpPage from './pages/SignUpPage';
import DownloadManualReport from './pages/DownloadManualReport';
import AboutUsPage from './pages/AboutUsPage';
import CrmInfoPage from './pages/CrmInfoPage';
import ReportsInfoPage from './pages/ReportsInfoPage';
import ClientDetailsPage from './pages/ClientDetailsPage';
import TeamPage from './pages/TeamPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ReportLoader from './components/ReportLoader';
import QuotePage from './pages/QuotePage';
import ProductGuidePage from './pages/ProductGuidePage';
import InfoPage from './pages/InfoPage';
import  GenerateLicense  from './components/GenerateLicense';
import BookDemoPage from './pages/BookDemo';
import SubscriptionPage from './pages/SubscriptionPage';
import ReportsPage from './pages/ReportsPage';
import MySubscriptionPage from './pages/MySubscriptionPage';
import PlotWorksPage from './pages/PlotWorksPage';





// library.add(faMap, faCaretRight, faCaretLeft)

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer,
        map: state.MapReducer,
        dialog: state.DialogReducer
    }
}

function App(props) {

    let curUrl = window.location.href.replace(/https?:\/\//i, "").replace(window.location.host, '');
    curUrl = curUrl.split("#")[0];

    const router = createBrowserRouter([
        {
            path: "/",
            element: <LandingPage />,
            // element: Boolean(props.data.user) ? <LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/map",
            // element: <MapPage />,
            element: Boolean(props.data.user) ? props.data.user.email.indexOf("plotpotential.co.nz")>-1?<MapPage />:<LandingPage/> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
            // element: Boolean(props.data.user) ? <LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/payment",
            // element: <PaymentWaitingPage paymentFor='order' />
            element: Boolean(props.data.user) ? <PaymentWaitingPage paymentFor='order' /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
            // element: Boolean(props.data.user) ? <LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />

        },
        {
            path: "/subscription",
            // element: <PaymentWaitingPage paymentFor='subscription' />
            element: Boolean(props.data.user) ? props.data.user.email.indexOf("plotpotential.co.nz")>-1?<PaymentWaitingPage paymentFor='subscription' />:<LandingPage/> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
            // element: Boolean(props.data.user) ? <LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />

        },
        {
            path: "/report",
            // element: <ReportPage />
            element: Boolean(props.data.user) ? props.data.user.email.indexOf("plotpotential.co.nz")>-1?<ReportPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
            // element: Boolean(props.data.user) ? <LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />

        },
        {
            path: "/paymentDetails",
            // element: <PaymentDetailsPage />
            element: Boolean(props.data.user) ? props.data.user.email.indexOf("plotpotential.co.nz")>-1?<PaymentDetailsPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
            // element: Boolean(props.data.user) ? <LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />

        },
        {
            path: "/privacy-policy",
            // element: <PrivacyPolicyPage />
            element: Boolean(props.data.user) ? <PrivacyPolicyPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/signIn",
            // element: <SignInPage />
            element: !Boolean(props.data.user) ? <SignInPage /> : <Navigate to={{ pathname: '/' }} />
        },
        {
            path: "/signUp",
            // element: <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
            element: !Boolean(props.data.user) ? <SignUpPage /> : <Navigate to={{ pathname: '/home' }} />
        },
        {
            path: "/home",
           element: Boolean(props.data.user) ? props.data.user.email.indexOf("plotpotential.co.nz")>-1?<HomePage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
            //  element: Boolean(props.data.user) ? <LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/orders",
            // element: Boolean(props.data.user) ? <LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
            element: Boolean(props.data.user) ? props.data.user.email.indexOf("plotpotential.co.nz")>-1?<OrdersPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/profile",
            // element: Boolean(props.data.user) ? <LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
             element: Boolean(props.data.user) ? props.data.user.email.indexOf("plotpotential.co.nz")>-1?<ProfilePage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/reports",
           element: Boolean(props.data.user) ? props.data.user.email.indexOf("plotpotential.co.nz")>-1?<ReportsPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
            //  element: Boolean(props.data.user) ? <LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/my-subscription",
           element: Boolean(props.data.user) ? props.data.user.email.indexOf("plotpotential.co.nz")>-1?<MySubscriptionPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
            //  element: Boolean(props.data.user) ? <LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/downloadMR",
            element: Boolean(props.data.user) ? <DownloadManualReport /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/viewQuote",
            element: Boolean(props.data.user) ? <QuotePage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/policy",
            element: Boolean(props.data.user) ? <PrivacyPolicyPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/terms",
            element: Boolean(props.data.user) ? <TermsConditionsPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/aboutus",
            element: Boolean(props.data.user) ? <AboutUsPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/aboutreports",
            element: Boolean(props.data.user) ? <ReportsInfoPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/aboutcrm",
            element: Boolean(props.data.user) ? <CrmInfoPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/clientInfo",
            // element: Boolean(props.data.user) ? <LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
            element: Boolean(props.data.user) ? props.data.user.email.indexOf("plotpotential.co.nz")>-1?<ClientDetailsPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/team",
            element: Boolean(props.data.user) ? <TeamPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/resetPassword",
             element: <ResetPasswordPage />
        },
        // {
        //     path: "/productGuide",
        //     element: Boolean(props.data.user) ? <ProductGuidePage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        // },
        {
            path: "/info",
             element: <InfoPage />
        },
        {
            path: "/pdf",
             element: <GenerateLicense />
        },
        {
            path: "/bookDemo",
             element: <BookDemoPage />
        },
        {
            path: "/pricing",
             element: <SubscriptionPage />
        },
        {
            path: "/plotworks",
             element: <PlotWorksPage />
        },
    ]);

    return (
        <>
            <RouterProvider router={router} />
            <Loader />
            <ReportLoader />
            {props.dialog.manualOrderDialog.flag ? (
                <OrderAutoReport
                    selectedAddress={{
                        name: props.dialog.manualOrderDialog.parcel ? props.dialog.manualOrderDialog.parcel.address : "",
                        id: props.dialog.manualOrderDialog.parcel ? props.dialog.manualOrderDialog.parcel.addressId : ""
                    }}
                />) : ''}
            {props.dialog.samplePlansDialog.flag ? (
                <ShowSamplePlans />) : ''}
            <AlertMessage />
        </>
    );
}
export default connect(mapStateToProps)(App);