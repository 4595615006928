import HomeHeader from "../components/HomeHeader";
import OrderList from "../components/Orders/OrderList";


const OrdersPage = (props) => {
    return <>
        <HomeHeader />
        <OrderList />
    </>
}

export default OrdersPage;