import { useDispatch } from "react-redux";
import AppHeader from "../components/AppHeader";
import HeaderSection from "../components/Landing/HeaderSection";
import FooterSection from "../components/Landing/FooterSection";
import TeamSection from "../components/Landing/TeamSection";
import Cursor from '../components/Cursor/Cursor';
import ScrollToAnchor from '../utils/ScrollToAnchor';


const TeamPage = () => {
    return <>
        <Cursor/>
        <ScrollToAnchor />

        <HeaderSection/>
            <TeamSection/>
        <FooterSection />
        </>
        }

export default TeamPage;