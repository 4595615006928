import { useDispatch } from "react-redux";
import { setUser, toggleLoader } from "../actions";
import HomeHeader from "../components/HomeHeader";
import SignInForm from "../components/SignIn/SignInForm";
import '../styles/signin.css';
import { validateToken } from "../utils/helpers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import Cursor from '../components/Cursor/Cursor';


const SignInPage = () => {

    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const returnUrl = searchParams.get("returnUrl");
    const navigate = useNavigate()

    useEffect(() => {
        console.log("Checking user login")
        // dispatch(toggleLoader(true));
        validateToken().then(user => {
            // dispatch(toggleLoader(false));
            if (user) {
                dispatch(setUser(user));
                if (returnUrl) {
                    console.log("Going to ", returnUrl)
                    navigate(returnUrl);
                    // dispatch(setReturnUrl(null));
                }
            } else {
                dispatch(setUser(null));
            }
        })
    }, []);



    return <>

<Cursor/>

        <HomeHeader />
        <SignInForm />
    </>
}

export default SignInPage;