import { useDispatch } from "react-redux";
import AppHeader from "../components/AppHeader";
import HeaderSection from "../components/Landing/HeaderSection";
import FooterSection from "../components/Landing/FooterSection";
import CrmSection from "../components/Landing/CrmSection";
import Cursor from '../components/Cursor/Cursor';
import ScrollToAnchor from '../utils/ScrollToAnchor';

const CrmInfoPage = () => {
    return <>
        <Cursor/>
        <ScrollToAnchor />

        <HeaderSection/>
        <CrmSection/>
        <FooterSection />
        </>
        }

export default CrmInfoPage;