import { connect } from 'react-redux';
import LoaderGif from '../images/pltp-report-loading.gif';

const mapStateToProps = state => {
    return {
        map: state.MapReducer
    }
};

const ReportLoader = (props) => {
    return <>
        <div style={{
            display: props.map.showReportLoader ? 'block' : 'none',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            textAlign: 'center',
            paddingTop: 100,
            backgroundColor: '#000000BB',
            zIndex: 2000
        }}>
            <div style={{
                marginTop: 50,
                marginLeft: 'calc(50% - 200px)',
                width: '400px',
                background: 'white',
                textAlign: 'center',
                padding: 20,
                borderRadius: 10,
                background: 'rgba(60, 60, 60, 0.4)',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                backdropFilter: 'blur(5px)',
                webkitBackdropFilter: 'blur(5px)',
                color: '#00FF99'
            }}>
                <div style={{ display: 'inline-block' }}><img style={{width: '360px'}}  src={LoaderGif} /></div>
                <div>{props.map.loaderReportMessage}</div>
            </div>
        </div>
    </>
}

export default connect(mapStateToProps)(ReportLoader);