
import React, { useEffect } from 'react';
import {Accordion, AccordionItem} from "@nextui-org/react";


const CrmSection = () => {

      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const services =
    [
        {
            id:"1",
            title:"Design management portal",
            content:"Your project's portal gives you an overview of all current and future tasks related to your design, as well as up-to-date information on their progress. Assign tasks to people, keep track of their progress, and easily stick to deadlines."
        },
        {
            id:"2",
            title:"Build Mangement Portal",
            content:"The build management platform maps out all stages of the building process and CMP (construction management programme). It also provides pre-inspection checklists for each stage, to give you confidence that all boxes are ticked before the council inspections."
        },
        {
            id:"3",
            title:"File and document management",
            content:"Our platform organises and saves all relevant paperwork as your project goes through the design process. You can edit view permissions to allow consultants and other stakeholders access to documentation as necessary."
        },
        {
            id:"4",
            title:"Chat with consultants and stakeholders",
            content:"The platform has a centralised communication system that allows you to chat directly with consultants and stakeholders."
        },
        {
            id:"5",
            title:"Transparent task and time management",
            content:"Jobs, tasks and deadlines can be directly assigned to consultants, ensuring accountability and timeliness. Easily view tasks' progress, and flag where tasks are slipping behind."
        }
    ];

    return <>
      <section  className='scroll-mt-[72px]' style={{ fontFamily: 'Verdana, sans-serif' }}>
            <div style={{ textAlign: 'center', padding: 0, background: '#111111', paddingTop: 65, paddingBottom: 65 }}>
                <div elevation={0} style={{ textAnchor: 'middle', background: 'transparent', minHeight: '100vh' }}>
                <p className="head_font2 md:text-5xl text-4xl font-bold text-[#00ff99] pt-5 ">
                DESIGN & BUILD MANAGEMENT PLATFORM
                    </p>
                    <div className='content-text justify-center items-center text-center py-4'>
                    Our design and build management platform consolidates all aspects of the design and building process into one transparent, user-friendly system. 
                    </div>



            <div className=' flex items-center justify-center'>
            <div className='md:w-3/4 w-11/12'>


                    <Accordion variant='splitted'
            itemClasses={
                {
                    base:"!bg-[#333333] text-white hover:bg-gradient-to-r from-[#07b16d88] to-[#71aec088]  hover:text-white",
                    title:"text-white"
                }
            }
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            height: "auto",
            transition: {
              height: {
                type: "spring",
                stiffness: 500,
                damping: 30,
                duration: 1,
              },
              opacity: {
                easings: "ease",
                duration: 1,
              },
            },
          },
          exit: {
            y: -10,
            opacity: 0,
            height: 0,
            transition: {
              height: {
                easings: "ease",
                duration: 0.25,
              },
              opacity: {
                easings: "ease",
                duration: 0.3,
              },
            },
          },
        },
      }}
    >
            {services.map((item,idx)=>(
                <AccordionItem key={item.id} aria-label={item.id} title={item.title} className='text-left text-xl' >
                {item.content}
                </AccordionItem>
            ))}
            </Accordion>
            </div>
            </div>
                    </div>
                    </div>
                    </section>
</>
}


export default CrmSection;