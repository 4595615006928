import {
    combineReducers
} from 'redux';
import MapReducer from "./MapReducer";
import DialogReducer from "./DialogReducer";
import DataReducer from "./DataReducer";

const rootReducer = combineReducers({
    MapReducer,
    DialogReducer,
    DataReducer
});

export default rootReducer;