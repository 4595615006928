import HomeHeader from "../components/HomeHeader";
import ReportTable from "../components/Profile/reports/page";

const ReportsPage = (props) => {
    return <>
        <HomeHeader />
        <div className="items-center justify-center bg-transparent text-white text-center">
    <div className="p-6 px-0">
    <h3 className="text-[#00ff99] p-3">Reports</h3>
  <ReportTable/>
</div>
</div>
    </>
}

export default ReportsPage;