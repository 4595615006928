import { useDispatch } from "react-redux";
import AppHeader from "../components/AppHeader";
import HeaderSection from "../components/Landing/HeaderSection";
import FooterSection from "../components/Landing/FooterSection";
import PrivacyPolicy from "../components/Landing/PrivacyPolicy";
import Cursor from '../components/Cursor/Cursor';
import ScrollToAnchor from '../utils/ScrollToAnchor';

const PrivacyPolicyPage = () => {
    const dispatch = useDispatch();



    return <>
        <Cursor/>
        <ScrollToAnchor />

        <HeaderSection/>
        <PrivacyPolicy/>

<FooterSection />
        {/* <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppHeader />
            <Box component="main" sx={{ width: '100%' }}>
                <Toolbar style={{ backgroundColor: 'black' }} />
                <div style={{ textAlign: 'center', backgroundColor: 'black', color: '#00FF99', minHeight: '100vh' }}>

                    <Grid container>
                        <Grid item sm={12} md={3}></Grid>
                        <Grid item sm={12} md={6}>
                            <Typography variant="h6">
                                INTRODUCTION
                            </Typography>
                            <br />
                            <Typography variant="body2" style={{ color: '#ffffff', textAlign: 'justify' }}>
                                ProjectX Management Limited (we, us, our) complies with the New Zealand Privacy Act 2020 (the Act) when dealing with personal information.  Personal information is information about an identifiable individual (a natural person).
                                <br />
                                <br />
                                This policy sets out how we will collect, use, disclose and protect your personal information when you use our website and related services at www.plotpotential.co.nz.
                                <br />
                                <br />
                                This policy does not limit or exclude any of your rights under the Act.  If you wish to seek further information on the Act, see www.privacy.org.nz.
                            </Typography>

                            <Typography variant="h6">
                                CHANGES TO THIS POLICY
                            </Typography>
                            <br />
                            <Typography variant="body2" style={{ color: '#ffffff', textAlign: 'justify' }}>

                                We may change this policy by uploading a revised policy onto the website.  The change will apply from the date that we upload the revised policy.
                                <br />
                                <br />
                                This policy was last updated on September, 07th 2023.

                            </Typography>
                            <br />

                            <Typography variant="h6">
                                WHO DO WE COLLECT YOUR PERSONAL INFORMATION FROM
                            </Typography>
                            <br />



                        </Grid>
                        <Grid item sm={12} md={3}></Grid>

                    </Grid>


                </div>
            </Box>
        </Box> */}

    </>
}

export default PrivacyPolicyPage;