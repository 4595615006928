import React, { useState,useEffect } from 'react';

import { FaFilePdf } from 'react-icons/fa6';
import { Divider } from '@nextui-org/react';
const SubscriptionSection = () => {





  return (
    <>
        <div className='pb-10   pt-[100px] bg-black text-justify scroll-mt-[72px]'  style={{ fontFamily: 'Verdana, sans-serif' }}>

      <div class="bg-black py-6 sm:py-8 lg:py-12">
  <div class="mx-auto max-w-screen-xl px-4 md:px-8">
    <h2 class="mb-4 text-center text-2xl font-bold text-[#00ff99] md:mb-8 lg:text-5xl xl:mb-12 head_font2">Choose Your Monthly Plan</h2>
    <p class="mx-auto max-w-screen-md text-center text-[#00ff99] font-extrabold md:text-xl pb-16 head_font2 ">"See features in Independent, Diverse or Limitless."</p>

    <div class="mb-6 grid gap-6 sm:grid-cols-2 md:mb-8 lg:grid-cols-3 lg:gap-12">
      <div class="flex flex-col overflow-hidden rounded-3xl  border-2 border-yellow-500 sm:mt-8">


        <div class="flex flex-1 flex-col p-6 pt-8">
          <div class="mb-12">
            <div class="mb-2  text-2xl font-bold text-[#00ff99]">Independent</div>

           <div className='flex text-[#00ff99] justify-center  pt-4' >
           <sup className='text-2xl font-extrabold '>$</sup>
            <p className=' text-6xl font-extrabold '>49</p> 
            <sup className='text-lg head_font2 font-extrabold'>One <br/> Time</sup>
            </div>

            

            <div class="pt-2 pb-4 justify-center flex">
            <button class="block rounded-full bg-yellow-500 px-16 py-3 text-center text-sm font-semibold text-[#000]  md:text-xl">Purchase</button>
          </div>
            <div class="space-y-4">
            <li class="flex items-center gap-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 shrink-0 " viewBox="0 0 20 20" fill="#00ff99">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <span className='text-[#00ff99]'>1 Plot Potential Report</span>
          </li>
          <hr className='w-4/5 bg-[#00ff99] h-1'/>
          <li class="flex items-center gap-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 shrink-0" viewBox="0 0 20 20" fill="#00ff99">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <span className='text-[#00ff99]'>Unlimited GIS searches</span>
          </li>
          <hr className='w-4/5 bg-[#00ff99] h-1'/>
          <li class="flex items-center gap-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 shrink-0" viewBox="0 0 20 20" fill="#00ff99">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <span className='text-[#00ff99]'>Limited Building Data</span>
          </li>
            </div>


          </div>      
        </div>


      </div>
    
      <div class="flex flex-col overflow-hidden rounded-3xl  border-2 border-[#ce802e]">

        <div class="flex flex-1 flex-col p-6 pt-8">
          <div class="mb-12">
            <div class="mb-2  text-2xl font-bold text-[#00ff99] flex gap-8">
              <div>Limitless</div>

            <svg version="1.1" id="Layer_1" width={30} height={30} viewBox="0 0 491.5 491.5" fill="#000000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> <ellipse fill="#E56353" cx="245.76" cy="289.774" rx="174.336" ry="102.707"></ellipse> 
            <path fill="#F6C358" d="M427.392,402.452H245.76V94.456c0,0,14.005,87.609,65.338,151.954 c29.694,37.221,89.246,37.368,119.454,0.508c18.297-22.327,29.411-51.504,35.936-85.54"></path> 
            <g> <path fill="#FCD462" d="M64.128,402.452H245.76V94.456c0,0-14.005,87.609-65.338,151.954 c-29.694,37.221-89.246,37.368-119.455,0.508c-18.297-22.327-29.41-51.504-35.935-85.54"></path>
             <rect x="52.306" y="402.465" fill="#FCD462" width="386.918" height="23.316"></rect> </g> <g>
               <circle fill="#E56353" cx="245.76" cy="94.728" r="29.01"></circle> <circle fill="#E56353" cx="462.49" cy="176.755" r="29.01"></circle>
                <circle fill="#E56353" cx="29.01" cy="176.755" r="29.01"></circle> </g> 
                <ellipse fill="#00ff99" cx="245.76" cy="307.489" rx="39.68" ry="59.023"></ellipse> </g></svg>
            </div>

            <div className='flex text-[#00ff99] justify-center font-extrabold pt-4' >
           <sup className='text-2xl '>$</sup>
            <p className='text-5xl'>By
             <br/> request</p>
            </div>

        
          <div class="pt-2 pb-4 justify-center flex">
            <button class="block rounded-full bg-[#ce802e] px-16 py-3 text-center text-sm font-semibold text-[#000]  md:text-xl">Get a Quote</button>
          </div>

            <div class="space-y-4">
            <li class="flex items-center gap-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 shrink-0 " viewBox="0 0 20 20" fill="#00ff99">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <span className='text-[#00ff99]'>Unlimited Reports</span>
          </li>
          <hr className='w-4/5 bg-[#00ff99] h-1'/>
          <li class="flex items-center gap-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 shrink-0" viewBox="0 0 20 20" fill="#00ff99">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <span className='text-[#00ff99]'>Hot Plots access</span>
          </li>
          <hr className='w-4/5 bg-[#00ff99] h-1'/>
          <li class="flex items-center gap-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 shrink-0" viewBox="0 0 20 20" fill="#00ff99">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <span className='text-[#00ff99]'>Access to Plot Works</span>
          </li>
            </div>
          </div>
        </div>
      </div>
  
      <div class="flex flex-col overflow-hidden rounded-3xl border-2 border-[#E6E5E3] lg:mt-8">

        <div class="flex flex-1 flex-col p-6 pt-8">
          <div class="mb-12">
            <div class="mb-2  text-2xl font-bold text-[#00ff99]">Diverse</div>

            <div className='flex text-[#00ff99] justify-center pt-4' >
           <sup className='text-2xl font-extrabold '>$</sup>
            <p className=' text-6xl font-extrabold '>199</p> 
            <sup className='text-lg head_font2 font-extrabold'>Per
             <br/> Month</sup>
            </div>

      

    
          <div class="pt-2 pb-4 justify-center flex">
            <button class="block rounded-full bg-[#E6E5E3] px-16 py-3 text-center text-sm font-semibold text-[#000]  md:text-xl">Purchase</button>
          </div>

            <div class="space-y-4">
            <li class="flex items-center gap-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 shrink-0 " viewBox="0 0 20 20" fill="#00ff99">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <span className='text-[#00ff99]'>Access to Plottie</span>
          </li>
          <hr className='w-4/5 bg-[#00ff99] h-1'/>
          <li class="flex items-center gap-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 shrink-0" viewBox="0 0 20 20" fill="#00ff99">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <span className='text-[#00ff99]'>Access to Plot Works</span>
          </li>
          <hr className='w-4/5 bg-[#00ff99] h-1'/>
          <li class="flex items-center gap-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 shrink-0" viewBox="0 0 20 20" fill="#00ff99">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <span className='text-[#00ff99]'>5 Reports per week</span>
          </li>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>





<h2 class="py-6 text-3xl md:text-4xl text-center font-extrabold text-[#00ff99] ">Independent</h2>
<div className='text-[#00ff99] text-center text-md md:text-xl pb-4'>Price: $49 (one-time payment for a one-time report)</div>
<div className='justify-center items-center flex flex-col '>
<ul class=" space-y-3 text-[#00ff99] w-11/12 md:w-3/4 lg:w-1/2">
    <li className='text-lg font-semibold text-[#00ff99] underline pb-3'>Features:</li>
    <li>
    o Automatic Reports: Comprehensive insights including  architecture, engineering, planning, and costing information.
    </li>
    <li>
    o Unlimited GIS Searches: Access detailed property layers.
    </li>
    <li>
    o Building Data: DVR information and comparative sales analysis.
    </li>
    <li className='text-md font-semibold text-[#00ff99] pt-3'>
    Ideal for: First-time property buyers, individual investors, and
    anyone seeking a detailed single-property report.
    </li>
</ul>
</div>


<div className='flex justify-center items-center '>
<hr className='w-4/5 bg-[#00ff99] h-1'/>
</div>


<h2 class="py-6 text-3xl md:text-4xl text-center font-extrabold text-[#00ff99] ">Diverse</h2>
<div className='text-[#00ff99] text-center text-md md:text-xl pb-4'>Price: $199/month</div>
<div className='justify-center items-center flex flex-col '>
<ul class=" space-y-3 text-[#00ff99] w-11/12 md:w-3/4 lg:w-1/2">
    <li className='text-lg font-semibold text-[#00ff99] underline pb-3'>Features:</li>
    <li>
    o Automatic & Manual Reports: Access 5 reports per week, offering architecture,
    engineering, and planning insights.
        </li>
    <li>
    o Enhanced Remote Sensing Features: Includes advanced property analytics.
    </li>
    <li>
    o Architectural and Engineering Representations: Detailed and visual data
    for projects.
        </li>
        <li>
        o Access to Plot Works: Collaborate with clients on land development projects through
 Plot Works. Use integrated tools like chat, file sharing, and task management to work
 seamlessly with clients and contractors.
        </li>
        <li>
        o Access to Plottie University: Explore learning guides, blogs, and insights
        into current issues in the development market.
        </li>
    <li className='text-md font-semibold text-[#00ff99] pt-3'>
    Ideal for: Architects, independent real estate professionals, builders, and
 investors.
    </li>
</ul>
</div>




<div className='flex justify-center items-center '>
<hr className='w-4/5 bg-[#00ff99] h-1'/>
</div>

<h2 class="py-6 text-3xl md:text-4xl text-center font-extrabold text-[#00ff99] ">Limitless</h2>
<div className='text-[#00ff99] text-center text-md md:text-xl pb-4'>Price: Tailored pricing for enterprise customers (contact us for a quote)</div>
<div className='justify-center items-center flex flex-col '>
<ul class=" space-y-3 text-[#00ff99] w-11/12 md:w-3/4 lg:w-1/2">
    <li className='text-lg font-semibold text-[#00ff99] underline pb-3'>Features:</li>
 o Unlimited Technical Reports and Assistance: Seamless support through
 our Plot Works network of in-house consultants.
 <li>
 o Hot Plots Access: Stay ahead with exclusive access to prime property opportunities.
 </li>
 <li>
 o Dedicated Client Relations Assistant: Personalized technical support and guidance.
 </li>
 <li>
 o Comprehensive Data and Features: Ownership history, CAD file extraction, GIS data layers, and
 business case documentation.
 </li>
 <li>
 o Access to Plot Works: Full access to our design and build management software, tailored for
 enterprise-level projects. Collaborate with clients on land development projects through Plot
 Works. Use integrated tools like chat, file sharing, and task management to work seamlessly
 with clients and contractors.
 </li>
 <li>
 o Access to Plottie University: Enjoy premium learning guides, blogs, and exclusive insights
 into the latest development trends and challenges.
 </li>
 <li>
 o Wastewater capacity check.
 </li>
 <li className='text-md font-semibold text-[#00ff99] pt-3'>
 Ideal for: Building and architectural corporates, real estate agencies, and financial
 institutions.
 </li>
 </ul>
 </div>




    </div>



    </>
    )
    }

export default SubscriptionSection;