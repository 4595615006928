// /**
//  * v0 by Vercel.
//  * @see https://v0.dev/t/bvVVDCNw9rK
//  * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
//  */
import { Card, CardContent, CardFooter, CardTitle, CardHeader } from "../../shadcn/components/ui/card"
import { Separator } from "../../shadcn/components/ui/separator"
import { Button } from "../../shadcn/components/ui/button"
import { fetchGet, fetchPost, toSlug } from "../../utils/helpers";

// import { Phone, Mail } from "lucide-react"
import { useNavigate } from "react-router-dom";

export default function PaymentDetails(props) {

    const navigate = useNavigate();

    console.log(props.paymentData);
    let token = window.localStorage.getItem('token');

    if (!props.paymentData) {
        return 'Error'
    }

    return (
        <div className="flex flex-col items-center justify-center  gap-4 p-2">
            <br />
            <Card className="w-full max-w-sm p-0 text-white border-none bg-[#111111] border-2">
                <CardHeader className="flex justify-center items-center">
                    <CardTitle className="text-[#00ff99]">Payment Details</CardTitle>
                </CardHeader>
                <CardContent className="grid gap-4">
                    <div className="flex items-center justify-between">
                        <div className="text-[#00ff99] font-extrabold ">Paid for</div>
                        <div className="font-medium">{props.paymentData['order_type'] === 'REPORT' ? 'Report' : 'Subscription'}</div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="text-[#00ff99] font-extrabold ">Amount</div>
                        <div className="font-medium">NZ$ {(parseFloat(props.paymentData['amount']) / 100).toFixed(2)}</div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="text-[#00ff99] font-extrabold ">Payment ID</div>
                        <div className="font-medium">#{props.paymentData['payment_id']}</div>
                    </div>
                    {
                        props.paymentData['order_type'] === 'REPORT' ? (<div className="flex items-center justify-between" style={{ background: '#222', cursor: 'pointer', padding: '16px 8px', borderRadius: 8 }} onClick={() => {
                            navigate('/report?id=' + props.paymentData['report_id'])
                        }}>
                            <div className="text-[#00ff99] font-extrabold ">Report Id</div>
                            <div className="font-medium">{props.paymentData['report_id']}</div>
                        </div>) : (<div className="flex items-center justify-between">
                            <div className="text-[#00ff99] font-extrabold ">Subscription Id</div>
                            <div className="font-medium">{props.paymentData['ref_id']}</div>
                        </div>)
                    }
                    <div className="flex items-center justify-between">
                        <div className="text-[#00ff99] font-extrabold ">Transaction Date</div>
                        <div className="font-medium">{new Date(props.paymentData['payment_date']).toLocaleDateString()}</div>
                    </div>
                    <Separator className="my-2" />
                    <div className="grid gap-3">
                        <div className="flex items-center gap-4 ">
                            {/* <div className="font-medium"><Mail color="#00ff99" /></div> */}
                            <div className="font-bold">contact@plotpotential.co.nz</div>
                        </div>
                        <div className="flex items-center gap-4">
                            {/* <div className="font-medium"><Phone color="#00ff99" /></div> */}
                            <div className="font-bold">+64 22 370 7760</div>
                        </div>
                    </div>
                </CardContent>
                <CardFooter className="flex w-full gap-5 p-4 md:p-6 items-center justify-center">
                    <div>
                        <Button variant="pp" className="text-black font-bold" onClick={() => {
                            navigate('/home')
                        }}>
                            Home
                        </Button>
                    </div>
                </CardFooter>
            </Card>

        </div>
    )
}

function CircleCheckIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" />
            <path d="m9 12 2 2 4-4" />
        </svg>
    )
}

function XIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            {/* <path d="M18 6 6 18" />
      <path d="m6 6 12 12" /> */}
            <path d="M16 8L8 16M8.00001 8L16 16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    )
}


