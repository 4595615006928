import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import { useEffect,useState } from 'react';
import { Input,Textarea, Select, SelectItem,Checkbox,Switch} from "@nextui-org/react";
import { TickIcon,CrossIcon } from "../icons/GeneralIcons";

export const PopUp = ({props}) => {  
    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    const [status, setStatus] = useState(0);
    const [msg, setMsg] = useState("");

    useEffect(()=>{



        if(props.status == false){
          setStatus(false);
          setMsg(props.msg);
          onOpen();
        }
        // else{
        //   setStatus(true);
        //   setMsg(props.msg);
        //   onOpen();
        // }

  }, [props]);

return(
    <>

      <Modal isOpen={isOpen} placement="center" onOpenChange={onOpenChange} isDismissable={false} isKeyboardDismissDisabled={true}>
        <ModalContent>
          {(onClose) => (
            <>
            <ModalHeader className="flex flex-col gap-1 text-center"></ModalHeader>
            <ModalBody className="flex flex-row justify-center p-5">
              {status ?
              <>
              <TickIcon width={20} height={20}/>
                <Button color="success">
                {msg}
                </Button>
                </>
                :
                <div className="flex flex-col items-center gap-4">
              <CrossIcon width={70} height={70}/>
                <Button color="danger">
                {msg}
                </Button>
                </div>
              }
            </ModalBody>
              <ModalFooter>
                <Button color="" variant="light" className="bg-[#00ff99] hover:text-black " onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
);
}