import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LogoImg from '../../images/logo.png';
import UserShieldImg from '../../images/user-shield.png';
import { Theme, Urls } from '../../utils/AppConfig';
import { GoogleLogin } from '@react-oauth/google';
import { validateToken } from '../../utils/helpers';
import { useDispatch } from "react-redux";
import { setUser, toggleLoader } from '../../actions';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BgVideo from '../../images/BgVideo.mp4';
import { Link } from 'react-router-dom';

const SignInForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [formValid, setFormValid] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const returnUrl = searchParams.get("returnUrl");

    const checkSignIn = (r) => {
        if (r.error) {
            return "Error"; //TODO
        }
        window.localStorage.setItem('token', r.data);
        validateToken()
            .then(user => {
                if (user) {
                    if (user.error) {
                        window.alert("Server error. Please, try again later.");
                    } else {
                        dispatch(setUser(user));
                        if (returnUrl) {
                            console.log("Going to ", returnUrl)
                            // navigate(returnUrl);
                            window.location.href = returnUrl;
                        } else {
                            window.location.href = '/';
                        }
                    }
                } else {
                    //TODO error
                    window.alert("Server error. Please, try again later.");
                }
            })
    }

    const signInUser = () => {
        dispatch(toggleLoader(true));
        fetch(Urls.ClientSignIn, {
            method: 'POST',
            body: JSON.stringify({
                email, password
            }),
            headers: {
                "Content-Type": "application/json",
            }
        }).then(r => r.json())
            .then(r => {
                dispatch(toggleLoader(false));
                console.log(r);
                checkSignIn(r)
            }).catch(err => {
                dispatch(toggleLoader(false));
                console.log(err);
                window.alert("Server error. Please, try again later.");
            })
    }

    const validateFields = () => {
        const validateEmail = (email) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        }
        let flag = true;
        if (!Boolean(email) || email.length === 0 || email.length > 100 || !validateEmail(email)) {
            flag = false;
        }
        if (!Boolean(password) || password.length === 0 || password.length > 100) {
            flag = false;
        }
        setFormValid(flag);

    }

    useEffect(() => { validateFields(); }, [
        password, email
    ])

    return <>
        <div className='sign-in-container'>
            <Container>
                <Row>
                    <Col xs={1}></Col>
                    <Col xs={10}>
                        <Row style={{ marginTop: 100 }}>
                            <Col lg={6} style={{ padding: 0 }}>
                                <div className='sign-in-logo-container'>
                                    <img src={LogoImg} />
                                </div>
                            </Col>
                            <Col lg={6} style={{ padding: 0 }}>
                                <div style={{ textAlign: 'center' }}>
                                    <div className='sign-in-user-img-container'>
                                        <img width="75" src={UserShieldImg} />
                                    </div>
                                </div>
                                <div className='sign-in-panel'>
                                    <div style={{ padding: '75px 32px 0 32px' }}>
                                        <h2 className='sign-in-header'>Sign In</h2>
                                    </div>
                                    <div>
                                        <label className='sign-in-label'>Email Id</label>
                                        <br />
                                        <input className="form-control pp-input" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        <br />
                                        <label className='sign-in-label'>Password</label>
                                        <br />
                                        <input className="form-control pp-input" name="password" value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
                                        <br />
                                        <div style={{ textAlign: 'center' }}>
                                            <input disabled={!formValid} className="btn" type="submit" onClick={() => { signInUser() }} />
                                        </div>
                                        {/* <hr style={{ color: Theme.PrimaryLogo }} />
                                        <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </Container>
        </div>

    </>
}










const SignInForm2 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [formValid, setFormValid] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const returnUrl = searchParams.get("returnUrl");

    const checkSignIn = (r) => {
        if (r.error) {
            return "Error"; //TODO
        }
        window.localStorage.setItem('token', r.data);
        validateToken()
            .then(user => {
                if (user) {
                    if (user.error) {
                        window.alert("Server error. Please, try again later.");
                    } else {
                        dispatch(setUser(user));
                        if (returnUrl) {
                            console.log("Going to ", returnUrl)
                            // navigate(returnUrl);
                            window.location.href = returnUrl;
                        } else {
                            window.location.href = '/';
                        }
                    }
                } else {
                    //TODO error
                    window.alert("Server error. Please, try again later.");
                }
            })
    }

    const signInUser = () => {
        dispatch(toggleLoader(true));
        fetch(Urls.ClientSignIn, {
            method: 'POST',
            body: JSON.stringify({
                email, password
            }),
            headers: {
                "Content-Type": "application/json",
            }
        }).then(r => r.json())
            .then(r => {
                dispatch(toggleLoader(false));
                console.log(r);
                checkSignIn(r)
            }).catch(err => {
                dispatch(toggleLoader(false));
                console.log(err);
                window.alert("Server error. Please, try again later.");
            })
    }

    const validateFields = () => {
        const validateEmail = (email) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        }
        let flag = true;
        if (!Boolean(email) || email.length === 0 || email.length > 100 || !validateEmail(email)) {
            flag = false;
        }
        if (!Boolean(password) || password.length === 0 || password.length > 100) {
            flag = false;
        }
        setFormValid(flag);

    }

    useEffect(() => { validateFields(); }, [
        password, email
    ])


    return <>
        <header className="relative  flex items-center justify-center h-[calc(100vh-72px)] overflow-hidden" style={{fontFamily: 'Verdana, sans-serif'}}>
                <div className='z-10 lg:py-0 md:py-3 py-5 sm:py-2 flex md:items-center md:justify-center absolute h-[calc(100vh-72px)] w-screen content-card-video'>
                <Container >
                <Row>
                    <Col xs={1}></Col>
                    <Col xs={10}>
                        <Row >
                            <Col lg={6} style={{ padding: 0 }}>
                                <div className='sign-in-logo-container'>
                                    <img src={LogoImg} />
                                </div>
                            </Col>
                            <Col lg={6} style={{ padding: 0 }}>
                                <div style={{ textAlign: 'center' }}>
                                    <div className='sign-in-user-img-container'>
                                        <img width="75" src={UserShieldImg} />
                                    </div>
                                </div>
                                <div className='sign-in-panel'>
                                    <div style={{ padding: '75px 32px 0 32px' }}>
                                        <h2 className='sign-in-header'>Sign In</h2>
                                    </div>
                                    <div>
                                        <label className='sign-in-label'>Email Id</label>
                                        <br />
                                        <input className="form-control pp-input" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        <br />
                                        <label className='sign-in-label'>Password</label>
                                        <br />
                                        <input className="form-control pp-input" name="password" value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
                                        <br />
                                        <div style={{ textAlign: 'center' }}>
                                            <input disabled={!formValid} className="btn" type="submit" onClick={() => { signInUser() }} />
                                        </div>
                                        {/* <hr style={{ color: Theme.PrimaryLogo }} />
                                        <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                                    </div>
                                    {/* <div className='flex items-center justify-end pt-2'>
                                    <div className='text-white text-sm underline'><Link to={'/resetPassword'}>Forgot Password</Link></div>
                                    </div> */}
                                    <div className='flex items-center justify-center pt-2'>
                                    <div className='text-white text-sm'>Don't have an Account ? <Link style={{ color: Theme.PrimaryLogo }} to={'/signUp'}><span className='text-[#00ff99] text-lg'>SignUp</span></Link></div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </Container>                  
                </div>
            <video
                autoPlay
                loop
                muted
                className="absolute -z-0 w-auto min-w-full min-h-full max-w-none bg-transparent object-cover hidden lg:block"
            >
                <source
                    src={BgVideo}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </header>
    </>
}





export default SignInForm2;