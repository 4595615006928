import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LogoImg from '../../images/logo.png';
import NzFlagImg from '../../images/nz_flag.png';
import UserShieldImg from '../../images/user-shield.png';
import { Theme, Urls } from '../../utils/AppConfig';
import { GoogleLogin } from '@react-oauth/google';
import { validateToken } from '../../utils/helpers';
import { useDispatch } from "react-redux";
import { setUser, toggleLoader } from '../../actions';
import { useEffect, useState } from 'react';
import BgVideo from '../../images/BgVideo.mp4';
import { Link } from 'react-router-dom';




const SignUpForm = () => {
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const [formValid, setFormValid] = useState(false);

    const responseMessage = (response) => {
        console.log(response);
        fetch(Urls.GoogleSignInAndSignUp, {
            method: "POST",
            body: JSON.stringify({
                credential: response.credential
            }),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(r => r.json())
            .then(r => {
                checkSignIn(r)
            }).catch(err => {
                console.log(err);
            })
    };

    const checkSignIn = (r) => {
        if (r.error) {
            return "Error"; //TODO
        }
        window.localStorage.setItem('token', r.data);
        validateToken()
            .then(user => {
                if (user) {
                    if (user.error) {
                        window.alert("Server error. Please, try again later.");
                    } else {
                        dispatch(setUser(user));
                    }
                } else {
                    //TODO error
                    window.alert("Server error. Please, try again later.");
                }
            })
    }

    const errorMessage = (error) => {
        console.log(error);
    };

    const validateFields = () => {
        const validateEmail = (email) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        }
        let flag = true;
        if (!Boolean(name) || name.length === 0 || name.length > 100) {
            flag = false;
        }
        if (!Boolean(email) || email.length === 0 || email.length > 100 || !validateEmail(email)) {
            flag = false;
        }
        if (!Boolean(password) || password.length === 0 || password.length > 100) {
            flag = false;
        }
        if (!Boolean(cPassword) || cPassword.length === 0 || cPassword.length > 100) {
            flag = false;
        }
        if (password !== cPassword) {
            flag = false;
        }
        setFormValid(flag);

    }

    useEffect(() => { validateFields(); }, [
        name, password, cPassword, email
    ])

    const signUpClient = () => {
        dispatch(toggleLoader(true));
        fetch(Urls.ClientSignUp, {
            method: 'POST',
            body: JSON.stringify({
                name, email, password, phone
            }),
            headers: {
                "Content-Type": "application/json",
            }
        }).then(r => r.json())
            .then(r => {
                console.log(r);
                dispatch(toggleLoader(false));
                if (r.error) {
                    return window.alert("Server error. Please, try again.");
                }
                checkSignIn(r)
            }).catch(err => {
                dispatch(toggleLoader(false));
                window.alert("Server error. Please, try again.");
                console.log(err);
            })
    }


    return <>
    <div className='sign-in-container'>
    <Container>
        <Row>
            <Col xs={1}></Col>
            <Col xs={10}>
                <Row style={{ marginTop: 0 }}>
                    <Col lg={5} style={{ padding: 0 }}>
                        <div className='sign-in-logo-container'>
                            <img src={LogoImg} />
                        </div>
                    </Col>
                    <Col lg={7} style={{ padding: 0 }}>
                        <div style={{ textAlign: 'center' }}>
                            <div className='sign-in-user-img-container'>
                                <img width="75" src={UserShieldImg} />
                            </div>
                        </div>
                        <div className='sign-in-panel'>
                            <div style={{ padding: '75px 32px 0 32px' }}>
                                <h2 className='sign-in-header'>Sign Up</h2>
                            </div>
                            <div>
                                <label className='sign-in-label'>Name</label>
                                <br />
                                <input class="form-control pp-input" name="name" value={name} onChange={(e) => setName(e.target.value)} />

                                <label className='sign-in-label'>Email Id</label>
                                <br />
                                <input class="form-control pp-input" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />

                                <label className='sign-in-label'>Phone</label>
                                <br />
                                <Row>
                                    <div style={{ width: 100, display: 'inline-block' }}>
                                        <input style={{
                                            background: `url(${NzFlagImg}) no-repeat`,
                                            backgroundSize: '30px 15px',
                                            backgroundPosition: 'calc(5px)',
                                            backgroundColor: 'white',
                                            paddingLeft: '40px'
                                        }} class="form-control pp-input" name="countryCode" value={'+64'} disabled />
                                    </div>
                                    <div style={{ display: 'inline-block', width: 'calc(100% - 100px)' }}>
                                        <input maxlength="10" onKeyDown={(event) => {
                                            if (isNaN(event.key) && event.key !== 'Backspace') {
                                                event.preventDefault();
                                            }
                                        }} class="form-control pp-input" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                    </div>
                                </Row>

                                <label className='sign-in-label'>Password</label>
                                <br />
                                <input class="form-control pp-input" name="password" value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
                                <label className='sign-in-label'>Confirm Password</label>
                                <br />
                                <input class="form-control pp-input" name="confirm-password" value={cPassword} onChange={(e) => setCPassword(e.target.value)} type="password" />
                                <br />
                                <div style={{ textAlign: 'center' }}>
                                    <input disabled={!formValid} class="btn" type="submit" onClick={() => {
                                        signUpClient()
                                    }} />
                                </div>
                                {/* <hr style={{ color: Theme.PrimaryLogo }} /> */}
                                {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col xs={1}></Col>
        </Row>
    </Container>
</div>
    </>





}








const SignUpForm2 = () => {

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const [formValid, setFormValid] = useState(false);
    const [clientTag, setClientTag] = useState('');

    const responseMessage = (response) => {
        console.log(response);
        fetch(Urls.GoogleSignInAndSignUp, {
            method: "POST",
            body: JSON.stringify({
                credential: response.credential
            }),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(r => r.json())
            .then(r => {
                checkSignIn(r)
            }).catch(err => {
                console.log(err);
            })
    };

    const checkSignIn = (r) => {
        if (r.error) {
            return "Error"; //TODO
        }
        window.localStorage.setItem('token', r.data);
        validateToken()
            .then(user => {
                if (user) {
                    if (user.error) {
                        window.alert("Server error. Please, try again later.");
                    } else {
                        dispatch(setUser(user));
                    }
                } else {
                    //TODO error
                    window.alert("Server error. Please, try again later.");
                }
            })
    }

    const errorMessage = (error) => {
        console.log(error);
    };

    const validateFields = () => {
        const validateEmail = (email) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        }
        let flag = true;
        if (!Boolean(name) || name.length === 0 || name.length > 100) {
            flag = false;
        }
        if (!Boolean(email) || email.length === 0 || email.length > 100 || !validateEmail(email)) {
            flag = false;
        }
        if (!Boolean(password) || password.length === 0 || password.length > 100) {
            flag = false;
        }
        if (!Boolean(cPassword) || cPassword.length === 0 || cPassword.length > 100) {
            flag = false;
        }
        if (!Boolean(clientTag) || clientTag.length === 0) {
            flag = false;
        }
        if (password !== cPassword) {
            flag = false;
        }
        setFormValid(flag);

    }

    useEffect(() => { validateFields(); }, [
        name, password, cPassword, email,clientTag
    ])

    const signUpClient = () => {
        dispatch(toggleLoader(true));
        fetch(Urls.ClientSignUp, {
            method: 'POST',
            body: JSON.stringify({
                name, email, password, phone,clientTag
            }),
            headers: {
                "Content-Type": "application/json",
            }
        }).then(r => r.json())
            .then(r => {
                console.log(r);
                dispatch(toggleLoader(false));
                if (r.error) {
                    return window.alert("Server error. Please, try again.");
                }
                checkSignIn(r)
            }).catch(err => {
                dispatch(toggleLoader(false));
                window.alert("Server error. Please, try again.");
                console.log(err);
            })
    }

    return <>
    <header className="relative flex items-center justify-center  h-screen  lg:h-[calc(100vh-72px)] overflow-hidden" style={{fontFamily: 'Verdana, sans-serif'}}>
   
    <video
        autoPlay
        loop
        muted
        className=" -z-0 top-0 left-0  object-cover absolute h-screen lg:h-[calc(100vh-72px)] w-screen hidden lg:block"
    >
        <source
            src={BgVideo}
            type="video/mp4"
        />
        Your browser does not support the video tag.
    </video>
   
    <div className='z-10  flex items-center content-card-video h-screen  lg:h-[calc(100vh-72px)]  w-screen '>
            <Container style={{padding:20}}>
                <Row>
                    <Col xs={1}></Col>
                    <Col xs={10}>
                        <Row className='flex items-center'>
                            <Col lg={5} style={{ padding: 0 }}>
                                <div className='flex items-center justify-center' >
                                    <img className=' lg:w-5/6 w-3/6' src={LogoImg} />
                                </div>
                            </Col>
                            <Col lg={7} style={{ paddingBottom:0 }}>
                                <div style={{ textAlign: 'center' }}>
                                    <div className='sign-in-user-img-container'>
                                        <img width="75" src={UserShieldImg} />
                                    </div>
                                </div>
                                <div className='sign-in-panel2 '>
                                    <div style={{ padding: '55px 32px 0 32px' }} >
                                        <h2 className='sign-in-header'>Sign Up</h2>
                                    </div>
                                    <div>
                                        <label className='sign-in-label'>Name</label>
                                        <br />
                                        <input class="form-control pp-input" name="name" value={name} onChange={(e) => setName(e.target.value)} />

                                        <label className='sign-in-label'>Email Id</label>
                                        <br />
                                        <input class="form-control pp-input" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />

                                        <label className='sign-in-label'>Phone</label>
                                        <br />
                                        <Row>
                                            <div style={{ width: 100, display: 'inline-block' }}>
                                                <input style={{
                                                    background: `url(${NzFlagImg}) no-repeat`,
                                                    backgroundSize: '30px 15px',
                                                    backgroundPosition: 'calc(5px)',
                                                    backgroundColor: 'white',
                                                    paddingLeft: '40px'
                                                }} class="form-control pp-input" name="countryCode" value={'+64'} disabled />
                                            </div>
                                            <div style={{ display: 'inline-block', width: 'calc(100% - 100px)' }}>
                                                <input maxlength="10" onKeyDown={(event) => {
                                                    if (isNaN(event.key) && event.key !== 'Backspace') {
                                                        event.preventDefault();
                                                    }
                                                }} class="form-control pp-input" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                            </div>
                                        </Row>

                                        <label className='sign-in-label'>Password</label>
                                        <br />
                                        <input class="form-control pp-input" name="password" value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
                                        <label className='sign-in-label'>Confirm Password</label>
                                        <br />
                                        <input class="form-control pp-input" name="confirm-password" value={cPassword} onChange={(e) => setCPassword(e.target.value)} type="password" />
                                        <label className='sign-in-label'>I am a</label>
                                        <br />
                                        <select class="form-control pp-input" name="clientTag" value={clientTag} onChange={(e) => setClientTag(e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="Real Estate Agent">Real Estate Agent</option>
                                        <option value="Real Estate Investor">Real Estate Investor</option>
                                        <option value="Property Developer">Property Developer</option>
                                        <option value="Builder">Builder</option>
                                        <option value="Consultant">Consultant</option>
                                        <option value="General Property Buyer">General Property Buyer</option>
                                        </select>
                                        <br />
                                        <div style={{ textAlign: 'center' }} className='pb-3'>
                                            <input disabled={!formValid} class="btn" type="submit" onClick={() => {
                                                signUpClient()
                                            }} />
                                        </div>
                                        {/* <hr style={{ color: Theme.PrimaryLogo }} /> */}
                                        {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                                    </div>
                                    <div className='flex items-center justify-center pt-2 pb-3'>
                                    <div className='text-white text-sm'>Already have an Account ? <Link style={{ color: Theme.PrimaryLogo }} to={'/signIn'}><span className='text-[#00ff99] text-lg'>SignIn</span></Link></div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </Container>
            </div>

    </header>
</>

}

export default SignUpForm2;