import React, { useRef, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { toggleLayerState } from '../actions/index';
import { Theme } from "../utils/AppConfig";
import Overlay from 'react-bootstrap/Overlay';
import { FaInfoCircle } from "react-icons/fa";


const mapStateToProps = (state) => {
    return {
        map: state.MapReducer
    }
}

const LayerListItem = (props) => {
    const dispatch = useDispatch();
    const handleLayerToggle = (e) => {
        dispatch(toggleLayerState(props.layer.id, e.target.checked));
    }
    const [show, setShow] = useState(false);
    const target = useRef(null);

    return (
        <div style={{ color: Theme.PrimaryLogo  }}>
            <div
                style={{fontFamily: 'Verdana, sans-serif'}}
                className="layer-list-container"
            >
                <div style={{ padding: 0 }} item>
                    <label style={{ height: 40, color: '#fff', cursor: 'pointer' }}>
                        <input type="checkbox"
                            style={{
                                fontSize: 14, marginLeft: 2,
                                padding: '10px'
                            }}
                            onChange={handleLayerToggle}
                            checked={props.layer.active} />
                        <span variant={'caption'} style={{ margin: 4,fontSize:13 }} >{props.layer.name} <FaInfoCircle ref={target} onClick={(e) => {
                            e.preventDefault();
                            setShow(!show)
                        }} style={{ display: 'inline' }} /></span>


                    </label>
                </div>
                <div style={{ padding: '0 8px' }} item>
                    {
                        props.layer.type !== 'WMS' || !props.layer.showLegend ? '' : (
                            <div style={{

                            }}>
                                <img src={props.layer.url + '?REQUEST=GetLegendGraphic&transparent=true&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=' + props.layer.id + '&LEGEND_OPTIONS=forceTitles:off;grouplayout:horizontal'} alt={'legend'} />
                            </div>
                        )
                    }
                </div>
            </div>


            {/* <Overlay target={target.current} show={show} placement="right">
                {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                }) => (
                    <div
                        {...props}
                        style={{
                            position: 'absolute',
                            backgroundColor: '#333',
                            padding: '2px 10px',
                            color: 'white',
                            borderRadius: 3,
                            ...props.style,
                        }}
                    >
                        Simple tooltip
                    </div>
                )}
            </Overlay> */}
        </div>
    )
}

export default connect(mapStateToProps)(LayerListItem);
