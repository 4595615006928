import HomeHeader from "../components/HomeHeader";
import ProfilePage from "./ProfilePage";


const HomePage = (props) => {
    return <>
        <ProfilePage />
    </>
}

export default HomePage;