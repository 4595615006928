import React, { useState,useEffect } from 'react';


const TeamSection = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    return <>

        </>
        }

        export default TeamSection;