import { useEffect, useState } from "react";
import { fetchPost } from "../../utils/helpers";
import { Urls } from "../../utils/AppConfig";
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';

const OrderList = () => {

    let [orders, setOrders] = useState([]);

    useEffect(() => {
        fetchPost(Urls.GetAllOrders, {})
            .then(data => {
                if (data.error) {
                    //todo
                    return;
                }
                console.log(data);
                setOrders(data['data'])
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    return <>
        <Container>
            <Table hover variant="dark">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Order Id</th>
                        <th>Details</th>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        orders.map((order, i) => {
                            return <tr>
                                <td>{i + 1}</td>
                                <td>{order['order_id']}</td>
                                <td>{`${order['address']}(${order['house_type']} - ${order['open_garage'] ? "Open Garage" : "Individual Garage"})`}</td>
                                <td>{new Date(order['created_on']).toLocaleString()}</td>
                                <td>{order['status']}</td>
                            </tr>;
                        })
                    }
                </tbody>
            </Table>
        </Container>
    </>
}

export default OrderList;
