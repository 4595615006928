import GoldLogo from '../../../images/emp_logo_gold.png';
import BronzeLogo from '../../../images/emp_logo_bronze.png';
import PlatinumLogo from '../../../images/emp_logo_platinum.png';
import { Theme } from '../../../utils/AppConfig';

const SubscriptionPlan = ({ subscriptionPlans, initSubscription }) => {

  console.log(subscriptionPlans);
  if (!subscriptionPlans) {
    return '';
  }
  subscriptionPlans.reverse();

  return (
    <div class="bg-transparent py-6 sm:py-8 lg:py-12">
      <div class="mx-auto max-w-screen-xl md:px-8">
        <h6 className="header-4-small">You don't have an active subscription</h6>
        <br />
        <h4 class="mb-4 text-center text-2xl text-[#00FF99] md:mb-8 lg:text-3xl xl:mb-12">NZ$ 19/report <span style={{ color: '#fff', fontSize: '24px' }}>or</span> Subscribe </h4>
        <hr style={{ color: Theme.PrimaryLogo }} />

        <div class="mb-6 grid gap-6 sm:grid-cols-2 md:mb-8 lg:grid-cols-3 lg:gap-8">

          {
            subscriptionPlans.map(plan => {
              return (
                <div class="flex flex-col overflow-hidden rounded-lg border-1 border-gray-500 sm:mt-8">
                  <div class={plan['planId'] === 'INDEPENDENT_V1' ? "h-2 bg-gray-500" : "h-2 bg-yellow-500"}></div>
                  <div class="flex flex-1 flex-col p-6 pt-8">
                    <div class="mb-12">
                      <div class="mb-2 text-center text-2xl font-bold text-[#00FF99]">
                        <div style={{ display: 'inline-block' }}>
                          <img width={100} src={plan['planId'] === 'INDEPENDENT_V1' ? BronzeLogo : GoldLogo} />
                        </div>
                      </div>
                      <div class="mb-2 text-center text-2xl font-bold text-[#00FF99]">{plan['planName']}</div>
                      {/* <p class="mb-8 px-8 text-center text-gray-500">One week trial free – take the card details and registration first</p> */}
                      <div class="space-y-4">

                        {
                          plan['planDescription'].split("\\n").map(planFeature => {
                            return (
                              <div class="flex items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0 text-[#00ff99]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                                <span style={{ textAlign: 'left' }} class="text-[#DDDDDD]">{planFeature}</span>
                              </div>
                            )
                          })
                        }

                      </div>
                    </div>

                    <div class="mt-auto">
                      <a onClick={() => { initSubscription(plan['planId']) }} class={`block rounded-lg px-8 py-3 text-center text-sm font-semibold text-black outline-none ring-indigo-300 transition duration-100 focus-visible:ring active:text-gray-700 md:text-base`} style={{ cursor: 'pointer', background: plan['planId'] === 'INDEPENDENT_V1' ? '#ce802e' : '#ffca06' }}>
                        NZ${plan['amount']} / month
                      </a>
                    </div>
                  </div>
                </div>
              )
            })
          }

          <div class="flex flex-col overflow-hidden rounded-lg border-1 border-gray-800 lg:mt-8">
            <div class="h-2 bg-gray-800"></div>

            <div class="flex flex-1 flex-col p-6 pt-8">
              <div class="mb-12">
                <div class="mb-2 text-center text-2xl font-bold text-[#00FF99]">
                  <div style={{ display: 'inline-block' }}>
                    <img width={100} src={PlatinumLogo} />
                  </div>
                </div>
                <div class="mb-2 text-center text-2xl font-bold text-[#00FF99]">Limitless</div>
                {/* <p class="mx-auto mb-8 px-8 text-center text-gray-500">Unlimited Pro Reports for any property</p> */}
                <div class="space-y-4">
                  <div class="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0 text-[#00ff99]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span style={{ textAlign: 'left' }} class="text-[#DDDDDD]">Unlimited Pro Reports for any property</span>
                  </div>
                  <div class="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0 text-[#00ff99]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span style={{ textAlign: 'left' }} class="text-[#DDDDDD]">Ideal for real estate agencies and financial institutions</span>
                  </div>
                  <div class="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0 text-[#00ff99]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span style={{ textAlign: 'left' }} class="text-[#DDDDDD]">Ownership details and history</span>
                  </div>
                  <div class="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0 text-[#00ff99]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span style={{ textAlign: 'left' }} class="text-[#DDDDDD]">Customer support from technical experts</span>
                  </div>
                  <div class="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0 text-[#00ff99]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span style={{ textAlign: 'left' }} class="text-[#DDDDDD]">Costing sheets</span>
                  </div>
                  <div class="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0 text-[#00ff99]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span style={{ textAlign: 'left' }} class="text-[#DDDDDD]">Ability to extract CAD files and business case documentation</span>
                  </div>
                  <div class="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0 text-[#00ff99]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span style={{ textAlign: 'left' }} class="text-[#DDDDDD]">architectural, and engineering representations</span>
                  </div>
                  <div class="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0 text-[#00ff99]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span style={{ textAlign: 'left' }} class="text-[#DDDDDD]">More exciting features will come soon</span>
                  </div>

                </div>
              </div>

              <div class="mt-auto">
                <a href="#" class="block rounded-lg px-8 py-3 text-center text-sm font-semibold text-gray outline-none ring-indigo-300 transition duration-100 focus-visible:ring active:bg-gray-600 md:text-base" style={{ background: 'rgb(230 229 227)', cursor: 'pointer' }}>Contact Us</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>



  )
};

export default SubscriptionPlan;