
import * as React from "react";
import { connect, useDispatch } from 'react-redux';
import OverviewDetails from './OverviewDetails';
import SalesHistory from "./SalesHistory";
import TitleValuation from "./TitleValuation";
import { removeSearchLayer, setParcelOverview, setZoneData, toggleLoader, toggleManualOrderDialog, updateCurIds, updateRaw } from "../../actions";
import { Constants, Urls } from "../../utils/AppConfig";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RightComponent from './RightComponent'
import { Cross2Icon } from "@radix-ui/react-icons";
import { fetchPost } from "../../utils/helpers";

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer
    }
}

const ParcelDetailPanel = (props) => {

    const dispatch = useDispatch();

    const [salesHistory, setSalesHistory] = React.useState([]);
    const [rateAssessment, setRateAssessment] = React.useState(null);
    const [parcelOverview, setLocalParcelOverview] = React.useState({});
    // const [demogData, setDemogData] = React.useState(null);
    // const [loadStatus, setLoadStatus] = React.useState(0);

    const [isLoading, setLoading] = React.useState(false);

    React.useEffect(() => {
        // console.log(props.data.currentParcelId, props.data.currentAddressId, props.data.currentTitleNo, props.data.addressGeometry)
        if (!isLoading && Boolean(props.data.currentParcelId) && Boolean(props.data.currentAddressId) && Boolean(props.data.currentTitleNo) && Boolean(props.data.addressGeometry)) {
            setLoading(true);
        }

    }, [props.data.currentParcelId, props.data.currentAddressId, props.data.currentTitleNo, props.data.addressGeometry]);

    React.useEffect(() => {
        if (isLoading) {
            loadAllData(props.data.currentParcelId, props.data.currentAddressId, props.data.currentTitleNo, props.data.addressGeometry.coordinates)
            // loadParcelOverview(props.data.currentParcelId, props.data.currentAddressId, props.data.currentTitleNo);
            // fetchSalesHistory(props.data.currentTitleNo);
            // loadTitleValuation(props.data.currentTitleNo);
            // loadDemographyData(props.data.addressGeometry.coordinates);
        }
    }, [isLoading])


    const loadAllData = async (parcelId, addressId, titleNo, coordinates) => {

        const fetchPromise = (url) => {
            let token = window.localStorage.getItem('token');
            let headers = {

            };
            if (token) {
                headers["Authorization"] = `Bearer ${token}`;
            }

            return new Promise((resolve, reject) => {
                fetch(url, { headers })
                    .then(r => r.json())
                    .then(r => {
                        resolve(r);
                    })
                    .catch(e => {
                        reject(e);
                    })
            })
        }
        
        const fetchPromiseNoToken = (url) => {
            return new Promise((resolve, reject) => {
                fetch(url)
                    .then(r => r.json())
                    .then(r => {
                        resolve(r);
                    })
                    .catch(e => {
                        reject(e);
                    })
            })
        }

        titleNo = titleNo.split(',').map(e => e.trim());
        titleNo = titleNo[0];
        let shurl = `${Urls.SalesHistory}?titleNo=${titleNo}`;
        let tvUrl = `${Constants.NZ_GEOMAPS_ESRI_SERVER}/3/query?f=json&where=CT='${titleNo}'&returnGeometry=false&spatialRel=&geometry=&geometryType=esriGeometryPolygon&inSR=4326&outFields=*&outSR=4326`;
        let poUrl = `${Urls.ParcelOverview}?parcelId=${parcelId}&addressId=${addressId}&titleNo=${titleNo}`;
        
        let promises = [
            fetchPromise(shurl),
            fetchPromiseNoToken(tvUrl),
            fetchPromise(poUrl),
            fetchPost(Constants.SERVER_BASE + "/data/getZoneRegulations", {
                parcelId: props.data.currentParcelId
            })
        ];

        setSalesHistory([]);
        setLocalParcelOverview({});
        dispatch(setParcelOverview(null));
        setRateAssessment(null);
        setZoneData(null);

        dispatch(toggleLoader(true));
        let results = await Promise.all(promises);
        console.log(results);
        setSalesHistory(results[0].data);
        if (results[1] && results[1].features && results[1].features.length > 0) {
            setRateAssessment(results[1].features[0]);
        }

        try {
            dispatch(updateRaw({
                ...props.data.raw,
                gisDetails: results[2].data.raw.gisDetails,
                pc78: results[2].data.raw.pc78,
                title: results[2].data.raw.title,
                salesHistory: results[0].data
            }))
            delete results[2]['data']['raw']
            setLocalParcelOverview(results[2]);
            dispatch(setParcelOverview(results[2].data));
            dispatch(setZoneData(results[3].data))
        } catch (e) {
            console.log(e)
        }
        setLoading(false);
        dispatch(toggleLoader(false));
    }


    if(!props.data.currentParcelId && props.panelType==="mobile"){
        props.minimisePanel();
        return <></>;
    }

    const openAutoReportForm = () => {
        dispatch(toggleManualOrderDialog(true, { address: props.data.fullAddress, addressId: props.data.currentAddressId }));

    }

    return <div id="parcel_details_panel" className={props.panelType==="mobile"?"overflow-y-auto h-full":""} style={{ padding: 0, width: '100%', background: 'transparent',fontFamily: 'Verdana, sans-serif', height: props.panelType==="mobile"?'100%':'calc(100vh - 66px)' }} >
        
        {
            props.data.currentParcelId?<>
                <div>
            <div style={{ position: 'relative', width: '100%', padding: 8 }}>
                <h6 className="header-3-small">
                    {props.data.fullAddress ? props.data.fullAddress : ''}
                    <div style={{ float: 'right' }}>
                    {
                        props.panelType==="mobile"?<button  onClick={props.minimisePanel}><Cross2Icon color="#00ff99 " className="w-6 h-6 " /></button>:''
                    }
                    </div>
                </h6>
            </div>

            <div style={props.panelType==="mobile"?{}:{ overflowY: 'auto', height: 'calc(100vh - 200px)' }}>
                <OverviewDetails data={parcelOverview} />
                <TitleValuation data={rateAssessment} />
                <SalesHistory data={salesHistory} />
                <RightComponent zoneData={props.data.zoneData} />
            </div>
            {
                Boolean(props.data.user) ? (
                    <div style={{ position: 'relative', width: '100%', padding: 8, borderRadius: 3, background: 'transparent', bottom: 40 }}>
                        <Row >
                            <Col xs={12} md={12} item>
                                <button className="btn btn-small" style={{ width: '100%', color: '#00FF99', backgroundColor: '#333', borderColor: '#00FF99' }} onClick={openAutoReportForm}>Auto Report</button>
                            </Col>
                        </Row>
                        <Row >
                            <Col xs={12} md={12} item>

                            </Col>
                        </Row>
                    </div>
                ) : ''
            }
        </div>
            </>:<>
            <div style={{ position: 'relative', width: '100%', padding: 32 }}>
                <h6 className="header-3-small">Please, select a parcel to get details and insights.</h6>
                </div>
            </>
        }
    </div>
}



export default connect(mapStateToProps)(ParcelDetailPanel);