const initialState = {
    showAddLayerDialog: false,
    showVectorToolDialog: false,
    currentVectorTool: 'buffer',
    alertDialog: {
        flag: false
    },
    manualOrderDialog: {
        flag: false,
        parcel: null,
        preprocess: null
    },
    samplePlansDialog: {
        flag: false,
        imgs: []
    },
    signUpDialog: {
        flag: false
    },
    signInDialog: {
        flag: false
    },
    currentRasterTool: 'raster_calculator'
};

const DialogReducer = (state = initialState, action) => {
    switch (action.type) {
        case "TOGGLE_ADD_LAYER":
            return {
                ...state,
                showAddLayerDialog: action.payload.flag
            };
        case 'TOGGLE_VECTOR_TOOL':
            return {
                ...state,
                showVectorToolDialog: action.payload.flag,
                currentVectorTool: action.payload.tool
            };
        case 'TOGGLE_ALERT_DIALOG':
            return {
                ...state,
                alertDialog: {
                    ...action.payload
                },
            };
        case 'TOGGLE_MANUAL_ORDER_DIALOG':
            return {
                ...state,
                manualOrderDialog: { ...action.payload }
            }
        case 'TOGGLE_SAMPLE_PLANS_DIALOG':
            return {
                ...state,
                samplePlansDialog: { ...action.payload }
            }
        case 'TOGGLE_SIGN_UP_DIALOG':
            return {
                ...state,
                signUpDialog: { ...action.payload }
            }
        case 'TOGGLE_SIGN_IN_DIALOG':
            return {
                ...state,
                signInDialog: { ...action.payload }
            }
        default:
            return {
                ...state
            };
    }
};

export default DialogReducer;