import HomeHeader from "../components/HomeHeader";
import SignUpForm from "../components/SignUp/SignUpForm";
import '../styles/signin.css';
import Cursor from '../components/Cursor/Cursor';


const SignUpPage = () => {
    return <>
    <Cursor/>
        <HomeHeader />
        <SignUpForm />
    </>
}

export default SignUpPage;