import HomeHeader from "../components/HomeHeader";
import HomeTab from "../components/Profile/HomeTab";


const ProfilePage = (props) => {
    return <>
        <HomeHeader />
        <div className="md:mx-20 md:my-10 my-5 mx-10">
            <div>
            <HomeTab />
            </div>
        </div>
    </>
}

export default ProfilePage;