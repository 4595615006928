import { useDispatch } from "react-redux";
import AppHeader from "../components/AppHeader";
import HeaderSection from "../components/Landing/HeaderSection";
import FooterSection from "../components/Landing/FooterSection";
import AboutSection from "../components/Landing/AboutSection";
import Cursor from '../components/Cursor/Cursor';
import ScrollToAnchor from '../utils/ScrollToAnchor';

const AboutUsPage = () => {
    return <>
        <Cursor/>
        <ScrollToAnchor />

        <HeaderSection/>
            <AboutSection/>
        <FooterSection />
        </>
        }

export default AboutUsPage;