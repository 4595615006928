import { useDispatch } from "react-redux";
import AppHeader from "../components/AppHeader";
import HeaderSection from "../components/Landing/HeaderSection";
import FooterSection from "../components/Landing/FooterSection";
import TermsConditions from "../components/Landing/TermsConditions";
import Cursor from '../components/Cursor/Cursor';
import ScrollToAnchor from '../utils/ScrollToAnchor';

const TermsConditionsPage = () => {
    const dispatch = useDispatch();



    return <>
        <Cursor/>
        <ScrollToAnchor />

        <HeaderSection/>
        <TermsConditions/>

<FooterSection />
    </>
}

export default TermsConditionsPage;