import * as React from 'react';
import EmpLogoPng from '../images/emp_logo.png'
import { Theme } from '../utils/AppConfig';
import { FaHome } from "react-icons/fa";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { connect, useDispatch } from 'react-redux';
import { setUser } from '../actions';
import AddressSearch from './AddressSearch';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer
    }
}

const HomeHeader = (props) => {
    const dispatch = useDispatch();
    return <>
    <Navbar data-bs-theme="dark" expand="lg" style={{ backgroundColor: '#000000EE', padding: 0,fontFamily: 'Verdana, sans-serif' }} className='min-h-[72px] z-10 '>
          <Container fluid>
            <Navbar.Brand  style={{ color: Theme.PrimaryLogo, margin: 'initial' }}>
                    <Link to={"/"}>
                        <img
                            alt=""
                            src={EmpLogoPng}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />
                        {' '}
                        PlotPotential
                    </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${'lg'}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${'lg'}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${'lg'}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'lg'}`}>
                <Link to={"/"}>
                        <img
                            alt=""
                            src={EmpLogoPng}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />
                        {' '}
                        PlotPotential
                    </Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                    <Nav className='me-auto'>
                        {
                            props.data && props.data.user && props.data.user.email.indexOf("plotpotential.co.nz")>-1?<div style={{ margin: 16 }}><AddressSearch isInput={false} height={40} /></div>:''
                        }
                        
                        
                    </Nav>
                <Nav className="justify-content-end pe-2" style={{ fontFamily: 'Verdana, sans-serif' }}>
                        {/* {
                            Boolean(props.data.user) ? (<Nav.Link>
                                <Link to={'/home'}>Home</Link>
                            </Nav.Link>) : ''
                        } */}
                        {
                            Boolean(props.data.user) ? (<Nav.Link>
                                <Link to={'/my-subscription'}>Subscription</Link>
                            </Nav.Link>) : ''
                        }
                        {
                            Boolean(props.data.user) ? (<Nav.Link>
                                <Link to={'/reports'}>Reports</Link>
                            </Nav.Link>) : ''
                        }
                        {
                            Boolean(props.data.user) ? (<Nav.Link>
                                <Link to={'/plotworks'}>Plotworks</Link>
                            </Nav.Link>) : ''
                        }
                        {
                            Boolean(props.data.user) ? (<Nav.Link>
                                <Link to={'/map'}>Map</Link>
                            </Nav.Link>) : ''
                        }
                {
                        !Boolean(props.data.user) ? (
                            <Nav>
                                <Nav.Link><Link to={'/signIn'}>Sign In</Link></Nav.Link>
                                <Nav.Link><Link to={'/signUp'}>Sign Up</Link></Nav.Link>
                            </Nav>
                        ) : (
                            <Nav>
                                {/* <Nav.Link><Link style={{ color: Theme.PrimaryLogo }} to={'/newOrder'}>Generate Report</Link></Nav.Link> */}
                                <Nav.Link href="#"><Button onClick={() => {
                                    dispatch(setUser(null));
                                    window.localStorage.removeItem('token');
                                }} variant="outline-success" >Log Out</Button></Nav.Link>
                            </Nav>
                        )
                    }
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
    </>
}

export default connect(mapStateToProps)(HomeHeader);