import React, { useState,useEffect } from 'react';

import { FaFilePdf } from 'react-icons/fa6';
import { Divider } from '@nextui-org/react';
import {Button, Calendar, DatePicker,Popover,PopoverContent,PopoverTrigger,Tooltip} from "@nextui-org/react";
import {today, getLocalTimeZone, isWeekend, DateValue} from "@internationalized/date";
import {useLocale} from "@react-aria/i18n";
import {parseDate} from "@internationalized/date";
import {CalenderIcon, CalenderIcon2, LocationIcon} from "../../icons/GeneralIcons"
import {Alert} from "@nextui-org/react";

const BookDemoSection = () => {

  const [isVisible, setIsVisible] = React.useState(false);
  const [alertMsg,setAlertMsg] = useState('');
  const [alertType,setAlertType] = useState('');
  const [disableBooking,setDisableBooking] = useState(false);

  

  let now = today(getLocalTimeZone());
  let {locale} = useLocale();

  let [value, setValue] = useState(now);
  let [value2, setValue2] = useState(parseDate("2024-06-16"));

  
  let isDateUnavailable = (date) =>
    date >= value && date <= value ||
  date >= value2 && date <= value2;

function bookDemo(){
  alert(value);
}

function checkAvailability(){
  // setAlertType('danger');
  // setAlertMsg('No Slots Available ! Book Another Day');
  // setAlertType('success');
  // setAlertMsg('Slots Available ! Book a Demo');
  setAlertType('warning');
  setAlertMsg('Slots Almost Booked ! Book Now');
  setIsVisible(true);
  alert(value);
  setDisableBooking(true);
}


  return (
    <>
        <div className='pb-10   pt-[100px] bg-black text-justify scroll-mt-[72px]'  style={{ fontFamily: 'Verdana, sans-serif' }}>

        <p className="head_font2 text-4xl font-bold text-[#00ff99] pt-5 text-center">
                        Book A Demo
                    </p>


      <div className='flex justify-center items-center '>
<hr className='w-4/5 bg-[#00ff99] h-1'/>
</div>

      <div className='content-text-about text-md md:text-xl text-center justify-center items-center flex' variant="caption2">
      <div>
      To know more about us and our products, Feel free to Book a demo.
      </div>
      </div> 

      <div className="flex items-center justify-center">
        <div className='w-11/12 md:w-1/2'>
        {isVisible ? (
        <Alert
          color={alertType}
          title={alertMsg}
          isVisible={isVisible}
          variant="faded"
          onClose={() => setIsVisible(false)}
        />
      ):''}
    </div>
    </div>
  


        
        <div>
        {/* <Calendar 
          isDateUnavailable={isDateUnavailable}



          /> */}

<div className=' lg:flex-row flex flex-col justify-center items-center gap-6 py-6 '>
  <div className=''>
<Calendar
 aria-label="Date (Controlled)" 
 showMonthAndYearPickers 

classNames={{base:'md:w-[600px] w-[400px] ',content:'md:w-[600px] w-[400px] h-[350px]',gridHeader:'md:w-[600px] w-[400px] h-[40px] ',gridHeaderRow:' h-[20px] w-[400px] md:w-[600px] gap-x-8  md:gap-x-8 md:pb-0',gridHeaderCell:' h-[40px] ',gridBody:'',gridBodyRow:'h-[40px]',cell:['px-3']}}
 value={value} onChange={setValue}
 />
  </div>
  <div className="flex lg:flex-col  gap-y-6 gap-x-6 ">
  <div className="flex gap-2.5 cursor-pointer bg-blue-600 rounded-lg hover:bg-indigo-700" onClick={checkAvailability} >
          <a  className="inline-block flex-1 pl-4 py-2 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100   sm:flex-none md:text-base">Check Availability</a>
          <button  className=" rounded-lg  pr-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none  transition duration-100    md:text-base">
          <CalenderIcon height="40" width="40"/>
          </button>
        </div>

  {/* <button onClick={checkAvailability} className=" items-center justify-center rounded-lg  px-8 py-3 text-center text-sm font-semibold  outline-none transition duration-100 hover:bg-[#333]  text-white  bg-blue-500 sm:flex-none md:text-base">Check Availability<div className="px-4"><CalenderIcon height="100" width="100"/></div>  </button> */}

  <button onClick={bookDemo} disabled={disableBooking} className=" rounded-lg bg-[#00ff99] px-8 py-3 text-center text-sm font-semibold text-black outline-none transition duration-100 hover:bg-[#00ff99]  active:bg-white sm:flex-none md:text-base">Book Now</button>
        </div>        
</div>
        </div>
    </div>
    </>
    )
    }

export default BookDemoSection;