import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import { useEffect,useState } from 'react';
import { Input,Textarea, Select, SelectItem,Checkbox,Switch} from "@nextui-org/react";
import { TickIcon,CrossIcon } from "../icons/GeneralIcons";
import { useNavigate } from "react-router-dom";
import { MdSdStorage } from "react-icons/md";

export const GeneralPopUp = ({props}) => {  
    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    const [status, setStatus] = useState(0);
    const [msg, setMsg] = useState("");
    const [url, setUrl] = useState("");

    const navigate = useNavigate();

    useEffect(()=>{
          setStatus(props.status);
          setMsg(props.msg);
          setUrl(props.url);
          onOpen();
  }, [props]);

const closeModal =() =>{
    navigate(url);
}

return(
    <>

      <Modal isOpen={isOpen} placement="center" onClose={closeModal} onOpenChange={onOpenChange} isDismissable={false} isKeyboardDismissDisabled={true}>
        <ModalContent>
          {(onClose) => (
            <>
            <ModalHeader className="flex flex-col gap-1 text-center"></ModalHeader>
            <ModalBody className="flex flex-row justify-center p-5">
              {status ?
                <div className="flex flex-col items-center gap-4">
                <TickIcon width={70} height={70}/>
                <Button className="bg-[#00ff99] text-black">
                {msg}
                </Button>
                </div>
                :
                <div className="flex flex-col items-center gap-4">
              <CrossIcon width={70} height={70}/>
                <Button color="danger">
                {msg}
                </Button>
                </div>
              }
            </ModalBody>
              <ModalFooter>
                <Button color="" variant="light" className="bg-[#00ff99] hover:text-black" onPress={onClose=()=>{navigate(url)}}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
);
}