import React, { useState,useEffect } from 'react';

const TermsConditions = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='pb-10 pt-[100px] bg-zinc-900 text-justify scroll-mt-[72px]'  style={{ fontFamily: 'Verdana, sans-serif' }}>



<div className='text-[#00ff99]  text-center text-4xl'>
Terms & Conditions
</div>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
1.	Parties
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
<span className='text-2xl font-extrabold text-white'>Between</span>	Plot Potential Limited trading as Plot Potential (NZBN: 9429051808075) of 35 Aldersgate Road, Hillsborough, Auckland 1042, New Zealand (<span className='text-white'>“us”</span> or <span className='text-white'>“we”</span> or <span className='text-white'>“our”</span>).</div>
<div>
 <span className='text-2xl font-extrabold text-white'>And</span>	You  (<span className='text-white'>“you”</span> or <span className='text-white'>“your”</span>).
</div>
<div>
Together the <span className='text-white'>“Parties”</span>, individually, a <span className='text-white'>“Party”</span>.
</div>
</div>
</section>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
2.	Introduction
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
2.1.	These terms and conditions (<span className='text-white'>‘Terms’</span>) cover your use of our Plot Potential software (defined in the definitions section below as <span className='text-white'>“Plot Potential”</span>). These Terms cover what Plot Potential does and does not do, and what information it uses.</div>
<div>
2.2.	By accessing Plot Potential you agree to comply with these Terms. If you don’t agree to comply with these Terms, cease use of Plot Potential immediately. </div>
<div>
2.3.	These Terms set out what you need to know about using Plot Potential, and what our obligations to you are.
</div>
<div>
2.4.	Plot Potential is free to use, but with limited features. There is a paid version of Plot Potential with additional features (defined in the definitions section below as the “Professional Version”). Your use of either of these versions of Plot Potential is governed by these Terms.
</div>
<div>
2.5.	These Terms form a legally binding agreement between you and <span className='text-white'>Project-X Management Limited (a New Zealand company trading as Plot Potential)</span>. You should read these Terms carefully before you start using Plot Potential. 
</div>
<div>
2.6.	Plot Potential is always evolving. This means that the minimum system requirements for Plot Potential to operate at its best may be upgraded from time to time. This also means that from time to time, we may need to amend these Terms by changing or removing existing terms or by adding new ones. 
</div>
<div>
2.7.	You can view the latest Terms and minimum system requirements at www.plotpotential.co.nz (defined in the definitions section below as our Website). You should review the latest Terms regularly. By using or continuing to use Plot Potential you agree to the latest Terms. 
</div>
</div>
</section>

<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
3.	Definitions </div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
3.1.	<span className='text-xl font-extrabold text-white'>Authorised User</span> means a User that you have authorised under these Terms to access Plot Potential.
</div>
<div>
3.2.	<span className='text-xl font-extrabold text-white'>Business Days</span> means any day except Saturday or Sunday or a Public Holiday in Auckland, New Zealand.
</div>
<div>
3.3.	<span className='text-xl font-extrabold text-white'>Business Hours</span> means between 9:00am and 5:00pm (NZST or NZDT, as is in force) on a Business Day.
</div>
<div>
3.4.	<span className='text-xl font-extrabold text-white'>Credential</span> means any unique identifier, password, token, credential, or any combination of those, or other means we may utilise from time to time for authorising access to all, or any portion of, the Services and Plot Potential.
</div>
<div>
3.5.	<span className='text-xl font-extrabold text-white'>Fees</span> means the subscription fee(s) set out at www.plotpotential.co.nz, or as otherwise agreed in writing between you and us. We may update our Fees in accordance with clause 5 below.
</div>
<div>
3.6.	<span className='text-xl font-extrabold text-white'>Intellectual Property</span> means copyright, patents, designs, trade marks, trade names, goodwill rights, trade secrets, confidential information, or any other like rights, the right to apply for any of those rights, and any other intellectual property right or form of intellectual property.
</div>
<div>
3.7.	<span className='text-xl font-extrabold text-white'>Password</span> means the password we provide you to access Plot Potential when you are invited to use it and any subsequent password you change your password to. 
</div>
<div>
3.8.	<span className='text-xl font-extrabold text-white'>Personal Information</span> means the personal information we collect from you as set out in our Privacy Policy.
</div>
<div>
3.9.	<span className='text-xl font-extrabold text-white'>Privacy Policy</span> means our Privacy Policy accessible on our Website. 
</div>
<div>
3.10.	<span className='text-xl font-extrabold text-white'>Professional Version</span> means the paid version of Plot Potential. The Professional Version has all the Services, plus the following additional features:   
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>
3.10.1.	The professional report, which includes, architectural representations, engineering possibilities, cost prospects, and return on the investment.
</div>
<div>
3.10.2.	Any other services we tell you that Plot Potential provides as part of the Professional Version or the base (unpaid) version, from time to time, as the case may be (including new features we may add). We will tell you about these either in writing, or on our Website.
</div>
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
3.11.	<span className='text-xl font-extrabold text-white'>Plot Potential</span> means the Plot Potential software and any content, images, text and other information appearing on any page or screen of the software and any source code and object code in the software, including any mobile versions and smartphone applications and any other related computer software provided by us. 
</div>
<div>
3.12.	<span className='text-xl font-extrabold text-white'>Services</span> means the information services provided through Plot Potential (including the Professional Version), remote support, and other services provided by us to you by way of a cloud-based software portal. Services includes the following:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>
3.12.1.	Provision of parcel details, sales history, title valuation, ownership details, and Stats NZ data regarding the demographics within the region.
</div>
<div>
3.12.2.	Geospatial information, including information about utilities, flooding, geotechnical, and zone regulations.
</div>
<div>
3.12.3.	Other relevant information, such as flood information, serviceability, slope rating, change of use, potential return on investment (ROI), and rental yield.
</div>
<div>
3.12.4.	Design & build management CRM based services, if opted by the User.
</div>
<div>
3.12.5.	Any other services we tell you that Plot Potential provides, from time to time, as the case may be (including new features we may add). We will tell you about these either in writing, or on our Website.
</div>
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
  <div>
  3.13.	<span className='text-xl font-extrabold text-white'>Terms</span> means these terms and conditions of use which are a legally binding contract between you and us and includes our Privacy Policy.
  </div>
  <div>
  3.14.	<span className='text-xl font-extrabold text-white'>Third Party Code</span> is defined in clause 15.1.
    </div>
    <div>
    3.15.	<span className='text-xl font-extrabold text-white'>Third Party Information</span> is defined in clause 8.1.
    </div>
    <div>
    3.16.	<span className='text-xl font-extrabold text-white'>Third Party Licensor(s)</span> is defined in clause 15.1.
    </div>
    <div>
    3.17.	<span className='text-xl font-extrabold text-white'>Updates</span> means (as might be the case) any patch, update or new version of Plot Potential and any update to or new version of the Services delivered to you.
    </div>
    <div>
    3.18.	<span className='text-xl font-extrabold text-white'>User</span> means a unique individual authorised by us to use Plot Potential with a unique Username and Password. 
    </div>
    <div>
    3.19.	<span className='text-xl font-extrabold text-white'>Username</span> means a unique method of identifying a User, usually the email address used to log in to Plot Potential.
    </div>
    <div>
    3.20.	<span className='text-xl font-extrabold text-white'>Website</span> means our website hosted at www.plotpotential.co.nz.
    </div>
</div>
</section>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
4.	Licence & use of Plot Potential and the Services</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>4.1.	By logging into, accessing, and/or using Plot Potential we grant to you, and you agree to be granted, a non-exclusive, non-transferable, non-sublicensable, limited right to access and use the Services and Plot Potential on these Terms.
</div>
<div>
4.2.	We give you the licence to use Plot Potential subject to these Terms; you gain no other rights in Plot Potential.
</div>
<div>
4.3.	By using Plot Potential, or the Services, you agree to also abide by our Privacy Policy. 
</div>
</div>
</section>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
5.	Payment terms – Professional Version</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
5.1.	If you subscribe to the Professional Version, we will give you access to the Professional Version in exchange for your prompt payment of the Fees. 
</div>
<div>
5.2.	Payment times, methods, and the relevant Fees are displayed on our Website on the ‘payment’ page.  
</div>
<div>
5.3.	We reserve the right to change the Fees at any time at our sole discretion. If we do change the Fees, you will be charged the new rate for Fees payable from the date on which the change is announced or a date we specify. 
</div>
<div>
5.4.	By continuing to use Plot Potential and/or the Services after that date, you agree to be charged the new Fees.
</div>
<div>
5.5.	If you do not agree to any change in Fees we make, you may cancel your subscription to the Professional Version.
</div>
<div>
5.6.	If you don’t pay your Fees on time, we have the right to cancel or suspend your access to, or use of, the Services and Plot Potential. We give more detail about this and its consequences at clause 17 and its subclauses and clause 18.2.
</div>
</div>
</section>



<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
6.	Provided ‘as-is’</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
6.1.	We provide Plot Potential ‘as is’. We do not warrant that Plot Potential will meet your requirements or that it will be suitable for any particular purpose. 
</div>
<div>
6.2.	We are not responsible for any loss, including loss of data, failure or fault with Plot Potential, including the inaccuracy of any information made available to you in Plot Potential. 
</div>
<div>
6.3.	Your access to Plot Potential may depend on third parties (for example your internet service provide, or our hosting service). We cannot therefore guarantee that Plot Potential will work 100% of the time.
</div>
</div>
</section>



<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
7.	Content of Plot Potential 
 </div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
7.1.	We may make errors, omissions, or inaccuracies in the content provided by, on, or hosted by Plot Potential that we have direct control over, and you accept this may happen. However, we are not responsible or liable, directly or indirectly, for any errors, omissions, or inaccuracies in any content on Plot Potential.
</div>
<div>
7.2.	We will do our best to fix any errors, omissions or inaccuracies where reasonably possible after we discover them. However, we do not accept any direct or indirect liability (responsibility) for any loss or damage which may result from any information, advice, statement, or representation, whether negligent or otherwise, contained on, made on, presented on, hosted on, Plot Potential. 
</div>
<div>
7.3.	We are not responsible for any action you take in reliance on any content or information provided on or by Plot Potential and/or the Services.
</div>
<div>
7.4.	We are not liable to you for the consequences of your use of Plot Potential and/or the Services, and you will indemnify, defend and hold us harmless from any claims arising from your use of Plot Potential and/or the Services, including reliance on any information.
</div>
<div>
7.5.	We reserve the right to make changes to the content of Plot Potential without warning.
</div>
<div>
7.6.	Plot Potential uses information provided by third parties (for more information on this, see clause 8 below). Plot Potential is not responsible or liable: 
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>
7.6.1.	for the accuracy or suitability of that information; and/or
</div>
<div>
7.6.2.	directly or indirectly, for any errors, omissions, or inaccuracies in any content on Plot Potential provided by or sourced from a third party; and/or
</div>
<div>
7.6.3.	directly or indirectly for any loss or damage which may result from your reliance on any information, advice, statement, or representation, whether negligent or otherwise, contained on, made on, presented on, hosted on, Plot Potential that was provided by or sourced from a third party.
</div>
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
  <div>
  7.7.	Information provided by Plot Potential and/or the Services is based on algorithmic selection but does not display or represent all possible options. Other professional consultants may offer different valid options. 
  </div>
  <div>
  7.8.	All architectural and engineering information provided by Plot Potential and/or the Services is or may be subject to approval by relevant local or central government regulatory authorities at their own discretion. Plot Potential does not imply, warrant, or guarantee that any architectural or engineering information it displays is suitable for regulatory approval, or will be approved by the relevant regulatory authority.
    </div>
    <div>
    7.9.	All results presented by Plot Potential are based on the available statutes, regulations, information, and other open data at the time those results are presented to you. The statutes, regulations, information, and other open data are subject to change at any time without warning. We will endeavour to stay up to date with these changes, but do not and cannot guarantee, represent, or warrant that the information provided by Plot Potential is the most accurate or up-to-date. Users must make their own independent inquiries and must not rely on Plot Potential alone.
    </div>
</div>

</section>



<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
8.	Third Party information and Licence Terms</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
8.1.	Plot Potential sources certain Information from third parties (<span className='text-white'>“Third Party Information”</span>) to provide you with the Services. By using Plot Potential, you agree to the third-party licence conditions outlined in this clause.
</div>
<div>
8.2.	Plot Potential will attribute, where practical, the Third Party Information to its source.
</div>
<div>
8.3.	You gain no proprietary or other rights in any Third Party Information provided as part of Plot Potential.
</div>
<div>
8.4.	Third Party Information sourced from Land Information New Zealand (<span className='text-white'>“LINZ”</span>) is provided under the terms of the LINZ Licence for Personal Data 2.2 and the Creative Commons Attribution 3.0 New Zealand Licence (CC BY 3.0 NZ), the details and terms of that can be found here: https://www.linz.govt.nz/products-services/data/licensing-and-using-data/linz-licence-personal-data.
</div>
<div>
8.5.	Users must comply with the LINZ License terms concerning access and use of the provided information.
</div>
<div>
8.6.	Third Party Information sourced from the Ministry of Business, Innovation and Employment (<span className='text-white'>“MBIE”</span>) is provided under the terms of the MBIE API Access Agreement, which can be found here: https://support.api.business.govt.nz/s/article/terms-and-conditions.
</div>
<div>
8.7.	Users must adhere to the terms of the MBIE API Access Agreement, including restrictions on usage, reporting obligations, and indemnification.
</div>
<div>
8.8.	Users must take reasonable measures to protect Third Party Information sourced from any local Council (<span className='text-white'>“Council Data”</span>) from unauthorised use or access, refrain from selling or offering it for sale, and acknowledge its potential origin from the relevant local or territorial authority.    
</div>
</div>
</section>




<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
9.	Your obligations as a User of Plot Potential and the Services
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
9.1.	You must only use the Services and Plot Potential in accordance with these Terms and responsibly, lawfully and in good faith. This means you must:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>
9.1.1.	Keep your passwords and user information secret; and
</div>
<div>
9.1.2.	Immediately notify us if you become aware of any unauthorised use or disclosure of information, by sending an email to Contact@plotpotential.co.nz; and
</div>
<div>
9.1.3.	Use Plot Potential in accordance with local laws, including intellectual property laws. We are not responsible for the consequences if you do not comply with applicable law when using Plot Potential.
</div>
</div>

<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
9.2.	Except where these Terms say otherwise, you are solely responsible for ensuring that your use of the Services and Plot Potential complies with these Terms and applicable law in your jurisdiction. 
</div>
<div>
9.3.	You accept and agree that your use of the Services is for your own private purposes alone. You cannot:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>
9.3.1.	Resell, make available for reselling, or otherwise commercially provide the Services.</div>
<div>
9.3.2.	Sell, share, publish, or otherwise distribute or make available, information gained using the Services (except as otherwise permitted by these Terms, for example, to seek further professional advice).
</div>
<div>
9.3.3.	Represent you are authorised to do any of clause 9.3.1 and/or 9.3.2.
</div>
</div>


<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
9.4.	For the protection of other Users of Plot Potential and for your own beneficial experience with Plot Potential you cannot:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>
9.4.1.	use Plot Potential and/or the Services in an illegal, obscene, threatening, defamatory, harassing, or offensive way, or otherwise use Plot Potential and/or the Services in an unlawful way or for an unlawful end-goal;
</div>
<div>
9.4.2.	impersonate another person or represent you are authorised to act on behalf of another person when you are not;
</div>
<div>
9.4.3.	authorise anyone to, or attempt to:
</div>
</div>

<div className='flex flex-col lg:mx-36 mx-14 text-lg space-y-4  '>
<div>
9.4.3.1.	abuse or misuse the Services, including gaining or attempting to gain unauthorised access to the Services, or altering or destroying information stored in the Services; or
</div>
<div>
9.4.3.2.	use the Services, in a manner that interferes with other users’ use of the Services; and
</div>
</div>


<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>
9.4.4.	circumvent any technical measures we have put in place to: 
</div>
</div>


<div className='flex flex-col lg:mx-36 mx-14 text-lg space-y-4  '>
<div>
9.4.4.1.	safeguard the Services, or the confidentiality, integrity or accessibility of any information and data stored as part of the Services or on Plot Potential; or 
</div>
<div>
9.4.4.2.	to restrict access to the Services to people authorised to use the Services under these Terms.
</div>
</div>



<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
9.5.	As Plot Potential is our Intellectual Property (see clause 21 further on in these terms), you are also not permitted to:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>
9.5.1.	Give anyone else (business, person, group or entity) access to Plot Potential other than your Authorised Users, unless required to by law, or unless we give you our written permission to do so;
</div>
<div>
9.5.2.	copy, reverse engineer, decompile or disassemble Plot Potential, in whole or in part, or otherwise attempt to discover the source code to Plot Potential; 
</div>
<div>
9.5.3.	modify Plot Potential or combine Plot Potential with any other software or services not provided or approved by us. This includes making any derivative works, translations, localisations, or ports of Plot Potential;
</div>
<div>
9.5.4.	reproduce, publish, or distribute content in connection with the Services and/or Plot Potential that infringes anyone else’s Intellectual Property rights; 
</div>
<div>
9.5.5.	use Plot Potential in an automated process (such as data mining);
</div>
<div>
9.5.6.	Use Plot Potential in any way that would be inconsistent with our Intellectual Property rights; or
</div>
<div>
9.5.7.	allow anyone else, or give anyone else permission, to do anything listed above in clauses 9.5.1 to 9.5.5 inclusive.
</div>
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
9.6.	If you do anything listed above that you are not permitted to do under these Terms, we may immediately terminate your licence to use Plot Potential and the Services.
</div>
<div>
9.7.	You may use the information provided by Plot Potential to seek further professional advice, such as consulting advice.
</div>
</div>



</section>




<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
10.	Authorised 
 </div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
10.1.	You may appoint Authorised Users by providing them with the relevant Credentials to access Plot Potential.
</div>
<div>
10.2.	You may disable or remove an Authorised User under these Terms at any time.</div>
<div>
10.3.	All Authorised Users are bound by these Terms. You are responsible for ensuring your Authorised Users comply with these Terms. 
</div>
<div>
10.4.	To avoid doubt, you must:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>10.4.1.	Require each Authorised User to have unique Credentials.</div>
<div>10.4.2.	Ensure that only the specific Authorised User with those assigned Credentials accesses and uses the Services and/or Plot Potential with those Credentials.
</div>
</div>
</section>



<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
11.	Service levels
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
11.1.	We will use all reasonable efforts to provide the Services:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>
11.1.1.	in accordance with these Terms and New Zealand law;
</div>
<div>
11.1.2.	exercising reasonable care, skill and diligence; and
</div>
<div>
11.1.3.	using suitably skilled, experienced and qualified personnel.
</div>
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
11.2.	Our ability to provide the Services is dependent on factors outside of our control including (without limitation) the availability of telecommunications networks and as such we cannot and do not warrant that the Services will be continuously available or available without interruption. However:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>
11.2.1.	We will do our best to maintain access to and uptime of Plot Potential to reasonable commercial standards during Business Hours, except for any planned maintenance, emergency maintenance, or unplanned outages. 
</div>
<div>
11.2.2.	Where there is planned maintenance, this will take place every two weeks during the hours of 1am and 2am New Zealand time (standard or daylight time, whichever is applicable as the case may be) or whenever we notify you in writing in advance.  
</div>
<div>
11.2.3.	For any necessary emergency maintenance, or any unplanned outages, we will notify you on our Website as soon as practicable when these occur and how long we estimate or expect the downtime to be. 
</div>
</div>
</section>




<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
12.	Usernames & Credentials</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
12.1.	We authorise you to use the Credentials assigned to you by Plot Potential. However, you do not own any Credentials, and we may delete or change Credentials at any time in our sole discretion. 
</div>
<div>
12.2.	It is your responsibility to come up with and keep reasonable and appropriate security precautions for your Credentials to keep them safe and prevent unauthorised use. 
</div>
</div>
</section>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
13.	Third party access to and use of data</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
13.1.	We will not permit third parties (other than our software developers) to access or use the data stored in Plot Potential, unless we need to comply with a lawful order of a competent authority, including by Court order or other such administrative or legal tribunal or body, for the purposes set out in that lawful order.
</div>
</div>
</section>

<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
14.	Updates to Plot Potential</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
14.1.	We will provide you with all Updates to Plot Potential that we make generally available. 
</div>
<div>
14.2.	If an Update requires specific software or hardware requirements that have changed since the previous version of Plot Potential, or the previous update, we will notify you of those requirements in advance. We are not responsible for keeping your own devices software and/or firmware up to date.
</div>
</div>
</section>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
15.	Third party code </div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
15.1.	Plot Potential may use or interact with software or other technology (<span className='text-white'>Third Party Code</span>) that is licensed from, and owned by, third parties (<span className='text-white'>Third Party Licensors</span>) – for example, by integrating with other websites or programs. You agree that, by using Plot Potential:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>15.1.1.	You will use any Third Party Code in accordance with these Terms and any other restrictions specified in any licence provided in addition to these Terms for that Third Party Code;</div>
<div>15.1.2.	no Third Party Licensor makes any representation or warranty to you concerning the Third Party Code or Plot Potential;</div>
<div>15.1.3.	no Third Party Licensor will have any obligation or liability to you as a result of these Terms or your use of the Third Party Code or Plot Potential.</div>
</div>
</section>



<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
16.	Limitation of liability, indemnity, warranties</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
16.1.	Subject to any rights you may have at law, our liability to you under these Terms is excluded to the fullest extent permitted by law.
</div>
<div>
16.2.	To the extent permitted by New Zealand law, we make no warranties, representations, or guarantee about whether Plot Potential, our Services, or their content is fit for purpose, or the accuracy, quality, reliability, completeness, utility or otherwise of any information or content on Plot Potential or our Services.</div>
<div>
16.3.	If we are ever liable to you and, for any reason we cannot rely on the above exclusion of our liability under these Terms, then the maximum combined amount we will have to pay you will be <span className='text-white'>the Fees</span>, regardless of whether you use the Professional Version or not, for any event or series of related events.</div>
<div>
16.4.	You indemnify us against all loss of profits, savings, revenue, or data, and any other claim, damage, loss, liability, and cost, including legal costs on a solicitor and own client basis: </div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>16.4.1.	incurred or suffered by us as a direct or indirect result of your failure to comply with these Terms; and/or</div>
<div>16.4.2.	any claims arising from your use of Plot Potential (including made by third parties); and/or</div>
<div>16.4.3.	any breach or alleged breach of the Third Party Licences in clause 8 above.</div>
</div>
</section>



<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
17.	When we can suspend or terminate your access to Plot Potential</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
17.1.	Without prejudice to any other right or remedy available to us, we may suspend or terminate your access to Plot Potential and/or the Services at any time for any reason by giving written notice by email. If you use the Professional Version, we are not obligated to refund any Fees paid prior to suspension or termination.
</div>
<div>
17.2.	If your access to Plot Potential or the Services is terminated your user accounts will be closed and you must stop using Plot Potential or the Services and must not attempt to gain further access subject to any limited rights permitted under these Terms.
</div>
<div>
17.3.	If you have any unpaid Fees, we may suspend or terminate your access to Plot Potential or the Services under clauses 17.1 and 17.2 above.
</div>
</div>
</section>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
18.	Cancelling your subscription to the Professional Version</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
18.1.	You may cancel your usage agreement to the Professional Version under these Terms at any time by cancelling directly from the subscription page on the Website.
</div>
<div>
18.2.	On cancellation or termination (but not suspension) either by you (under clause 18.1) or us (under clause 17 above, including its subclauses or clause 19, including its subclauses below) we will remove your access to the Services and to Plot Potential. 
</div>
</div>
</section>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
19.	Deleting your account</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
19.1.	You may delete your account with Plot Potential under these Terms at any time from  your account  or on the Website. 14 days after selecting this option, we will completely delete your account and any data associated with it. You may recover your account within that 14 day period by contacting us and requesting account recovery during that 14 day period.    
</div>
</div>
</section>

<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
20.	Contact / Notices 
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
20.1.	If we need to contact you personally, we will do so by email which will be deemed to have been received by you once we have sent it.</div>
<div>
20.2.	If you need to contact us, please email us at contact@plotpotential.co.nz or at the email or other contact details on our Website for the relevant purposes.
</div>
</div>
</section>


<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col'>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
21.	Intellectual Property
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
21.1.	All Intellectual Property rights in and to Plot Potential belong to us or are exclusively licenced to us. You will not acquire any Intellectual Property rights in and to Plot Potential whatsoever, or in any improvements or variations that may be made to it.</div>
<div>
21.2.	You must not, and you must not permit any other person to:
</div>
</div>
<div className='flex flex-col lg:mx-32 mx-10 text-lg space-y-4  '>
<div>
21.2.1.	Tamper, obscure, alter, or remove any trade mark, any patent or copyright notices, or other proprietary right or legal notices in relation to Plot Potential or the Services; or
  </div>
<div>
21.2.2.	Do anything that would or might invalidate or be inconsistent with our Intellectual Property rights (either as owner or exclusive licensee) that is not permitted by these Terms.
  </div>
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
21.3.	Nothing contained in these Terms or in Plot Potential grants you, by implication or otherwise, any right to use any trade mark or other Intellectual Property right without our written permission.</div>
</div>
</section>




<section className='text-[#00ff99] space-y-5 mt-10 lg:mx-24 md:mx-20 flex flex-col '>
<div className=' text-2xl font-bold lg:mx-5 mx-3'>
22.	General
</div>
<div className='flex flex-col lg:mx-28 mx-7 text-xl space-y-4  '>
<div>
22.1.	<span className='text-xl font-extrabold text-white'>No assignment or transfer:</span> You may not transfer or assign any of your rights or obligations under these Terms without our prior written consent. This means that no other third party may enforce any of the provisions in these Terms.
</div>
<div>
22.2.	<span className='text-xl font-extrabold text-white'>Duration/Survival:</span> Clauses which by their nature are intended to survive cancelling your access to Plot Potential, including clauses 8 and 21, continue in force.</div>
<div>
22.3.	<span className='text-xl font-extrabold text-white'>Governing law and jurisdiction:</span> These Terms are governed by New Zealand law, and both us and you submit to the non-exclusive jurisdiction of the Courts of New Zealand.</div>
<div>
22.4.	<span className='text-xl font-extrabold text-white'>No waiver:</span> We will only waive a right under these Terms in writing. This means that even if we have not enforced a clause in these Terms, that does not mean we are prevented from enforcing that cause, or any other clause, at any other time unless we say in writing we will not enforce it.</div>
</div>
</section>
    </div>)
  }

  export default TermsConditions;
