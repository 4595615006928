import { Urls } from "./AppConfig";

export const generateId = (length) => {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

function pointGeomQueryEsriFeatureLayer(featureLayerUrl, id, success, failure) {
    let data = {
        "where": "id=" + id,
        "geometry": '',
        "geometryType": "esriGeometryPoint",
        "inSR": "4326",
        "spatialRel": "esriSpatialRelIntersects",
        "resultType": "none",
        "distance": "0.0",
        "units": "esriSRUnit_Meter",
        "returnGeodetic": "false",
        "outFields": "*",
        "returnGeometry": "true",
        "returnCentroid": "false",
        "featureEncoding": "esriDefault",
        "multipatchOption": "xyFootprint",
        "applyVCSProjection": "false",
        "returnIdsOnly": "false",
        "returnUniqueIdsOnly": "false",
        "returnCountOnly": "false",
        "returnExtentOnly": "false",
        "returnQueryGeometry": "false",
        "returnDistinctValues": "false",
        "cacheHint": "false",
        "returnZ": "false",
        "returnM": "false",
        "returnExceededLimitFeatures": "true",
        "sqlFormat": "none",
        "f": "geojson",
        // "resultRecordCount": 1
    };
    let formData = new FormData();
    Object.keys(data).map(k => {
        formData.append(k, data[k]);
    })
    fetch(featureLayerUrl, {
        method: 'POST',
        body: formData
    }).then(r => r.json()).then(result => {
        return success(result.features);
    })
        .catch(e => {
            console.log(e);
            return failure(e);
        });
}

const loadParcelById = (parcelId) => {
    return new Promise((resolve, reject) => {
        pointGeomQueryEsriFeatureLayer(
            'https://services.arcgis.com/xdsHIIxuCWByZiCB/ArcGIS/rest/services/LINZ_NZ_Primary_Parcels/FeatureServer/0/query',
            parcelId,
            (result) => {
                // console.log(result);
                resolve(result);
            },
            (err) => {
                console.log(err);
                reject(err);
            }
        )
    })
}

export const loadparcelIdFromAddressId = async (addressId) => {
    const url = 'https://data.linz.govt.nz/services;key=090cb5ff348b438aac49f2df6ca09256/wfs/table-53324?service=WFS&request=GetFeature&PROPERTYNAME=address_id,parcel_id&CQL_FILTER=address_id=' + addressId + '&typename=data.linz.govt.nz:table-53324&outputformat=application/json';
    let result = await fetchGet(url);
    if (result.features.length > 0) {
        let parcelId = result.features[0].properties['parcel_id'];
        let parcels = await loadParcelById(parcelId);
        if (parcels.length > 0) {
            let parcel = parcels[0];
            return parcel;
        }
    }
    return null;
}

export const fetchGetWithSignal = (url, signal) => {
    let token = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
        let headers = {
            "Content-Type": "application/json",
        };
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }
        fetch(url, {
            method: 'GET',
            headers: headers,
            signal: signal
        }).then(r => r.json()).then(result => {
            return resolve(result);
        })
            .catch(e => {
                console.log(e);
                return reject(e);
            });
    })
}

export const fetchPost = (url, data={}, token=null) => {
    token = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
        let headers = {
            "Content-Type": "application/json",
        };
        if(!token){
            token = window.localStorage.getItem('token');
        }
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: headers
            // signal: signal
        }).then(r => r.json()).then(result => {
            return resolve(result);
        })
            .catch(e => {
                console.log(e);
                return reject(e);
            });
    })
}

export const fetchGet = (url, token = null) => {
    return new Promise((resolve, reject) => {
        let headers = {
            "Content-Type": "application/json",
        };
        if(!token){
            token = window.localStorage.getItem('token');
        }
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }
        fetch(url, {
            method: 'GET',
            headers: headers
        }).then(r => r.json()).then(result => {
            return resolve(result);
        })
            .catch(e => {
                console.log(e);
                return reject(e);
            });
    })
}

export const getRandomColorRGBA = () => {
    return `rgba(${Math.round(Math.random() * 255)}, ${Math.round(Math.random() * 255)}, ${Math.round(Math.random() * 255)}, ${Math.random()})`
}

export const toSlug = (data) => {
    return data
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[\W_]+/g, "-")
        .toLowerCase()
        .replace(/^-+|-+$/g)
}

export const validateToken = () => {
    return new Promise((resolve, reject) => {
        try {
            let token = window.localStorage.getItem('token');
            if (!Boolean(token)) {
                return resolve(null);
            }
            fetch(Urls.ValidateToken, {
                method: 'POST',
                body: JSON.stringify({
                    token: token
                }),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(e => e.json())
                .then(r => {
                    if (r.error) {
                        window.localStorage.removeItem('token');
                        return resolve(null);
                    }
                    return resolve(r.data);
                }).catch(err => {
                    console.log(err);
                    window.localStorage.removeItem('token');
                    return resolve(null);
                })
        } catch (err) {
            console.log(err);
            window.localStorage.removeItem('token');
            return resolve(null);
        }
    })
}
