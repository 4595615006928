import { connect, useDispatch } from "react-redux"
import { toggleAlertDialog, toggleReportLoader, toggleManualOrderDialog, toggleSamplePlansDialog } from "../actions"
import AddressSearch from "./AddressSearch"
import { Theme, Urls } from "../utils/AppConfig"
import { useEffect, useState } from "react"
import { capitalizeFirstLetter, fetchGet, fetchPost, toSlug } from "../utils/helpers";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
// import { useNavigate } from "react-router-dom";
import AddressInput from "./AddressInput"
import Button from 'react-bootstrap/Button';
import ReportGenerateLoader from '../components/Report/ReportGenerateLoader'

const mapStateToProps = (state) => {
    return {
        dialog: state.DialogReducer,
        data: state.DataReducer
    }
}

const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: '800px',
    backgroundColor: '#171717',
    border: '2px solid #000',
    boxShadow: 24,
}


const ShowSamplePlans = (props) => {
    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const imgs = props.dialog.samplePlansDialog.imgs;
    const quota = props.dialog.samplePlansDialog.quota;
    let token = window.localStorage.getItem('token');
    const checkReportStatus = (reportId, jobId) => {
        let headers = {};
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }
        fetch(`${Urls.GetJobStatus}?jobId=${jobId}`, { headers })
            .then(jr => jr.json())
            .then(jr => {
                if (!jr.data) {
                    setTimeout(() => {
                        checkReportStatus(reportId, jobId)
                    }, 3000);
                    return;
                }
                if (jr.error || jr.data['status'] === 'ERROR') {
                    dispatch(toggleReportLoader(false));
                    window.alert("Server error. Please, try again.");
                    return
                }
                if (jr.data['status'] === 'COMPLETED') {
                    fetchGet(`${Urls.GetReportStatus}?reportId=${reportId}`, token)
                        .then(status => {
                            if (status.error || status === "ERROR") {
                                dispatch(toggleReportLoader(false));
                                return window.alert("Server error. Please, try again.");
                            }
                            status = status.data.status;

                            console.log("Report status:", status);
                            if (status === 'PDF_GENERATED') {
                                dispatch(toggleReportLoader(false));
                                window.location.href = "/report?id=" + reportId;
                                // navigate("/report?id=" + reportId)
                                // if (quota && (quota.message === 'QUOTA_LIMIT_REACHED' || quota.message === 'PAY_PER_REPORT')) {
                                //     console.log('Init Payment');
                                //     fetchGet(`${Urls.PayForReport}?reportId=${reportId}`, token)
                                //         .then(paymentInfo => {
                                //             console.log("paymentInfo", paymentInfo);
                                //             if (paymentInfo.error) {
                                //                 return window.alert("Payment error");
                                //             }
                                //             window.location.href = paymentInfo.data
                                //         });
                                // } else {
                                //     //download report
                                //     window.location.href = "/report?id=" + reportId;
                                // }
                            }else{
                                setTimeout(() => {
                                    checkReportStatus(reportId, jobId)
                                }, 3000);
                                return;
                            }
                        })
                } else {
                    setTimeout(() => {
                        checkReportStatus(reportId, jobId)
                    }, 3000);
                }
            })


    }

    return <>
        <Modal
            show={Boolean(props.dialog.samplePlansDialog.flag)}
            onHide={() => { dispatch(toggleSamplePlansDialog(false, [])) }}
            centered
            size="lg"
            style={{
                background: 'transparent'
            }}
        >
            <div sx={style}>
                <div id="modal-modal-description" sx={{ mt: 2 }} style={{
                    background: 'rgba(0, 0, 0, 0.6)',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.6)',
                    backdropFilter: 'blur(5px)',
                    webkitBackdropFilter: 'blur(5px)',
                    color: '#00FF99'
                }}>
                    <div style={{ padding: 24, border: `2px solid ${Theme.PrimaryLogo}`, borderRadius: 2 }}>
                        <p className="header-2-small" style={{ textAlign: 'center' }}>
                            {props.data.fullAddress ? props.data.fullAddress : ''}
                        </p>
                        <hr style={{ color: Theme.PrimaryLogo }} />
                        <p className="content-text-details-2" style={{ fontSize: '15px !important' }}>
                            Generated plan for the selected plot with the provided options.
                        </p>

                        {
                            imgs.map(img => {
                                let hType = img['houseType'];
                                let noOfFloors = img['noOfFloors'];
                                return <>
                                    <p className="content-text-details-2" style={{ fontSize: '15px !important' }}>
                                        [PlanID: {img['id']}] House type: {capitalizeFirstLetter(hType)} with {noOfFloors} floors
                                    </p>
                                    <img src={img['imageUrl']} width={400} />
                                    {
                                        quota.message === 'QUOTA_LIMIT_REACHED' ? <p className="text-sm font-medium text-white" style={{ textAlign: 'center' }}>You have reached your subscription report limit.</p> : ''
                                    }
                                    {
                                        props.data.currentSubscription && (props.data.currentSubscription['quota']['quotaLimit'] - props.data.currentSubscription['quota']['quotaUsed']) > 0 ? <p className="text-sm font-medium text-white" style={{ textAlign: 'center' }}>{(props.data.currentSubscription['quota']['quotaLimit'] - props.data.currentSubscription['quota']['quotaUsed'])} reports remaining for your subscription.</p> : ''
                                    }
                                    <p className="content-text-details-2" style={{ fontSize: '15px !important', margin: 2 }}>
                                        <span style={{color: 'orange'}}>Note:</span> If you are not satisfied with the generated plan, please, request a manual report from our in-house consultants (24 hrs).
                                    </p>

                                    <Button style={{ float: 'right', margin: 2 }} variant="outline-success"
                                        onClick={async () => {
                                            dispatch(toggleReportLoader(true));
                                            let reportOrder = await fetchGet(`${Urls.CreateReportOrder}?planId=${img.id}&token=${token}`, token);
                                            if (reportOrder.error) {
                                                console.log(reportOrder);
                                                dispatch(toggleReportLoader(false));
                                                return window.alert("Server error. Please, try again later.");
                                            }
                                            let reportId = reportOrder.data.reportId;
                                            let jobId = reportOrder.data.jobId;
                                            setTimeout(() => { checkReportStatus(reportId, jobId); }, 3000);
                                        }}
                                    >
                                        Generate Report
                                        {/* {!quota ? 'Generate Report ' : quota.message === 'QUOTA_LIMIT_REACHED' ? 'Generate Report (NZ$ 19)' : quota.message === 'PAY_PER_REPORT' ? 'Generate Report (NZ$ 19)' : 'Generate Report '} */}
                                    </Button>

                                    <Button style={{ float: 'right', margin: 2, border: '2px solid orange', color: 'orange' }} variant="outline-warning"
                                        onClick={async () => {
                                            //planId
                                            dispatch(toggleReportLoader(true));
                                            try{
                                                let reportOrder = await fetchGet(`${Urls.RequestManualReportNoPdf}?planId=${img.id}&token=${token}`, token);
                                                if (reportOrder.error) {
                                                    console.log(reportOrder);
                                                    dispatch(toggleReportLoader(false));
                                                    dispatch(toggleAlertDialog(true, "In development", reportOrder.message))
                                                    return;
                                                }else{
                                                    dispatch(toggleReportLoader(false));
                                                    dispatch(toggleAlertDialog(true, "Report Requested", reportOrder.message))
                                                }
                                            }catch(e){
                                                dispatch(toggleReportLoader(false));
                                                console.log(e);
                                            }
                                        }}
                                    >
                                        Request Manual Report (24 hrs)
                                        {/* {!quota ? 'Generate Report ' : quota.message === 'QUOTA_LIMIT_REACHED' ? 'Generate Report (NZ$ 19)' : quota.message === 'PAY_PER_REPORT' ? 'Generate Report (NZ$ 19)' : 'Generate Report '} */}
                                    </Button>
                                    {/* <ReportGenerateLoader /> */}
                                    <br />

                                </>
                            })
                        }

                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default connect(mapStateToProps)(ShowSamplePlans);