import { useDispatch } from "react-redux";
import AppHeader from "../components/AppHeader";
import HeaderSection from "../components/Landing/HeaderSection";
import FooterSection from "../components/Landing/FooterSection";
import ReportSection from "../components/Landing/ReportSection";
import Cursor from '../components/Cursor/Cursor';
import ScrollToAnchor from '../utils/ScrollToAnchor';

const ReportsInfoPage = () => {
    return <>
    <Cursor/>
    <ScrollToAnchor />
        <HeaderSection/>
        <ReportSection />
        <FooterSection />
        </>
        }

export default ReportsInfoPage;