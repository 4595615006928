import HomeHeader from "../components/HomeHeader";
import Quote from "../components/Quote";


const QuotePage = (props) => {
    //viewQuote
    
    return <>
        <HomeHeader />
        <Quote />
    </>
}

export default QuotePage;