import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Overlay from 'react-bootstrap/Overlay';
import { fetchGetWithSignal } from "../utils/helpers";
import { Urls } from "../utils/AppConfig";
import { Link } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';


const AddressInput = (props) => {

    let [searchText, setSearchText] = useState(props.defaultValue || '');
    let [searchResults, setSearchResults] = useState([]);
    let [ctrl, setCtrl] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);

    const onClick = props.onClick;

    const queryAddress = (e) => {
        let targetEle = e.target;
        let text = e.target.value;
        setSearchText(text);
        setAnchorEl(null);
        setSearchResults([])
        if (!Boolean(text)) {
            return;
        }
        let addressUrl = `${Urls.AddressSearch}?searchText=${text}`;
        if (ctrl !== null) {
            ctrl.abort();
        }
        const controller = new AbortController();
        setCtrl(controller);
        let _pm = fetchGetWithSignal(addressUrl, controller.signal);
        _pm.then(result => {
            let sr = [];
            for (let i = 0; i < result.data.length; i++) {
                sr.push({
                    gid: result.data[i]['gid'],
                    id: result.data[i]['address_id'],
                    name: result.data[i]['full_address'],
                    geom: JSON.parse(result.data[i]['geom']),
                })
            }
            setSearchResults(sr);
            setAnchorEl(targetEle);
        })
            .catch(e => {

            })
    }
    let inputStyle = { fontFamily: 'Verdana, sans-serif' };
    if (props.height) {
        inputStyle['height'] = props.height;
    }

    return <div>
        <InputGroup className="address-search-container">
            <InputGroup.Text className="address-search-icon" style={inputStyle}>
                <div>
                    &#9906;
                </div>
            </InputGroup.Text>

            <Form.Control
                type="text"
                placeholder="Enter your address..."
                className="address-search"
                style={inputStyle}
                onChange={queryAddress}
                value={searchText}
            />
        </InputGroup>


        <Overlay rootClose={true} target={anchorEl} show={Boolean(anchorEl)} placement="bottom" onHide={() => { setSearchResults([]); setAnchorEl(null) }}>
            {({
                placement: _placement,
                arrowProps: _arrowProps,
                show: _show,
                popper: _popper,
                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                ...props
            }) => (
                <div
                    {...props}
                    style={{
                        backgroundColor: '#fff',
                        padding: '2px 10px',
                        color: 'white',
                        borderRadius: 3,
                        width: anchorEl ? anchorEl.getBoundingClientRect().width : 0,
                        zIndex: 1003,
                        // marginLeft: anchorEl ? `-${anchorEl.getBoundingClientRect().width / 2 - 64}px` : 0,
                        ...props.style,
                    }}
                >
                    {
                        searchResults.map(r => {
                            return <Link style={{
                                textDecoration: 'none', color: '#333'
                            }}>
                                <p
                                    style={{
                                        cursor: 'pointer',
                                        width: '100%',
                                        padding: 8,
                                        border: 'none'
                                    }}
                                    onClick={async () => {
                                        setAnchorEl(null);
                                        setSearchText(r.name)
                                        setSearchResults([]);
                                        onClick({ id: r.id, name: r.name })
                                    }} key={r.id} sx={{ p: 2 }}>{r.name}</p>
                            </Link>
                        })
                    }
                </div>
            )}
        </Overlay>


    </div>
}

export default AddressInput;