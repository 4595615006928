import React, { useEffect, useState } from "react";
import "./Cursor.css";

import {motion} from 'framer-motion';
const Cursor = () =>{

    const [largeCircle,setLargeCircle] =useState({x:0,y:0});
    const [smallCircle,setSmallCircle] =useState({x:0,y:0});

    useEffect(()=>{
        const mousemove = (e) =>{
            setLargeCircle({x:e.clientX, y:e.clientY});
            setSmallCircle({x:e.clientX, y:e.clientY});
            // setLargeCircle({x:e.clientX,y:clientY});
            // setSmallCircle({x:e.clientX,y:clientY});
        };
        window.addEventListener("mousemove",mousemove);
return () =>{
    window.removeEventListener("mousemove",mousemove);
}
},[]);

    return(
            <div className="hidden lg:block">
                <motion.div 
                animate={{
                    x:largeCircle.x-15, 
                    y:largeCircle.y-15,
                    transition:{type:"spring",mass:0.8,stiffness:750,damping:80},
                    
                }}
                className="large_circle"></motion.div>
                <motion.div 
                animate={{
                    x:smallCircle.x-4,
                    y:smallCircle.y-4,
                    transition:{type:"spring",mass:0.4,stiffness:800,damping:70},
                }}
                className="small_circle"></motion.div>
            </div>
    )
}
export default Cursor;