import { useState } from "react";
import * as moment from 'moment';
import { fetchPost } from "../../../utils/helpers";
import { Button } from "../../../shadcn/components/ui/button"
import { Urls } from "../../../utils/AppConfig";

const ActivePlan = ({ subscriptionData }) => {


  const cancelSubscription = (subscriptionId) => {
    let url = Urls.CancelSubscription;
    let token = window.localStorage.getItem('token');
    fetchPost(url, { subscriptionId }, token)
      .then(response => {
        console.log(response);
        if (response.error) {
          return window.alert("Error");
        } else {
          window.location.href = "/home";
        }
      })
      .catch(err => console.log(err));
  }


  const BenefitsCard = ({ text }) => {

    const planBenefits = [];
    const PlanData = text.split('\\n').map(str => {
      planBenefits.push(str)
    });


    return (
      planBenefits.map((item) =>
        <div className="p-2 sm:w-1/2 w-full">
          <div className="bg-[#111111] rounded flex p-4 h-full items-center border-2 border-[#00ff99]">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" className="text-green-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
              <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
              <path d="M22 4L12 14.01l-3-3"></path>
            </svg>
            <span className="title-font text-white font-medium">{item}</span>
          </div>
        </div>
      )
    )
  }


  return (

    <div className="items-center justify-center bg-transparent text-white text-center">
      <div className="p-6 px-0">
        <div className="text-center mb-10">
          <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-[#00ff99]">Subscription</h1>
        </div>

        <div className="flex flex-wrap justify-center items-center">
          <div className="flex flex-col  rounded pb-3 w-full md:w-2/3  mb-5 bg-[#111111] border-[#00ff99] border-2">
            <div className="py-3 text-[#00ff99] text-xl font-extrabold">
              Current Plan
            </div>


            <div className="flex px-4 h-full items-center justify-center gap-3 flex-col lg:flex-row">


              <div className="text-2xl bg-[#333] p-3 border-none rounded-xl">NZ$ {parseFloat(subscriptionData.subscriptionPlan["amount"]).toFixed(2)}</div>

              <div class="text-gray-900 bg-gradient-to-r from-[#00ff99] via-[#00ff99] to-[#00ff99]  shadow-lg shadow-[#00ff99]/50 font-bold rounded-lg text-xl px-5 py-3 text-center">
                {subscriptionData.subscriptionPlan['plan_name']}
              </div>
              {
                subscriptionData.subscription['status'] === 'CANCELLED' ? 'Cancelled' : <Button variant="destructive" className="text-white font-bold" onClick={() => {
                  cancelSubscription(subscriptionData.subscription['subscription_ref']);
                }}>
                  Cancel
                </Button>
              }
            </div>

            <div className="flex flex-col items-center justify-center rounded-lg bg-[#111111] p-4 lg:p-8">
              <div className="text-sm font-semibold sm:text-base" style={{ fontSize: 18 }}>Usage</div>
              <div className="text-xl font-bold text-[#00ff99] sm:text-2xl md:text-1xl">
                {`${subscriptionData.quota['quotaUsed']}/${subscriptionData.quota['quotaLimit']}`} reports
              </div>
            </div>


          </div>
        </div>


        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <div className="flex flex-col items-center justify-center rounded-lg bg-[#111111] p-4 lg:p-8">
              <div className="text-sm font-semibold sm:text-base">Subscribed On</div>

              <svg width={50} height={50} viewBox="0 0 120 120" id="Layer_1" version="1.1" fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                  <path fill="#FCEDDB" d="M19.5,96.5h73.1c4.1,0,7.3-3.3,7.3-7.3V26.9c0-4.1-3.3-7.3-7.3-7.3H19.5c-4.1,0-7.3,3.3-7.3,7.3v62.2 C12.2,93.2,15.5,96.5,19.5,96.5z"></path>
                  <path fill="#F47C7C" d="M100,26.6v15.1H12.2V26.6c0-3.9,3.2-7,7-7h73.7C96.8,19.5,100,22.7,100,26.6z"></path> <g>
                    <circle fill="#D66767" cx="35" cy="30.6" r="3.6"></circle> <circle fill="#D66767" cx="56.1" cy="30.6" r="3.6"></circle>
                    <circle fill="#D66767" cx="77.2" cy="30.6" r="3.6"></circle> </g> <g> <path fill="#FFF2F2" d="M36,15.1h-2c-0.6,0-1.1,0.5-1.1,1.1v13.9c0,0.6,0.5,1.1,1.1,1.1h2c0.6,0,1.1-0.5,1.1-1.1V16.3 C37.2,15.6,36.6,15.1,36,15.1z"></path>
                    <path fill="#FFF2F2" d="M57.1,15.1h-2c-0.6,0-1.1,0.5-1.1,1.1v13.9c0,0.6,0.5,1.1,1.1,1.1h2c0.6,0,1.1-0.5,1.1-1.1V16.3 C58.2,15.6,57.7,15.1,57.1,15.1z"></path>
                    <path fill="#FFF2F2" d="M78.2,15.1h-2c-0.6,0-1.1,0.5-1.1,1.1v13.9c0,0.6,0.5,1.1,1.1,1.1h2c0.6,0,1.1-0.5,1.1-1.1V16.3 C79.3,15.6,78.8,15.1,78.2,15.1z"></path> </g>
                  <g> <path fill="#EFA3A3" d="M37,51.8h-8.6c-1,0-1.8,0.8-1.8,1.8v8.6c0,1,0.8,1.8,1.8,1.8H37c1,0,1.8-0.8,1.8-1.8v-8.6 C38.9,52.7,38.1,51.8,37,51.8z"></path>
                    <path fill="#EFA3A3" d="M60.4,51.8h-8.6c-1,0-1.8,0.8-1.8,1.8v8.6c0,1,0.8,1.8,1.8,1.8h8.6c1,0,1.8-0.8,1.8-1.8v-8.6 C62.3,52.7,61.4,51.8,60.4,51.8z"></path>
                    <path fill="#EFA3A3" d="M83.8,51.8h-8.6c-1,0-1.8,0.8-1.8,1.8v8.6c0,1,0.8,1.8,1.8,1.8h8.6c1,0,1.8-0.8,1.8-1.8v-8.6 C85.6,52.7,84.8,51.8,83.8,51.8z"></path>
                    <path fill="#EFA3A3" d="M37,74.5h-8.6c-1,0-1.8,0.8-1.8,1.8v8.6c0,1,0.8,1.8,1.8,1.8H37c1,0,1.8-0.8,1.8-1.8v-8.6 C38.9,75.3,38.1,74.5,37,74.5z"></path>
                    <path fill="#EFA3A3" d="M60.4,74.5h-8.6c-1,0-1.8,0.8-1.8,1.8v8.6c0,1,0.8,1.8,1.8,1.8h8.6c1,0,1.8-0.8,1.8-1.8v-8.6 C62.3,75.3,61.4,74.5,60.4,74.5z"></path>
                    <path fill="#EFA3A3" d="M83.8,74.5h-8.6c-1,0-1.8,0.8-1.8,1.8v8.6c0,1,0.8,1.8,1.8,1.8h8.6c1,0,1.8-0.8,1.8-1.8v-8.6 C85.6,75.3,84.8,74.5,83.8,74.5z"></path> </g>
                  <circle fill="#83E598" cx="88.2" cy="85.3" r="19.6"></circle>
                  <path fill="#FFF2F2" d="M85.9,94.1c0,0-0.1,0-0.1,0c-0.8,0-1.5-0.4-2-1l-7.9-8.9c-1-1.2-0.9-3,0.2-4c1.2-1,3-0.9,4,0.2l6,6.7l10.3-9.9 c1.1-1.1,2.9-1,4,0.1c1.1,1.1,1,2.9-0.1,4L87.9,93.4C87.4,93.9,86.7,94.1,85.9,94.1z"></path>
                </g>
              </svg>




              <div className="text-xl font-bold text-[#00ff99] sm:text-2xl md:text-1xl">
                {moment(subscriptionData.subscription['subscribed_on']).format('MMM Do, YYYY')}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center rounded-lg bg-[#111111] p-4 md:p-8">
              <div className="text-sm font-semibold sm:text-base">Days Remaining</div>


              <svg viewBox="0 0 120 120" id="Layer_1" version="1.1" width={50} height={50} fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <g> <g> <path fill="#FFF2F2" d="M23.4,100.7h73.1c4.1,0,7.3-3.3,7.3-7.3V31.1c0-4.1-3.3-7.3-7.3-7.3H23.4c-4.1,0-7.3,3.3-7.3,7.3v62.2 C16.1,97.4,19.4,100.7,23.4,100.7z"></path>
                    <path fill="#F47C7C" d="M103.9,30.8v15.1H16.1V30.8c0-3.9,3.2-7,7-7h73.7C100.7,23.7,103.9,26.9,103.9,30.8z"></path> <g>
                      <circle fill="#D66767" cx="38.9" cy="34.8" r="3.6"></circle> <circle fill="#D66767" cx="60" cy="34.8" r="3.6"></circle>
                      <circle fill="#D66767" cx="81.1" cy="34.8" r="3.6"></circle> </g> <g>
                      <path fill="#FFF2F2" d="M39.9,19.3h-2c-0.6,0-1.1,0.5-1.1,1.1v13.9c0,0.6,0.5,1.1,1.1,1.1h2c0.6,0,1.1-0.5,1.1-1.1V20.5 C41.1,19.8,40.5,19.3,39.9,19.3z"></path>
                      <path fill="#FFF2F2" d="M61,19.3h-2c-0.6,0-1.1,0.5-1.1,1.1v13.9c0,0.6,0.5,1.1,1.1,1.1h2c0.6,0,1.1-0.5,1.1-1.1V20.5 C62.1,19.8,61.6,19.3,61,19.3z"></path>
                      <path fill="#FFF2F2" d="M82.1,19.3h-2c-0.6,0-1.1,0.5-1.1,1.1v13.9c0,0.6,0.5,1.1,1.1,1.1h2c0.6,0,1.1-0.5,1.1-1.1V20.5 C83.2,19.8,82.7,19.3,82.1,19.3z"></path> </g>
                    <g> <path fill="#FCA4A4" d="M40.9,56h-8.6c-1,0-1.8,0.8-1.8,1.8v8.6c0,1,0.8,1.8,1.8,1.8h8.6c1,0,1.8-0.8,1.8-1.8v-8.6 C42.8,56.9,42,56,40.9,56z"></path>
                      <path fill="#FCA4A4" d="M64.3,56h-8.6c-1,0-1.8,0.8-1.8,1.8v8.6c0,1,0.8,1.8,1.8,1.8h8.6c1,0,1.8-0.8,1.8-1.8v-8.6 C66.2,56.9,65.3,56,64.3,56z"></path>
                      <path fill="#FCA4A4" d="M87.7,56h-8.6c-1,0-1.8,0.8-1.8,1.8v8.6c0,1,0.8,1.8,1.8,1.8h8.6c1,0,1.8-0.8,1.8-1.8v-8.6 C89.5,56.9,88.7,56,87.7,56z"></path>
                      <path fill="#FCA4A4" d="M40.9,78.7h-8.6c-1,0-1.8,0.8-1.8,1.8v8.6c0,1,0.8,1.8,1.8,1.8h8.6c1,0,1.8-0.8,1.8-1.8v-8.6 C42.8,79.5,42,78.7,40.9,78.7z"></path>
                      <path fill="#FCA4A4" d="M64.3,78.7h-8.6c-1,0-1.8,0.8-1.8,1.8v8.6c0,1,0.8,1.8,1.8,1.8h8.6c1,0,1.8-0.8,1.8-1.8v-8.6 C66.2,79.5,65.3,78.7,64.3,78.7z"></path>
                      <path fill="#FCA4A4" d="M87.7,78.7h-8.6c-1,0-1.8,0.8-1.8,1.8v8.6c0,1,0.8,1.8,1.8,1.8h8.6c1,0,1.8-0.8,1.8-1.8v-8.6 C89.5,79.5,88.7,78.7,87.7,78.7z"></path> </g> </g>
                    <circle fill="#8AAEFF" cx="89.5" cy="86.9" r="19.2"></circle>
                    <circle fill="#658FDB" cx="89.5" cy="86.9" r="16.1"></circle>
                    <path fill="#FFF2F2" d="M91.5,83.6v-8c0-1.1-0.9-2-2-2s-2,0.9-2,2v8c-0.6,0.3-1,0.8-1.4,1.4h-4.5c-1.1,0-2,0.9-2,2s0.9,2,2,2h4.5 c0.7,1.2,1.9,1.9,3.4,1.9c2.2,0,3.9-1.8,3.9-3.9C93.4,85.5,92.7,84.3,91.5,83.6z M89.5,88.9c-1.1,0-1.9-0.9-1.9-1.9 s0.9-1.9,1.9-1.9s1.9,0.9,1.9,1.9S90.6,88.9,89.5,88.9z"></path>
                  </g> </g></svg>


              <div className="text-xl font-bold text-[#00ff99]  sm:text-2xl md:text-3xl">
                {Math.floor((new Date(subscriptionData.subscription['expires_on']).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center rounded-lg bg-[#111111] p-4 md:p-8">
              <div className="text-sm font-semibold sm:text-base">{subscriptionData.subscription['status'] === 'CANCELLED' ? 'Expires On' : 'Renewal On'}</div>


              <svg viewBox="0 0 120 120" id="Layer_1" version="1.1" width={50} height={50} fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <g> <path fill="#F4F4F4" d="M24.9,100.7H95c3.9,0,7-3.3,7-7.3V31.1c0-4.1-3.2-7.3-7-7.3H24.9c-3.9,0-7,3.3-7,7.3v62.2 C17.9,97.4,21,100.7,24.9,100.7z"></path>
                    <path fill="#7A9BFF" d="M102.1,30.8v15.1H17.9V30.8c0-3.9,3.1-7,6.7-7h70.7C99.1,23.7,102.1,26.9,102.1,30.8z"></path>
                    <g> <path fill="#6588E0" d="M38.9,31.2c-2,0-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6S40.9,31.2,38.9,31.2z"></path>
                      <path fill="#6588E0" d="M81.1,31.2c-2,0-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6S83.1,31.2,81.1,31.2z"></path> </g>
                    <g> <path fill="#F4F4F4" d="M39.9,19.3h-2c-0.6,0-1.1,0.5-1.1,1.1v13.9c0,0.6,0.5,1.1,1.1,1.1h2c0.6,0,1.1-0.5,1.1-1.1V20.5 C41.1,19.8,40.5,19.3,39.9,19.3z"></path>
                      <path fill="#F4F4F4" d="M82.1,19.3h-2c-0.6,0-1.1,0.5-1.1,1.1v13.9c0,0.6,0.5,1.1,1.1,1.1h2c0.6,0,1.1-0.5,1.1-1.1V20.5 C83.2,19.8,82.7,19.3,82.1,19.3z"></path> </g>
                    <g> <path fill="#A9C1FC" d="M39.7,56.4H33c-1,0-1.8,0.8-1.8,1.8v6.6c0,1,0.8,1.8,1.8,1.8h6.6c1,0,1.8-0.8,1.8-1.8v-6.6 C41.5,57.2,40.7,56.4,39.7,56.4z"></path>
                      <path fill="#A9C1FC" d="M87,56.4h-6.6c-1,0-1.8,0.8-1.8,1.8v6.6c0,1,0.8,1.8,1.8,1.8H87c1,0,1.8-0.8,1.8-1.8v-6.6 C88.8,57.2,88,56.4,87,56.4z"></path>
                      <path fill="#A9C1FC" d="M63.3,56.4h-6.6c-1,0-1.8,0.8-1.8,1.8v6.6c0,1,0.8,1.8,1.8,1.8h6.6c1,0,1.8-0.8,1.8-1.8v-6.6 C65.1,57.2,64.3,56.4,63.3,56.4z"></path>
                      <path fill="#A9C1FC" d="M39.7,77H33c-1,0-1.8,0.8-1.8,1.8v6.6c0,1,0.8,1.8,1.8,1.8h6.6c1,0,1.8-0.8,1.8-1.8v-6.6 C41.5,77.8,40.7,77,39.7,77z"></path>
                      <path fill="#A9C1FC" d="M87,77h-6.6c-1,0-1.8,0.8-1.8,1.8v6.6c0,1,0.8,1.8,1.8,1.8H87c1,0,1.8-0.8,1.8-1.8v-6.6 C88.8,77.8,88,77,87,77z"></path>
                      <path fill="#A9C1FC" d="M63.3,77h-6.6c-1,0-1.8,0.8-1.8,1.8v6.6c0,1,0.8,1.8,1.8,1.8h6.6c1,0,1.8-0.8,1.8-1.8v-6.6 C65.1,77.8,64.3,77,63.3,77z"></path>
                    </g> <g> <path fill="#83E598" d="M83.6,90.1c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S88.1,90.1,83.6,90.1z M83.6,76.1c-3.3,0-6,2.7-6,6 s2.7,6,6,6s6-2.7,6-6S87,76.1,83.6,76.1z"></path>
                    </g> <path fill="#FFF2F2" d="M82.8,85.2L82.8,85.2c-0.3,0-0.6-0.1-0.7-0.4l-2.8-3.1c-0.4-0.4-0.3-1.1,0.1-1.4c0.4-0.4,1.1-0.3,1.4,0.1 l2.1,2.4l3.6-3.5c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L83.5,85C83.4,85.1,83.1,85.2,82.8,85.2z"></path>
                  </g> </g></svg>



              <div className="text-xl font-bold text-[#00ff99]  sm:text-2xl md:text-1xl">
                {moment(subscriptionData.subscription['expires_on']).format('MMM Do, YYYY')}
              </div>
            </div>
          </div>
        </div>

        <br />





        <div className="flex items-center justify-center p-3">
          <hr className="w-1/2" />
        </div>
        {/* <h5>Current plan: {subscriptionData.subscriptionPlan['plan_name']}</h5>
      Subscribed on {new Date(subscriptionData.subscription['subscribed_on']).toLocaleDateString('in')}
      <br />
      <h2 style={{ color: '#00FF99' }}>
        {Math.floor((new Date(subscriptionData.subscription['expires_on']).getTime() - new Date(subscriptionData.subscription['subscribed_on']).getTime()) / (1000 * 60 * 60 * 24))}
      </h2>days remaining
      <br />
      Next renewal on {new Date(subscriptionData.subscription['expires_on']).toLocaleDateString('in')}
      <hr /> */}
        {/* <div style={{ textAlign: 'center' }}>
        <div style={{ textAlign: 'left', width: 'fit-content' }}>
          <NewlineText text={subscriptionData.subscriptionPlan['plan_description']} />
        </div>
      </div> */}
        <section className="text-gray-600 body-font">
          <div className="container px-3 md:px-5 mx-auto">
            <div className="text-center mb-10">
              <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-[#00ff99]">Active Plan Benefits</h1>
            </div>
            <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
              <BenefitsCard text={subscriptionData.subscriptionPlan['plan_description']} />
            </div>
          </div>
        </section>
      </div>
    </div>

  )
}

export default ActivePlan;