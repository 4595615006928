import * as React from "react";
import { Theme } from "../../utils/AppConfig";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/modal";
import { Link } from "@nextui-org/react";
import { Button } from "@nextui-org/react";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
      data: state.DataReducer
  }
}


const RightComponent = (props) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [clientDetails, setClientDetails] = React.useState("");
  const [clientUrl, setClientUrl] = React.useState("");


  React.useEffect(() => {

    var usr_data = {
      client_id: props.data.user.id,
      client_name: props.data.user.client_name,
      client_email: props.data.user.client_email,
      client_phone: props.data.user.client_phone,
    };

    var encoded = btoa(JSON.stringify(usr_data));
    setClientDetails(encoded);
    setClientUrl('http://localhost:3000/query?token='+encoded);
  }, [props]);

  console.log(props.data.zoneData);


  return <>


    <div className="m-3 ">
      <div className="py-2 justify-center text-[#00ff99] font-bold flex">Flood Risk Rating</div>

      <div className=" flex p-2 text-center font-bold bg-gradient-to-r from-[#52C755] via-[#33A036,yellow,orange] to-red-600" >
      </div>
      <div className="flex justify-between text-white" style={{ fontSize: 12 }}>
        <div>Low</div>
        <div>Moderate</div>
        <div>Severe</div>
      </div>
    </div>

    <div className="m-3 ">
      <div className="py-2 justify-center text-[#00ff99] font-bold flex">Serviceability</div>

      <div className=" flex p-2 text-center font-bold bg-gradient-to-r from-red-600 via-[orange,yellow,#33A036] to-[#52C755]" >
      </div>
      <div className="flex justify-between text-white" style={{ fontSize: 12 }}>
        <div className="">1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
        <div>5</div>
      </div>
    </div>

    <div className="m-3 ">
      <div className="py-2 justify-center text-[#00ff99] font-bold flex">Contour Rating</div>

      <div className=" flex p-2 text-center font-bold bg-gradient-to-r from-[#4b9d0f] via-[#d6e554,#ffee5d,#f8ad26,#94651e] to-[#ffffff]" >
      </div>
      <div className="flex justify-between text-white" style={{ fontSize: 12 }}>
        <div className="">1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
        <div>5</div>
      </div>
    </div>

    {/* <div className="flex w-full">
<div className="p-4 border-2 bg-[#52C755]">1</div>
    <div className="p-4 border-2 bg-[#33A036]">2</div>
    <div className="p-4 border-2 bg-yellow-400">3</div>
    <div className="p-4 border-2 bg-orange-500">4</div>
    <div className="p-4 border-2 bg-red-500">5</div>
</div> */}

    <div className="m-3 ">
      <div className="py-2 justify-center text-[#00ff99] font-bold flex">Rental Yield Percentage</div>

      <div className=" flex p-2 text-center font-bold bg-gradient-to-r from-red-500  via-yellow-500 to-green-600 " >
      </div>

      <div className="flex justify-between text-white" style={{ fontSize: 12 }}>
        <div className="">Low</div>
        <div>Moderate</div>
        <div>Good</div>
      </div>
    </div>



    <div className="m-3 border-2 border-[#00ff99] flex-col items-center justify-center flex">
    <div className="py-2  text-[#00ff99] font-bold flex justify-center">Capital Gains</div>



      <div className=" flex xl:flex-row flex-col ">
        <p className="text-md px-3 text-white">Coming soon</p>
        {/* <div className="w-full ">
          <div className=" rounded flex p-4 h-full items-center ">
            <svg viewBox="0 0 512 512"  width={40} height={40} xmlns="http://www.w3.org/2000/svg"><g id="_1_toucan" data-name="1 toucan"><path d="m403.279 230.648c-6.464-5.916-14.131-9.442-21.559-12.194v-32.062a45.38 45.38 0 0 1 13.6 7.039 10 10 0 0 0 12.831-15.342 64.8 64.8 0 0 0 -26.431-12.346v-4.565a10 10 0 0 0 -20 0v4.315a50.21 50.21 0 0 0 -5.157 1.261c-13.376 4.03-23.641 16.067-24.962 29.274-1.238 12.372 4.9 23.754 16.408 30.449a83.9 83.9 0 0 0 13.711 6.172v38.651a39.386 39.386 0 0 1 -19.217-11.31 10 10 0 1 0 -14.819 13.43c8.6 9.487 20.827 15.954 34.036 18.308v5.665a10 10 0 1 0 20 0v-5.6c14.065-2.875 28.292-12.333 32.18-27.569 3.045-11.932-1.025-24.796-10.621-33.576zm-45.216-21.461c-2.71-1.576-7.166-5.122-6.561-11.168.493-4.929 4.925-10.036 10.218-11.9v24.969c-1.268-.596-2.491-1.223-3.657-1.901zm36.459 50.085c-1.176 4.607-6.122 9.432-12.8 11.857v-30.991a33.707 33.707 0 0 1 8.058 5.265c4.14 3.79 5.958 9.104 4.742 13.869z" fill="#00ff99" ></path>
              <path d="m227.931 184.631h51.185l-59.56 91.294a10 10 0 0 0 8.375 15.464h69.649a10 10 0 1 0 0-20h-51.18l59.56-91.294a10 10 0 0 0 -8.375-15.464h-69.654a10 10 0 0 0 0 20z" fill="#00ff99">
              </path><path d="m184.677 291.39a10 10 0 0 0 10-10v-106.759a10 10 0 0 0 -20 0v72.259l-49.118-77.607a10 10 0 0 0 -18.45 5.348v106.758a10 10 0 0 0 20 0v-72.259l49.119 77.607a10 10 0 0 0 8.449 4.653z" fill="#00ff99" >
              </path><path d="m512.005 229.285c0-61.463-26.778-119.182-75.4-162.522-48.305-43.05-112.438-66.763-180.6-66.763-56.355 0-109.805 16.076-154.569 46.482a10 10 0 0 0 11.237 16.545c41.437-28.146 91-43.027 143.332-43.027 130.071 0 235.9 93.8 236 209.122a188.139 188.139 0 0 1 -3.421 35.837c-9.274 47.846-37.43 91.678-79.294 123.431-42.654 32.353-97.091 50.17-153.283 50.17s-110.637-17.817-153.292-50.17c-41.865-31.753-70.021-75.582-79.295-123.428-.011-.061-.022-.122-.034-.182a188.206 188.206 0 0 1 -3.379-35.5c0-52.878 22.305-103.361 62.807-142.148a10 10 0 0 0 -13.835-14.44c-44.479 42.596-68.974 98.208-68.974 156.593v53.429c0 61.464 26.777 119.182 75.4 162.522 48.295 43.05 112.434 66.764 180.6 66.764a280.86 280.86 0 0 0 95.365-16.431 10 10 0 1 0 -6.77-18.819 260.933 260.933 0 0 1 -88.6 15.25c-111.659 0-205.453-69.134-229.827-161.666a235.586 235.586 0 0 0 64.454 74c46.111 34.975 104.843 54.235 165.378 54.235s119.26-19.26 165.369-54.235a235.6 235.6 0 0 0 64.426-73.951 194.885 194.885 0 0 1 -24.535 55.651c-19.605 30.553-47.808 56.462-81.559 74.927a10 10 0 1 0 9.6 17.546c36.679-20.068 67.383-48.309 88.791-81.672a211.155 211.155 0 0 0 33.903-114.121s.005-52.66.005-53.429z" fill="#00ff99"></path>
              <path d="m439.461 255.567c-6.946 35.352-28.287 68.108-60.1 92.24-34.07 25.838-77.879 40.067-123.357 40.067-34.09 0-67.494-8.011-96.6-23.169a10 10 0 1 0 -9.237 17.739c31.948 16.636 68.546 25.43 105.839 25.43 49.815 0 97.916-15.672 135.443-44.132 35.763-27.129 59.792-64.219 67.678-104.527l.071-.411a156.576 156.576 0 0 0 2.818-29.519c0-46.784-20.9-91.081-58.848-124.735a206.354 206.354 0 0 0 -20.1-15.683 10 10 0 0 0 -11.31 16.495 186.369 186.369 0 0 1 18.14 14.154c33.606 29.8 52.115 68.786 52.115 109.769a136.378 136.378 0 0 1 -2.442 25.661c-.037.192-.071.384-.11.621z" fill="#00ff99" ></path>
              <path d="m345.6 68.459a229.363 229.363 0 0 0 -89.594-17.765c-55.728 0-107.995 19.127-147.175 53.859-37.941 33.647-58.831 77.947-58.831 124.732a156.077 156.077 0 0 0 2.8 29.523c.011.058.022.118.034.183.017.1.035.2.055.3 7.407 37.862 29.418 73.363 61.977 99.964a10 10 0 0 0 12.654-15.488c-28.912-23.619-48.43-54.924-54.972-88.167-.021-.12-.043-.24-.066-.363-.01-.052-.02-.1-.029-.156a136 136 0 0 1 -2.453-25.796c0-40.989 18.5-79.972 52.1-109.768 35.517-31.483 83.073-48.823 133.907-48.823a209.453 209.453 0 0 1 81.826 16.2 10 10 0 0 0 7.767-18.435z" fill="#00ff99" ></path></g></svg>
            <span className="text-md px-3  text-white font-bold">500</span>
          </div>
        </div>

        <div className="w-full ">
          <div className=" rounded flex p-4 h-full items-center  ">
            <svg version="1.1" id="Layer_1" x="0px" y="0px" width={40} height={40} viewBox="0 0 512 512"  ><circle cx="256.054" cy="255.998" r="245.419" fill="#00ff99">
            </circle><path d="M122.962,82.461c42.77-42.776,97.406-66.443,153.315-71.037 c-69.366-5.701-140.69,17.967-193.751,71.037c-95.846,95.846-95.846,251.23,0,347.076c53.061,53.07,124.385,76.737,193.751,71.037 c-55.909-4.594-110.545-28.262-153.315-71.037C27.116,333.69,27.116,178.306,122.962,82.461z" fill="#22ffbb">
              </path><path d="M133.386,389.252c-2.707,0-5.415-1.033-7.481-3.099c-4.131-4.131-4.131-10.829,0-14.96l245.348-245.347 c4.133-4.131,10.83-4.131,14.962,0c4.131,4.131,4.131,10.829,0,14.96L140.867,386.154 C138.801,388.22,136.092,389.252,133.386,389.252z" fill="#000000">
              </path><path d="M182.754,227.104c-11.377,0-22.756-4.33-31.417-12.992c-17.323-17.323-17.323-45.511,0-62.834 c17.323-17.324,45.512-17.324,62.835,0c17.323,17.323,17.323,45.511,0,62.834C205.51,222.774,194.133,227.104,182.754,227.104z M182.754,159.434c-5.96,0-11.919,2.268-16.455,6.806c-9.075,9.074-9.075,23.838,0,32.912c9.073,9.074,23.838,9.074,32.911,0 c9.075-9.074,9.075-23.838,0-32.912C194.675,161.702,188.714,159.434,182.754,159.434z" fill="#000000"></path><path d="M329.365,373.714c-11.377,0-22.756-4.33-31.417-12.992c-17.323-17.323-17.323-45.511,0-62.834 c17.323-17.324,45.512-17.324,62.835,0c17.323,17.323,17.323,45.511,0,62.834C352.121,369.384,340.743,373.714,329.365,373.714z M329.365,306.044c-5.96,0-11.919,2.268-16.455,6.806c-9.075,9.074-9.075,23.838,0,32.912c9.073,9.074,23.838,9.074,32.911,0 c9.075-9.074,9.075-23.838,0-32.912C341.284,308.312,335.323,306.044,329.365,306.044z" fill="#000000"></path>
              <path d="M256.06,512c-68.381,0-132.668-26.628-181.02-74.981S0.06,324.379,0.06,255.999S26.689,123.333,75.04,74.981 S187.68,0,256.06,0s132.666,26.628,181.02,74.98l0,0c99.814,99.814,99.814,262.224,0,362.038C388.726,485.371,324.44,512,256.06,512 z M256.06,21.158c-62.729,0-121.702,24.428-166.058,68.783s-68.783,103.33-68.783,166.058s24.428,121.702,68.785,166.058 c44.354,44.356,103.329,68.783,166.058,68.783c62.728,0,121.701-24.428,166.058-68.783c91.565-91.565,91.565-240.553,0-332.117 C377.761,45.586,318.788,21.158,256.06,21.158z" fill="#000000"></path></svg>
              <span className="text-md px-3   text-white font-bold">20</span>
              </div>
        </div> */}
      </div>

    </div>


    <div className="m-3 border-2 border-[#00ff99]">
      <div className="py-2 justify-center  text-[#00ff99] font-bold flex">Zone Regulations</div>
      <div className="w-full">
        <div className="text-base justify-between  text-[#00ff99] rounded flex p-2 h-full  items-center">
          Building Coverage
          <span className=" px-3 text-white ">{props.data.zoneData?props.data.zoneData['buildingCoverageArea'].toFixed(1) + ' sq.m':'...'}</span>
        </div>
      </div>
      <div className="w-full">
        <div className="text-base justify-between  text-[#00ff99] rounded flex p-2 h-full items-center ">
          Landscaping
          <span className=" px-3 text-white ">{props.data.zoneData && props.data.zoneData['zoneRegulation']['landscape']?props.data.zoneData['zoneRegulation']['landscape']['maxPercent'] * 100 + "%":''}</span>
        </div>
      </div>
      <div className="w-full">
        <div className="text-base justify-between text-[#00ff99] rounded flex p-2 h-full items-center ">
          Setbacks
          <span className=" px-3 text-white ">{props.data.zoneData && props.data.zoneData['zoneRegulation']['yardControls']?props.data.zoneData['zoneRegulation']['yardControls']['front']+'m front & '+props.data.zoneData['zoneRegulation']['yardControls']['side']+'m side':''}</span>
        </div>
      </div>
      <div className="w-full">
        <div className="text-base justify-between text-[#00ff99] rounded flex p-2 h-full items-center ">
          Max. no of floors
          <span className=" px-3 text-white ">{props.data.zoneData?props.data.zoneData['zoneRegulation']['maxNoOfFloors']:''}</span>
        </div>
      </div>
    </div>


    <div className="py-2 justify-center text-[#00ff99] font-bold flex text-center" style={{ fontSize: 20 }}>What can I do with my plot?</div>
    <div className="w-full">
      <div className=" rounded flex px-4 py-3 h-full items-center  justify-between">
        <div className="text-[#00ff99] font-bold text-md xl:text-lg">Change of Use</div>
        <span className="text-xl px-3 text-white font-bold"><Button className="bg-[#00ff99] text-black" onPress={onOpen} >Request Info</Button></span>
      </div>
    </div>
    <div className="w-full">
      <div className=" rounded flex px-4 py-3 h-full items-center  justify-between">
        <div className="text-[#00ff99] font-bold text-md xl:text-lg">Cross-lease to Freehold</div>
        <span className="text-xl px-3 text-white font-bold"><Button className="bg-[#00ff99] text-black" >Details</Button></span>
      </div>
    </div>
    <div className="w-full">
      <div className=" rounded flex px-4 py-3 h-full items-center  justify-between">
        <div className="text-[#00ff99] font-bold text-md xl:text-lg">Selling Time</div>
        <span className="text-md px-3 text-white font-bold">16 days ago</span>
      </div>
    </div>
    <div className="py-2 justify-center text-[#00ff99] font-bold flex text-center" style={{ fontSize: 20 }}>Any issues on your property ?</div>

    <div className="  text-center px-3 pb-5 text-white" >
    Any unconsented works on your property? We can help you with that – register the scope of unconsented works here <span className="text-[#00ff99]"><Link className="text-[#00ff99]" href={clientUrl} target="_blank">(www.projectx.nz)</Link></span>

    </div>


    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent className="z-50">
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">Modal Title</ModalHeader>
            <ModalBody>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Nullam pulvinar risus non risus hendrerit venenatis.
                Pellentesque sit amet hendrerit risus, sed porttitor quam.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Nullam pulvinar risus non risus hendrerit venenatis.
                Pellentesque sit amet hendrerit risus, sed porttitor quam.
              </p>
              <p>
                Magna exercitation reprehenderit magna aute tempor cupidatat consequat elit
                dolor adipisicing. Mollit dolor eiusmod sunt ex incididunt cillum quis.
                Velit duis sit officia eiusmod Lorem aliqua enim laboris do dolor eiusmod.
                Et mollit incididunt nisi consectetur esse laborum eiusmod pariatur
                proident Lorem eiusmod et. Culpa deserunt nostrud ad veniam.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
              <Button color="primary" onPress={onClose}>
                Action
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>

  </>
};

export default connect(mapStateToProps)(RightComponent);